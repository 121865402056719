<template>
  <!-- eslint-disable -->
<div  style="text-align:left; background-color: rgb(242, 247, 251);">
 
  
  <div class="column-left">
    <SideNav />
  </div>

  
  <div class="column-right">
    <TopNav />
    <div class="main-container">   
      
      <div class="articulo-principal">
        <h2>Crear profesional</h2>    
        <div class="boxy">



      <div
        class="main"
        style="text-align:left; width:100%;"
      >
        <table style=" margin-left: 0px;">
          <tr>
            <td>Email</td>
            <td>
              <input
                v-model="email"
                type="text"
              >
            </td>
          </tr>
          <tr>
            <td>Nombre</td>
            <td>
              <input
                v-model="nombre"
                type="text"
              >
            </td>
          </tr>
          <tr>
            <td>Apellido</td>
            <td>
              <input
                v-model="apellido"
                type="text"
              >
            </td>
          </tr>
          <tr>
            <td>Rut</td>
            <td>
              <input
                v-model="rut"
                type="text"
              >
            </td>
          </tr>
        </table>
        <br>
        <button
          type="button"
          class="menuCol btn-ir-gordo"
            :style="{'background-color':prestador.admin.assets.colors.primary}"
          @click="crear_profesional()"
        >
          <b-icon icon="plus-circle" /> Crear profesional
        </button>
      </div>
      {{ resultado }}
          
        </div>
      </div>



    </div>
  </div>



</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  components:{
    TopNav,
    Loader,
    SideNav,
  },
  data() {
    return {
      email:'',
      nombre:'',
      apellido:'',
      rut:'',

      resultado:'',

    }
  },
  methods:{
    ...mapActions(['loginAdmin']),
    crear_profesional(){
        axios.post(window.hostname+'/api/admin/crear_profesional', 
        {email:this.email, nombre:this.nombre, apellido:this.apellido, rut:this.rut})
        .then(response => 
        {
          this.resultado = 'Profesional Creado'
        })

        .catch(e=>{
          console.log(e.response)
          if (e.response.data.message)
            this.resultado = e.response.data.message           
        })
    }

  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'prestador', 'admin_datos']),
  }

}
</script>


<style scoped>
td{
  text-align: left;
}
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;



  
  margin: 0 auto;




}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);

  padding-top:0px;
  padding-left:275px;
  width:100%;
  

}
.main-container{
  background-color: rgb(242, 247, 251);
  padding: 10px;

  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

