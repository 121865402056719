<template>
  <!-- eslint-disable -->
<div  style="text-align:left; background-color: rgb(242, 247, 251);">
 
  
  <div class="column-left">
    <SideNav />
  </div>

  
  <div class="column-right">
    <TopNav />
    <div class="main-container">   
      
      <div v-if="loaded" class="articulo-principal">

        <div>
          <div style="margin-bottom: 20px;" class="boxy">
            <h4 style=" ">
        Nuevo bloqueo
      </h4>

    
      <div style=" width:100%; padding:20px 0px; ">
        Profesional
        <select
          v-model="pro_id"
          style="padding:4px;"
        >
          <option :value="0">
            Todos
          </option>
          <option
            v-for="p in profesionales"
            :value="p.id"
          >
            {{ p.nombre+' '+p.apellido }}
          </option>
        </select>
        Dia inicio <input
          id="x2"
          v-model="dt_dia"
          type="date"
        >
        Dia termino <input
          id="x2"
          v-model="dt_dia2"
          type="date"
        >
        Desde
        <select
          v-model="desde_general"
          style="padding:4px;"
        >
          <option
            v-for="v in Math.round(1440/5)"
            :value="(v-1)*5"
          >
            {{ timeConvert((v-1)*5) }}
          </option>
        </select>
        Hasta
        <select
          v-model="hasta_general"
          style="padding:4px;"
        >
          <option
            v-for="v in Math.round(1440/5)"
            v-if="(v)*5 > desde_general"
            :value="(v)*5"
          >
            {{ timeConvert((v)*5) }}
          </option>
        </select>
        <button
          style="margin-left:8px;"
          class="menuCol btn-ir-gordo"
            :style="{'background-color':prestador.admin.assets.colors.primary}"
          @click="agregar_bloqueo()"
        >
          Agregar Bloqueo <b-icon
            style="transform:translateY(2px);"
            icon="plus-circle"
          />
        </button>
      </div>
      </div>
  <div class="boxy">
    <h4 style=" ">
        Bloqueos existentes
      </h4>
      <div style=" padding:10px;">
        <table style=" border-radius:5px; width:100%;">
          <thead>
            <td style="padding:10px;">
              <b>Nombre</b>
            </td>
            <td style="padding:10px;">
              <b>Fecha</b>
            </td>
            <td style="padding:10px;">
              <b>Horario</b>
            </td>
            <td style="padding:10px; text-align:center;">
              <b>Eliminar</b>
            </td>
          </thead>
          <tr v-for="b in bloqueos">
            <td style="padding:10px;">
              <span v-if="profesionales.some(z=>{return z.id == b.profesional})">{{ profesionales.filter(x=>{return x.id == b.profesional})[0].nombre+' '+profesionales.filter(x=>{return x.id == b.profesional})[0].apellido }}</span>
            </td>
            <td style="padding:10px;">
              {{ new Date(b.fecha.a, b.fecha.m-1, b.fecha.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}
            </td>
            <td style="padding:10px;">
              {{ timeConvert(b.desde)+' - '+timeConvert(b.hasta) }} hrs
            </td>
            <td style="padding:10px; text-align:center;">
              <b-icon
                icon="trash"
                style="color:red; cursor:pointer;"
                @click="borrar_bloqueo(b.id)"
              />
            </td>
          </tr>
        </table>
      </div>   
  </div>          
    </div>
          
        </div>
     

      <div v-else class="articulo-principal">
        <div style="height: calc(100vh - 140px); display: flex; justify-content: center; align-items: center;">
          <Loader :height="100" :width="100" />
        </div>
      </div>

    </div>
  </div>



</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  //props:['id'],
  components: {
    SideNav,
    Loader,
    TopNav,
  },
  data() {
    return {
      dias:['Lu','Ma','Mi','Ju','Vi','Sa','Dom'],
      dias2:['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
      loaded:false,
      
      dt_dia:'',
      dt_dia2:'',
      pro_id:0,
      dia_general:{d:0, m:0, a:0},
      dia_general2:{d:0, m:0, a:0},
      profesionales:[],
      bloqueos:[],
      periodo:60,
      desde_general:0,
      hasta_general:0,
      profesional_id:0,

    }
  },
  created(){
  
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),
   
    agregar_bloqueo(){
      axios.post(window.hostname+'/api/admin/admin_agregar_bloqueos', 
        {id: this.pro_id, 
        desde:this.desde_general, 
        hasta:this.hasta_general, 
        d:this.dia_general.d, 
        m:this.dia_general.m, 
        a: this.dia_general.a,
        d2:this.dia_general2.d, 
        m2:this.dia_general2.m, 
        a2: this.dia_general2.a,
      })
        .then(response => 
        {
            
            this.initialize_();
        })

        .catch(e=>{

            console.log("Error al cargar...")
        })

    },
    borrar_bloqueo(bid){
      axios.post(window.hostname+'/api/admin/admin_borrar_bloqueos', 
        {id: bid})
        .then(response => 
        {
            
            this.initialize_();
        })

        .catch(e=>{

            console.log("Error al cargar...")
        })

    },
   
        timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    
      initialize_(){
        this.loaded = false;
      axios.get(window.hostname+'/api/admin/initialize_bloqueos')
      .then(r=>{
        this.loaded = true
        this.profesionales = r.data.profesionales
        this.bloqueos = r.data.bloqueos

      })
      .catch(r=>{
        this.loaded = true
        
        })
      
    },

        agregar_horario(agenda_id, inicio, termino, repeticion){

      var error_msg = ''
      if(agenda_id == 0)
          error_msg = 'Por favor, ingrese una agenda. '
      if(inicio == -1)
        error_msg += 'Por favor, ingrese una hora de inicio. '
      if(termino == -1)
        error_msg += 'Por favor, ingrese una hora de término. '

      if(inicio > termino)
        error_msg += 'La hora de inicio no puede ser mayor a la de término. '  
      var x = '';
      for (var i = 0; i < repeticion.length; i++)
        x += repeticion[i].value.toString();
      
      if(x == '0000000')
         error_msg += 'Debes especificar al menos un día. '  

      if(error_msg == ''){

        axios.post(window.hostname+'/api/admin/addHorario', 
        {profesional_id: agenda_id,
          inicio: inicio,
          termino: termino,
          repeticion:x,
          } )
        .then(resp=>{

          this.$notify({duration:5000, title: 'Horario agregado', text: '¡Se ha añadido el horario a la base de datos!', type: 'success'})
          this.initialize_(this.$route.params.id);
        })
        .catch(e=>{ 
          this.$notify({duration:5000, title: 'Error!', text: e.response.data.message, type: 'error'})
        })
      }
      else{
        this.$notify({ title: 'Error', text: error_msg, type: 'error'})
      }

    },

  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'prestador', 'admin_datos', 'examenes']),
  },
  watch:{
    dt_dia(val){

        var datos = val.split('-')
        this.dia_general = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
      
    },
    dt_dia2(val){

      var datos = val.split('-')
      this.dia_general2 = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}

    },
  },
  mounted() {
    var hoy = new Date()
    this.dt_dia = hoy.toISOString().split('T')[0]
    this.dt_dia2 = hoy.toISOString().split('T')[0]
  }

}
</script>


<style scoped>
td{
  text-align: left;
}
thead td{
  
}
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;



  
  margin: 0 auto;




}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);

  padding-top:0px;
  padding-left:275px;
  width:100%;
  

}
.main-container{
  background-color: rgb(242, 247, 251);
  padding: 10px;

  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

