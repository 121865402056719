import store from '../store';

function formatNum(num){
    const numFormat = Intl.NumberFormat("de-DE").format(Math.round(num));
    return numFormat;
}

function getExamen(examen_id){
    return store.getters.examenes.find( (x) => {return x.id == examen_id} );
}


function getPrecioExamen(examen_id, comuna_id = -1, categoria_cliente_id = undefined, aseguradora_id=-1){
    const data = {
        precio: getExamen(examen_id).precio,
        precio_minimo: getExamen(examen_id).precio,
        multiples_precios: false,
    };



    data.multiples_precios = Object.keys(getExamen(examen_id).precios_por_comuna).length > 0;

    let precio_min_comunas = Math.min(...Object.values(getExamen(examen_id).precios_por_comuna));
    data.precio_minimo = Math.min(data.precio, precio_min_comunas);

    if (comuna_id > 0) {
        if (getExamen(examen_id).precios_por_comuna[comuna_id]) {
            data.precio = getExamen(examen_id).precios_por_comuna[comuna_id];
        }
    }
    if(aseguradora_id != -1){
        
        let desc_aseg = (store.getters.descuentos.find(x=>{return x.examen_id == examen_id && x.aseguradora_id == aseguradora_id}) || {valor:0}).valor;
        data.precio = data.precio - desc_aseg;
        data.precio_minimo = data.precio_minimo - desc_aseg;
    }
    let p_paciente;
    if (categoria_cliente_id != undefined){
        p_paciente = store.getters.precios_examen_prestador_categoria_cliente.find(x=>{return x.examen_id == examen_id && x.categoria_cliente_id == categoria_cliente_id})
        if(p_paciente){
            data.precio = p_paciente.precio
            data.precio_minimo = p_paciente.precio
        }
    }

    return data;
}

function getReembolsoExamen(examen_id, aseguradora_id,  categoria_cliente_id = undefined){
    
    var examen_precio_categoria;
    if (categoria_cliente_id != undefined && categoria_cliente_id != 0)
    {
        examen_precio_categoria =    store.getters.precios_examen_prestador_categoria_cliente.find(x=>{return x.examen_id == examen_id && x.categoria_cliente_id == categoria_cliente_id})
        if(examen_precio_categoria)
            return 0
    }
    var reembolso_aseguradora = store.getters.descuentos.find(x=>{return x.examen_id == examen_id && x.aseguradora_id == aseguradora_id});
    if (reembolso_aseguradora)
        return reembolso_aseguradora.valor;
    return 0;

}
function isIndexOutOfArrayBounds(array, index) {
    return index < 0 || index >= array.length;
  }
  
function calcularCostoServicioPaciente(cantidadPacientes, aseguradorId, categoria_cliente_id=undefined, indice=undefined, comuna_id=undefined) {
    
    


    var costoServicio;
    console.log("EO1", comuna_id)
    console.log(store.getters.costo_servicio_comuna)
    if(!comuna_id)
        costoServicio = store.getters.costo_servicio_comuna[store.getters.comuna];
    else
        costoServicio = store.getters.costo_servicio_comuna[comuna_id];

    console.log("EO2")
    if(!costoServicio)
        costoServicio = store.getters.costo_servicio_asegurador[aseguradorId];

    if (!costoServicio)
    {
        costoServicio = {}
        costoServicio.costo_servicio_pendiente = store.getters.costo_servicio_pendiente;
        costoServicio.costo_servicio = store.getters.costo_servicio;
    }
    console.log("vamos bien")
    if(categoria_cliente_id != undefined){
    
        let costoCategoriaClienteBase = store.getters.costo_servicio_categoria_cliente.find(z=>{return z.categoria_cliente_id == categoria_cliente_id && z.tipo == 0})
        if(costoCategoriaClienteBase)
            costoServicio.costo_servicio = costoCategoriaClienteBase.valor
        let costoCategoriaClientePendiente = store.getters.costo_servicio_categoria_cliente.find(z=>{return z.categoria_cliente_id == categoria_cliente_id && z.tipo == 1})
        if(costoCategoriaClientePendiente)
            costoServicio.costo_servicio_pendiente = costoCategoriaClientePendiente.valor
    }
    let descuento_costo_servicio_asegurador_comuna = 0;
    
    if(store.getters.descuento_costo_servicio_asegurador_comuna)
    {
        if (store.getters.comuna in store.getters.descuento_costo_servicio_asegurador_comuna)
        {
            if (aseguradorId in store.getters.descuento_costo_servicio_asegurador_comuna[store.getters.comuna])
            {
                descuento_costo_servicio_asegurador_comuna = store.getters.descuento_costo_servicio_asegurador_comuna[store.getters.comuna][aseguradorId];
            }

        }
    }

    //Calculando los descuentos especiales
    let descuento_especial_paciente = 0;
    console.log("AQUI ESTÁ")
    if(indice != undefined){
        if(typeof store.getters.descuentos_especiales_examenes_agregados === 'object'){
            if ('detalle' in store.getters.descuentos_especiales_examenes_agregados){
 
                 store.getters.descuentos_especiales_examenes_agregados.detalle.forEach((descuento) => {
                     if (!isIndexOutOfArrayBounds(descuento.detalle, indice)){
                        descuento_especial_paciente += descuento.detalle[indice].monto_domicilio
                    } 

    }); 
            }
        }
        

    }

    const costoBase = costoServicio.costo_servicio / cantidadPacientes;
    const costoVariable = costoServicio.costo_servicio_pendiente ? costoServicio.costo_servicio_pendiente : 0;
    const costoFinal = costoBase + costoVariable - descuento_costo_servicio_asegurador_comuna - descuento_especial_paciente;
    return costoFinal;
}

function calcularCostoServicioPacienteSinDescuento(cantidadPacientes, aseguradorId, categoria_cliente_id=undefined, indice=undefined) {
    
    
    

    var costoServicio;
    costoServicio = store.getters.costo_servicio_comuna[store.getters.comuna];

    if(!costoServicio)
        costoServicio = store.getters.costo_servicio_asegurador[aseguradorId];

    if (!costoServicio)
    {
        costoServicio = {}
        costoServicio.costo_servicio_pendiente = store.getters.costo_servicio_pendiente;
        costoServicio.costo_servicio = store.getters.costo_servicio;
    }

    if(categoria_cliente_id != undefined){
    
        let costoCategoriaClienteBase = store.getters.costo_servicio_categoria_cliente.find(z=>{return z.categoria_cliente_id == categoria_cliente_id && z.tipo == 0})
        if(costoCategoriaClienteBase)
            costoServicio.costo_servicio = costoCategoriaClienteBase.valor
        let costoCategoriaClientePendiente = store.getters.costo_servicio_categoria_cliente.find(z=>{return z.categoria_cliente_id == categoria_cliente_id && z.tipo == 1})
        if(costoCategoriaClientePendiente)
            costoServicio.costo_servicio_pendiente = costoCategoriaClientePendiente.valor
    }
    let descuento_costo_servicio_asegurador_comuna = 0;
    
    if(store.getters.descuento_costo_servicio_asegurador_comuna)
    {
        if (store.getters.comuna in store.getters.descuento_costo_servicio_asegurador_comuna)
        {
            if (aseguradorId in store.getters.descuento_costo_servicio_asegurador_comuna[store.getters.comuna])
            {
                descuento_costo_servicio_asegurador_comuna = store.getters.descuento_costo_servicio_asegurador_comuna[store.getters.comuna][aseguradorId];
            }

        }
    }

    

    const costoBase = costoServicio.costo_servicio / cantidadPacientes;
    const costoVariable = costoServicio.costo_servicio_pendiente ? costoServicio.costo_servicio_pendiente : 0;
    const costoFinal = costoBase + costoVariable - descuento_costo_servicio_asegurador_comuna;
    return costoFinal;
}

function calcularTotalServicio(examenesAgregados) {
    let totalServicio = 0;
    examenesAgregados.forEach( (paciente, indice) => {
        totalServicio += calcularCostoServicioPaciente(examenesAgregados.length, paciente.aseguradora_id, paciente.categoria_cliente_id, indice);
    });

    return totalServicio;
}

function calcularTotalServicioSinDescuentos(examenesAgregados) {
    let totalServicio = 0;
    examenesAgregados.forEach( (paciente, indice) => {
        totalServicio += calcularCostoServicioPacienteSinDescuento(examenesAgregados.length, paciente.aseguradora_id, paciente.categoria_cliente_id, indice);
    });

    return totalServicio;
}

function calcularTotalReembolsos(examenesAgregados){
    
    let totalReembolsos = 0;
    let sumando = 0;
    for(var paciente of examenesAgregados){
        for(var examen_id of paciente.examenes){

            sumando = getReembolsoExamen(examen_id, paciente.aseguradora_id, paciente.categoria_cliente_id)
            //(!(paciente.categoria_cliente_id != -1 && paciente.categoria_cliente_id != undefined && paciente.categoria_cliente_id != 0) ? store.getters.descuentos.find( (x) => x.examen_id==examen_id && x.aseguradora_id == paciente.aseguradora_id ) || {valor:0} : {valor:0} ).valor
            //- (store.getters.descuentos.find(x=>{return x.examen_id == examen_id && x.aseguradora_id == paciente.aseguradora_id}) || {valor:0}).valor
            //- (store.getters.valores_codigo.find(x=>{return x.examen_id == examen_id }) || {valor:0}).valor
            if (sumando > 0)
            totalReembolsos += sumando
        }
    }
    return totalReembolsos;
}

function calcularTotalExamenes(examenesAgregados, comuna_id = -1){
    let totalExamenes = 0;
    let sumando = 0;
    for(var paciente of examenesAgregados){
        for(var examen_id of paciente.examenes){
            sumando = 
            getPrecioExamen(examen_id, comuna_id, paciente.categoria_cliente_id).precio 
            //- (!(paciente.categoria_cliente_id != -1 && paciente.categoria_cliente_id != undefined && paciente.categoria_cliente_id != 0) ? store.getters.descuentos.find( (x) => x.examen_id==examen_id && x.aseguradora_id == paciente.aseguradora_id ) : {valor:0} || {valor:0}).valor
            //- (store.getters.descuentos.find(x=>{return x.examen_id == examen_id && x.aseguradora_id == paciente.aseguradora_id}) || {valor:0}).valor
            - (store.getters.valores_codigo.find(x=>{return x.examen_id == examen_id }) || {valor:0}).valor
            if (sumando > 0)
            totalExamenes += sumando
        }
    }
    return totalExamenes;
}

function calcularRecargo(examenesAgregados, recargoPorcentaje = 0, recargoSuma = 0, comuna_id = -1) {
    const totalExamenes = calcularTotalExamenes(examenesAgregados, comuna_id);
    const totalServicio = calcularTotalServicio(examenesAgregados);
    const recargo =  (totalExamenes + totalServicio)*(recargoPorcentaje/100) + recargoSuma;
    return recargo;
}

function calcularTotalDescuentosEspeciales(descuentos){
    if (descuentos.valor_total){
        return descuentos.valor_total
    }
    return 0;
}
function calcularTotalDescuentos(examenesAgregados, descuentos)
{
    const totalReembolsos = calcularTotalReembolsos(examenesAgregados)
    const totalDescuentosEspeciales = calcularTotalDescuentosEspeciales(descuentos)
    return totalReembolsos + totalDescuentosEspeciales;
}

function calcularTotalOrden(examenesAgregados, comuna_id = -1, recargoPorcentaje = 0, recargoSuma = 0){
    const totalExamenes = calcularTotalExamenes(examenesAgregados, comuna_id);
    const totalReembolsos = calcularTotalReembolsos(examenesAgregados)
    const totalServicio = calcularTotalServicio(examenesAgregados);
    const recargo = calcularRecargo(examenesAgregados, recargoPorcentaje, recargoSuma, comuna_id);
    const totalOrden = totalExamenes + totalServicio + recargo - totalReembolsos;
    return totalOrden;
}

function solicitarComuna(examenesAgregados = [], comuna_id = -1){
    let solicitar = false;
    if (comuna_id > 0) return solicitar;
    if (examenesAgregados.length > 0) {
        for(var paciente of examenesAgregados){
            for(var examen_id of paciente.examenes){
                if (getPrecioExamen(examen_id, comuna_id).multiples_precios){
                    solicitar = true;
                    break;
                }
            }
        }
    }
    return solicitar;
}
function pagoInmediato(examenesAgregados){

    if(store.getters.prestador.atributos.config.pago_visita_anticipada)
        return true;

    for(var paciente of examenesAgregados){
        for(var examen_id of paciente.examenes){
             
           if(getExamen(examen_id).requiere_pago)
            return true;

        }
    }
    return false;
}

const funcionesCalculoCosto = {
    formatNum,
    getExamen,
    getPrecioExamen,
    calcularCostoServicioPaciente,
    calcularTotalServicio,
    calcularTotalExamenes,
    calcularTotalReembolsos,
    calcularRecargo,
    calcularTotalDescuentosEspeciales,
    calcularTotalOrden,
    solicitarComuna,
    pagoInmediato,
    getReembolsoExamen,
    calcularTotalDescuentos,
    calcularTotalServicioSinDescuentos
}

export { funcionesCalculoCosto };
