<template>
  <!-- eslint-disable -->
<div  style="text-align:left; background-color: rgb(242, 247, 251);">
 
  
  <div class="column-left">
    <SideNav />
  </div>

  
  <div class="column-right">
    <TopNav />
    <div class="main-container">   
      
      <div v-show="loaded" class="articulo-principal">
        <h2>Prestaciones</h2>    
        <div class="boxy">
          <span style="font-style: italic;">
            *Arrastra con el cursor para ver todas las columnas. Puedes <b>modificar la configuración</b> de cada prestación utilizando el lápiz de la derecha.
          </span>
          <div class="container-fluid">
      <div>
        <div class="flex-header">
          <div class="flex-header-left">


          </div>
          <div class="flex-header-right">
            <div style="text-align:right; padding:7px;">
          <button
            v-if="prestador.id == 1"
            class="btn-ir-gordo"
            @click="agregar_examen()"
            :style="{'background-color':prestador.admin.assets.colors.primary}"
          >
            Agregar Examen <b-icon icon="plus-circle" />
          </button>

<!--           <div
            tag="button"
            style="margin-left:5px;"
            class="btn-ir-gordo"
            @click="DownloadExamsExcel()"
            :style="{'background-color':prestador.admin.assets.colors.primary}"
          >
            Descargar EXCEL <b-icon icon="card-text" />
          </div> -->

          <div
            style="display: inline-block; margin-left:5px;"
          >
            <excel-uploader />
          </div>
        </div>
          </div>

        </div>

      

        <div class="scroll-div">
          <table>
          <thead>
            <td v-on:click="ordenar_por('id')" style="text-align:center;">
<div>
  Id

              <b-icon v-if="ordenar_por_selected == 'id'" style="position: absolute; transform: translate(1px, 3px);" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'idr'" style="position: absolute; transform: translate(1px, 3px);" icon="chevron-up"></b-icon>
</div>
            </td>
            <td v-on:click="ordenar_por('nombre')">Nombre
              
              <b-icon v-if="ordenar_por_selected == 'nombre'" style="position: absolute; transform: translate(1px, 3px);" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'nombrer'" style="position: absolute; transform: translate(1px, 3px);" icon="chevron-up"></b-icon>
              
            </td>
            <td v-on:click="ordenar_por('categoria')">Categoría
            
              <b-icon v-if="ordenar_por_selected == 'categoria'" style="position: absolute; transform: translate(1px, 3px);" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'categoriar'" style="position: absolute; transform: translate(1px, 3px);" icon="chevron-up"></b-icon>
            </td>
            <td>Requiere receta</td>
            <td>Requiere pago inmediato</td>
            <td >
              Información
            </td>
            <td >
              Código
            </td>
            <td >
              Keywords
            </td>
            <td v-on:click="ordenar_por('precio')">Precio
              <b-icon v-if="ordenar_por_selected == 'precio'" style="position: absolute; transform: translate(1px, 3px);" icon="chevron-down"></b-icon>
              <b-icon v-else-if="ordenar_por_selected == 'precior'" style="position: absolute; transform: translate(1px, 3px);" icon="chevron-up"></b-icon>
            
            </td>
            <td>Activo</td>
            <td>Sug</td>
            <td>
              Editar
            </td>
          </thead>
          <tr
            v-for="e in editando_examenes"
            style="height:40px; background-color:white; "
          >
            <template v-if="!e.modificar">
              <td style="text-align:center;">
                {{ e.id }}
              </td>
              <td style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
                {{ e.nombre }}
              </td>
              <td style="max-width:200px;">
                {{ categorias.filter(x=>{ return x.id == e.categoria})[0].nombre }}
              </td>
              <td style="max-width:200px;">
                {{ e.requiere_orden == 0 ? 'No':'Si' }}
              </td>
              <td style="max-width:200px;">
                {{ e.requiere_pago == 0 ? 'No':'Si' }}
              </td>
              <td>{{ e.informacion }}</td>
              <td>{{ e.codigo }}</td>
              <td>{{ e.keywords }}</td>
              <td style="width:80px">
                {{ e.precio }}
              </td>
              <td>{{ e.activo == true ? 'Si':'No' }}</td>
              <td>{{ e.hasta == 1 ? 'Si':'No' }}</td>
              <td>
                <span
                  style="color:blue; cursor:pointer; margin-right:6px;"
                  @click="e.modificar = true"
                >
                <i 
                :style="{'color': prestador.admin.assets.colors.primary}" 

                class="menu-icon tf-icons ti icon-edit ti-pencil"
                ></i>
              </span>  
                <span
                  style="color:red; cursor:pointer;"
                  @click="remover_examen(e.id)"
                >
                <i :style="{'color': '#FF4136'}" class="action-icon tf-icons ti ti-trash"></i>
              </span>  
              </td>
            </template>

            <template v-else>
              <td style="">
                {{ e.id }}
              </td>
              <td style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
                <input
                  v-model="e.enombre"
                  type="text"
                >
              </td>
              <td>
                <select v-model="e.ecategoria">
                  <option
                    v-for="o in categorias"
                    :value="o.id"
                  >
                    {{ categorias.filter(x=>{ return x.id == o.id})[0].nombre }}
                  </option>
                </select>
              </td>
              <td>
                <select v-model="e.erequiere_orden">
                  <option :value="1">
                    Si
                  </option>
                  <option :value="0">
                    No
                  </option>
                </select>
              </td>
              <td>
                <select v-model="e.erequiere_pago">
                  <option :value="1">
                    Si
                  </option>
                  <option :value="0">
                    No
                  </option>
                </select>
              </td>
              <td>
                <input
                  v-model="e.einformacion"
                  style="width:170px"
                  type="text"
                >
              </td>
              <td>
                <input
                  v-model="e.ecodigo"
                  style="width:110px"
                  type="text"
                >
              </td>
              <td>
                <input
                  v-model="e.ekeywords"
                  style="width:110px"
                  type="text"
                >
              </td>
              <td style="width:80px">
                <input
                  v-model="e.eprecio"
                  style="width:70px"
                  type="text"
                >
              </td>
              <td style="width:80px">
                <select v-model="e.edesde">
                  <option :value="true">
                    Si
                  </option>
                  <option :value="false">
                    No
                  </option>
                </select>
              </td>
              <td style="width:80px">
                <select v-model="e.ehasta">
                  <option :value="1">
                    Si
                  </option>
                  <option :value="0">
                    No
                  </option>
                </select>
              </td>
              <td style="text-align:center;">
                <span @click="e.modificar = false; modificar(e.id);"> <b-icon
                  style="color:green; cursor:pointer;"
                  icon="check-circle"
                /></span>
              </td>
            </template>
          </tr>

        </table>
        </div>


      </div>
    </div>
          
        </div>
      </div>
      <div v-show="!loaded" class="articulo-principal">
        <div style="height: calc(100vh - 140px); display: flex; justify-content: center; align-items: center;">


<Loader :height="100" :width="100" />


          </div>

        

      </div>
    </div>
  </div>



</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';
import ExcelUploader from './utils/ExcelUploader.vue';

export default {
  name: 'HelloWorld',
  components: {
    ExcelUploader,
    Loader,
    TopNav,
    SideNav,
  },
  data() {
    return {

      ordenar_por_selected:'id',

      loaded:false,
      editando_examenes:[],
      categorias:[],
      editando_categorias:[],
      costos: {
        'costo_servicio_base': -1,
        'costo_servicio_variable': -1,
        'costo_servicio_festivos': -1,
      },
      modificar_costos : false,
    }
  },
  created(){
  
    this.initialize_();
    this.get_costos_servicio();
  },

  methods:{
    ...mapActions(['recargar']),

    ordenar_por(attributeName) {
  if (this.ordenar_por_selected != attributeName || this.ordenar_por_selected == attributeName+'r')
{
  this.ordenar_por_selected = attributeName;
  return this.editando_examenes.sort((a, b) => {
    if (a[attributeName] < b[attributeName]) {
      return -1;
    }
    if (a[attributeName] > b[attributeName]) {
      return 1;
    }
    return 0;
  });
}
  this.ordenar_por_selected = attributeName+'r';
  return this.editando_examenes.sort((a, b) => {
    if (a[attributeName] < b[attributeName]) {
      return 1;
    }
    if (a[attributeName] > b[attributeName]) {
      return -1;
    }
    return 0;
  });
},
    DownloadExamsExcel(){
      axios({
        url: window.hostname+'/api/admin/exportar/examenes',
        method: 'GET',
        responseType: 'blob',
      })
      .then( (response) =>  {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'examenes.xlsx'); 
        document.body.appendChild(link);
        link.click();          
      })
      .catch( (e) => {
        console.log(e);
      })
    },
    DownloadExamsExcel2(){
      axios({
        url: window.hostname+'api/admin/descargar_excel_estandar',
        method: 'GET',
        responseType: 'blob',
      })
      .then( (response) =>  {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'exams_with_prestador.xlsx'); 
        document.body.appendChild(link);
        link.click();          
      })
      .catch( (e) => {
        console.log(e);
      })
    },

  get_costos_servicio(){
    axios.get(window.hostname+'/api/admin/costo_servicio')
        .then(response => {
          this.costos = response.data;
        })
        .then(() => {
          console.log(this.costos);
        })
        .catch(e=>{
            console.log("Error al cargar costos servicios...")
        })
  },

  async post_costos_servicio(){
    if (this.modificar_costos) {
      axios.post(window.hostname+'/api/admin/costo_servicio',this.costos)
          .then(response => { this.get_costos_servicio(); })
          .catch( (e) =>{ console.log("Error al cargar...") })
    }
  },

 async remover_examen(id) {
    axios.post(window.hostname+'/api/admin/remove_examen',{id:id})
        .then( async (response) => 
        {
          await this.recargar({prestador_id:this.prestador.id})
          this.initialize_();
          
          
        })

        .catch(e=>{
            console.log(e)
        })
  },
  agregar_examen(){
    axios.post(window.hostname+'/api/admin/add_examen')
        .then(response => 
        {
          this.recargar({prestador_id:this.prestador.id}).then( ()=>{
          this.initialize_();
          })
          
        })

        .catch(e=>{
            console.log(e)
        })
  },

  modificar(id){
    let data = this.editando_examenes.filter(x=>{return x.id == id})[0]
      axios.post(window.hostname+'/api/admin/modificar_examen', 
        {id: id,
        categoria_id:data.ecategoria,
        precio:data.eprecio,
        desde:data.edesde,
        activo:data.edesde,
        hasta:data.ehasta,
        sugerido:data.ehasta,
        informacion:data.einformacion,
        requiere_orden:data.erequiere_orden,
        requiere_pago:data.erequiere_pago,
        nombre:data.enombre,
        keywords:data.ekeywords,
        codigo:data.ecodigo,
        })
        .then(response => 
        {
          this.recargar({prestador_id:this.prestador.id}).then( ()=>{
          this.initialize_();
          })
          
        })

        .catch(e=>{
            this.loaded = true;
            console.log(e)
        })

  },
    
  initialize_(pid){
      this.loaded = false;
      axios.get(window.hostname+'/api/admin/init_modificar_examenes')
      .then(response => 
      {
          try{

         
          this.editando_examenes = []
          this.categorias = response.data.categorias

          for(var i = 0; i <= this.examenes.length; i++){
            this.editando_examenes.push({

                nombre:this.examenes[i].nombre,
                id:this.examenes[i].id,
                precio:this.examenes[i].precio,
                informacion:this.examenes[i].informacion,
                keywords:this.examenes[i].keywords,
                categoria:this.examenes[i].categoria_id,
                requiere_orden:this.examenes[i].requiere_orden,
                requiere_pago:this.examenes[i].requiere_pago,
                desde:this.examenes[i].desde,
                activo:this.examenes[i].activo,
                hasta:this.examenes[i].hasta,
                sugerido:this.examenes[i].hasta,
                codigo:this.examenes[i].codigo,
                
                enombre:this.examenes[i].nombre,
                eid:this.examenes[i].id,
                eprecio:this.examenes[i].precio,
                einformacion:this.examenes[i].informacion,
                ekeywords:this.examenes[i].keywords,
                ecategoria:this.examenes[i].categoria_id,
                erequiere_orden:this.examenes[i].requiere_orden,
                erequiere_pago:this.examenes[i].requiere_pago,
                edesde:this.examenes[i].activo,
                ehasta:this.examenes[i].hasta,
                ecodigo:this.examenes[i].codigo,

                modificar:false,
            })
            this.loaded = true;
          }

          } catch(e){
            console.log(e)
            this.loaded = true;
            
          }
      })

      .catch(e=>{
          this.loaded = true;
          console.log("Error al cargar...")
      })

  },


  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes', 'prestador']),
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
    examenes(){
      this.initialize_()
    }
  },
  mounted() {
    const scrollDiv = document.querySelector('.scroll-div');

let isDragging = false;
let lastX;

scrollDiv.addEventListener('mousedown', (e) => {
    isDragging = true;
    lastX = e.clientX;
    scrollDiv.style.cursor = 'grabbing';
});

document.addEventListener('mousemove', (e) => {
    if (!isDragging) return;

    const deltaX = lastX - e.clientX;
    scrollDiv.scrollLeft += deltaX;

    lastX = e.clientX;
});

document.addEventListener('mouseup', () => {
    isDragging = false;
    scrollDiv.style.cursor = 'grab';
});

    this.ENV = process.env;
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']});
  }

}
</script>


<style scoped>

.flex-header{
  display: flex;
  justify-content: space-between;
}
.flex-header-right{
  text-align: right;
}
.flex-header-left{
  display: flex;
  align-items: center;
}
.scroll-div {


cursor: grab !important;
overflow-x: scroll;
overflow-y: hidden;
position: relative;

}

table{
  max-width: 100%;
}
thead td{
  font-weight: 700;
  padding:0px 10px;
}
td{
  text-align: left;
}
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;



  
  margin: 0 auto;




}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);

  padding-top:0px;
  padding-left:275px;
  width:100%;
  

}
.main-container{
  background-color: rgb(242, 247, 251);
  padding: 10px;

  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

