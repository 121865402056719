<!-- eslint-disable -->
<template>
<div>
<transition name="zoom-m">


  <div v-if="showing" id="modal-receta-m" >
    
  <div v-on:click="hide();" class="contenedor-icono">       
    <b-icon class="icon" icon="x"></b-icon>
  </div>
  <div v-on:click="estado = estado - 1" :style="{'background-color': prestador.atributos.assets.colors.secondary }" class="btn-volv" v-if="(estado != 0 && prestador.id != 1) || (estado != -1 && es_principal)">
    <b-icon icon="arrow-left"></b-icon>
    Volver
  </div>

  <div class="main-m">


   <div v-if="estado == 1">

      <div  class="main-grid-2">

      
<div class="leftyx2">

  <div style="width: 100%;">


<div class="orden-container" v-if="examenes_agregados.length > 0">
<div class="tu-orden">
2.- Configuración de pacientes
</div>
<!-- <div class="tu-orden-sub">
  Puedes agregar más exámenes y agregar más pacientes .

</div> -->
<br/>

<div v-for="p, i in examenes_agregados" class="paciente">
    <div class="gridd">
      
      <div class="g1">Paciente {{ i+1 }}  <b-icon icon="trash" style="color:#FF4136; cursor: pointer;" v-on:click="removerPaciente(i)"> </b-icon> </div>
      <div class="g2">Previsión: 
        <select class="selector form-select" style="display:inline-block; width:120px;" v-model="p.aseguradora_id">
    <option :value="0">Seleccionar</option>
    <option v-for="a in aseguradoras" :value="a.id">{{a.nombre}}</option>
    
  </select>
      </div>
    </div>
    
    <div style="padding:3px 10px; color:rgb(40, 40, 40); font-weight: 600; ">
      <table style="width:100%; margin-top:10px;">
        <td style="width:75%; padding-left:20px;  max-width: 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">Servicio</td>
        <td style="width:15%;">Valor</td>
        <td style="width:10%;"></td>
      </table>
    </div>

    <div v-for="ex, j in p.examenes">
      <div class="table-cont">
      <table style="width:100%">
        <td style="width:75%;  max-width: 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
          <b-icon
            style="
              color: #5e81bc;
              height: 12px;
              width: 12px;
              transform: translateY(-1px);
              margin-right: 6px;
              margin-left: 20px;
            "
            icon="heart-fill"
            ></b-icon>
          {{ getExamen(ex).nombre }}
        
        </td>
        <td style="width:15%;">
        <div class="seleccionar-comuna-btn" v-on:click="$refs.modalcomuna.show()" v-if="comuna == -1">Seleccionar Comuna</div>
        
        <div v-else>
          
$ {{ formatNum(getPrecioExamen(ex, comuna, p.categoria_cliente_id).precio  
-  getReembolsoExamen(ex, p.aseguradora_id, p.categoria_cliente_id)
- (valores_codigo.find( (x) => x.examen_id == ex) || {valor:0}).valor) }}
<!--           {{
                        getPrecioExamen(ex, comuna, p.categoria_cliente_id).multiples_precios && comuna < 0 ? '-'
                        :
                        '$ ' + formatNum(
                          (
                            getPrecioExamen(ex, comuna, p.categoria_cliente_id).precio
                            -  getReembolsoExamen(ex, p.aseguradora_id, p.categoria_cliente_id)
                            - (valores_codigo.find( (x) => x.examen_id == ex) || {valor:0}).valor
                          ) > 0 ?
                          (
                            getPrecioExamen(ex, comuna, p.categoria_cliente_id).precio
                            -  getReembolsoExamen(ex, p.aseguradora_id, p.categoria_cliente_id)
                            - (valores_codigo.find( (x) => x.examen_id == ex) || {valor:0}).valor
                          )
                          :
                          0
                        )
                      }}
           -->
          

       
        </div>
        </td>
        <td style="width:10%; text-align: center;"><b-icon v-on:click="removerExamen(i, j)" style="color:#FF4136; cursor: pointer;" icon="x-circle"></b-icon></td>
      </table>
    </div>
      
    </div>
    <div class="table-cont">
      <table style="width:100%">
        <td style="width:75%;  max-width: 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
          <b-icon
            style="
              color: #5e81bc;
              height: 16px;
              margin-left: 18px;
              margin-right:5px;
              width: 16px;
              transform:translateY(2px);" 
              icon="truck"></b-icon>
          Servicio domiciliario
        </td>
        <td style="width:15%;">
          $ {{ formatNum(calcularCostoServicioPaciente(examenes_agregados.length, p.aseguradora_id, p.categoria_cliente_id, i, comuna)) }}

        </td>
        <td style="width:10%; text-align: center;">
          
        </td>
      </table>

    </div>
<!--     <div v-if="buscando_examen != '' && agregando_examen_paciente  != -1" style="position: fixed; height: 100vh; width: 100vw; top:0px; left:0px; background-color: rgba(0,0,0,0.12); z-index: 9998;"></div>

 -->
    <div   class="table-cont">
      
      <table style="width:100%">
        <td style="width:75%;  max-width: 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
          
          <input 
          :style="{'z-index': agregando_examen_paciente == i ? '9999': '1'}"  
          style=" position: relative;" 
          @blur="_blur();  $event.target.value = ''" @focus="agregando_examen_paciente = i"
           v-on:input="buscando_examen = $event.target.value; agregando_examen_paciente = i;"  
           class="input-add" 
           type="text" 
           placeholder="Agrega otro examen..."
           >

         
        </td>
        <td style="width:15%;">
        

        </td>
     
      </table>

      <!--Modal agregar examen-->
      <div 
        v-if="agregando_examen_paciente == i && resultado_fuse_examen.length > 0" 
        :style="{'z-index': agregando_examen_paciente != -1 ? '9999': '1'}"             
        class="popup-examen"
       >
        <div v-on:click="click_servicio(e.item, i)" class="buscando-item" v-for="e in resultado_fuse_examen.slice(0, 3)">
          <div class="buscando-item-nombre">
            {{ e.item.nombre }}
          </div>
          <div class="buscando-item-precio">
            
            {{
                        getPrecioExamen(e.item.id, comuna, p.categoria_cliente_id).multiples_precios && comuna < 0 ? 'INDICAR COMUNA'
                        :
                        '$ ' + formatNum(
                          (
                            getPrecioExamen(e.item.id, comuna, p.categoria_cliente_id).precio
                            -  getReembolsoExamen(e.item.id, p.aseguradora_id, p.categoria_cliente_id)
                            - (valores_codigo.find( (x) => x.examen_id == e.item.id) || {valor:0}).valor
                          ) > 0 ?
                          (
                            getPrecioExamen(e.item.id, comuna, p.categoria_cliente_id).precio
                            -  getReembolsoExamen(e.item.id, p.aseguradora_id, p.categoria_cliente_id)
                            - (valores_codigo.find( (x) => x.examen_id == e.item.id ) || {valor:0}).valor
                          )
                          :
                          0
                        )
                      }}
          </div>
        </div>
      </div>


    </div>
   <!--  <button v-on:click="removerPaciente(i)" class="btn-rem"> Remover paciente <b-icon icon="x-circle"></b-icon></button> -->

  </div>
  <button v-on:click="agregarPaciente()" class="btn-pac"> <b-icon icon="person"></b-icon> Agregar otro paciente</button>

</div>


  </div>

</div>

<div class="rightyx2">


<div  >
<div style="margin-bottom: 20px;">

<span style="    font-weight: 600; font-size: 16px;">Resumen </span>
<!-- <img style="max-width: 100px; max-height: 45px; margin-left:0px;" :src="'https://assets-prestador-clinicgo.s3-sa-east-1.amazonaws.com/'+prestador_selected+'/images/logo.png'" alt=""/>
 -->
</div>

<div  class="grid-2">

<template v-for="p in examenes_agregados">
<template v-for="e in p.examenes">
<div class="grid-2-left" >{{getExamen(e).nombre}}</div>
<div v-if="comuna != -1" class="grid-2-right">


  {{
                        getPrecioExamen(e, comuna, p.categoria_cliente_id).multiples_precios && comuna < 0 ? '-'
                        :
                        '$ ' + formatNum(
                          (
                            getPrecioExamen(e, comuna, p.categoria_cliente_id).precio
                            -  getReembolsoExamen(e, p.aseguradora_id, p.categoria_cliente_id)
                            - (valores_codigo.find( (x) => x.examen_id == e) || {valor:0}).valor
                          ) > 0 ?
                          (
                            getPrecioExamen(e, comuna, p.categoria_cliente_id).precio
                            -  getReembolsoExamen(e, p.aseguradora_id, p.categoria_cliente_id)
                            - (valores_codigo.find( (x) => x.examen_id == e ) || {valor:0}).valor
                          )
                          :
                          0
                        )
                      }}


</div>
</template>
</template>


<div  class="total-c grid-2-left" style="border-top:1px solid rgb(240,240,240);">   </div>
<div class="grid-2-right total-c" style="border-top:1px solid rgb(240,240,240);">
</div>

<!-- <div  class="total-c grid-2-left"> Total servicio  </div>
<div class="grid-2-right total-c" style="font-weight: 700;">
{{ solicitarComuna(examenes_agregados, comuna) ? '-' : '$'+formatNum(calcularTotalServicio(examenes_agregados)) }}
</div>
<div  class="total-o grid-2-left"> Total Orden  </div>
<div class="grid-2-right total-o" style="font-weight: 700;">
{{ solicitarComuna(examenes_agregados, comuna) ? '-' : '$'+formatNum(calcularTotalOrden(examenes_agregados, comuna)) }}
</div> -->

</div>

</div>



<button v-on:click="checkout()" :style="{'background-color': prestador.atributos.assets.colors.primary }" class="btn-pagar"> Continuar</button>
</div>

</div>
   </div>

    <!-- Estado 0: seleccionar prestador, exclusivo clinicgo -->
      <div v-if="(estado == -1 && es_principal) && !loading_transferencia && !orden_enviada">
   
   <div class="tu-orden">0. Seleccione el prestador</div>
 
     <div v-if="!loading_prestador" class="buscador-cont">
      <select @change="onSelectPrestadorChange" v-model="selectedPrestador">
      <option v-for="prestador_ in prestadores" :key="prestador_.id" :value="prestador_.id">
        {{ prestador_.nombre }}
      </option>
    </select>
     </div>
     <div v-else>
      <div
                class="spinner-border spinner-border-sm"
                role="status"
              /> Reconfigurando...

     </div>
     <button v-on:click="estado = 0" :style="{'background-color': prestador.atributos.assets.colors.primary }" class="btn-pagar"> Continuar</button>

  </div>

   <!-- Primer Estado: Comuna -->
   <div v-if="(estado == 0 || (estado == -1 && prestador.id != 1)) && !loading_transferencia && !orden_enviada">
   
    <div class="tu-orden">1. Selecciona una comuna</div>
      <div class="buscador-cont">
         <input placeholder="Escribe tu comuna..." style="width: 100%; outline: none; padding: 5px 10px; border-radius: 4px; border: 1px solid rgba(0,0,0,0.7);" v-model="comuna_buscado" v-on:input="comuna_buscado = $event.target.value" type="text">
         <div v-if="comuna_buscado != ''" class="popup-comuna">
            <div v-if="resultado_fuse_comuna.length > 0">
               <div v-on:click="clickearComuna(c.item)" class="item-comuna" v-for="c in resultado_fuse_comuna.slice(0, 6)">{{c.item.Nombre}}</div>
            </div>
            <div class="item-comun" v-else>
               <div style="padding: 0px 2px">No se encontraron comunas</div>
            </div>
         </div>
      </div>
   </div>



   <!-- Segundo Estado: Fecha y hora -->
   <div v-else-if="estado == 2 && !loading_transferencia && !orden_enviada">
    <div class="tu-orden">
3.- Selecciona día, y hora.
</div>
      <div class="doble-columna">
                <div class="calendar boxy">
                  <div class="number">1</div>
                    <div class="msg-top">Seleccione una fecha en el calendario</div>
                    <div  class="button-container">
                      <h2 style="font-size:16px;">{{meses[viendoMes]}} del {{viendoAño}} </h2>
                      
                      <div v-on:click="restarMes()" class="btn-prev " ><b-icon style="transform:translateY(1px);" icon="arrow-left"/> </div>
                      <div v-on:click="sumarMes()" class="btn-next " ><b-icon style="transform:translateY(1px);" icon="arrow-right"/></div>
                    </div>
                 

                  <table>

                    <thead>

                      <tr>

                        <td v-for="d in dias_av">{{d}}</td>

                      </tr>
                    </thead>
                    <tbody>

                      <tr>
                        <td 
                        :style="{'background-color': (i-mapping[new Date(viendoAño, viendoMes, 1).getDay()] == selectedDia && viendoMes == selectedMes && viendoAño == selectedAño)  ? prestador.atributos.assets.colors.primary : ''}"
                        :class="{
                        'current-day ' : (i-mapping[new Date(viendoAño, viendoMes, 1).getDay()] == hoyDia && viendoMes == hoyMes && viendoAño == hoyAño),
                        'selected-day' : ((i-mapping[new Date(viendoAño, viendoMes, 1).getDay()] == selectedDia && viendoMes == selectedMes && viendoAño == selectedAño)),
                        'disponible' :disponibilidad_diaria[i-mapping[new Date(viendoAño, viendoMes, 1).getDay()]+'-'+(viendoMes+1)+'-'+viendoAño] == 1,
                        'no-disponible' :disponibilidad_diaria[i-mapping[new Date(viendoAño, viendoMes, 1).getDay()]+'-'+(viendoMes+1)+'-'+viendoAño] == 0,
                        }"
                        v-for="i in 7"
                        v-on:click="seleccionarDia(i-mapping[new Date(viendoAño, viendoMes, 1).getDay()], viendoMes, viendoAño); tiSelected = -1; tfSelected = -1; combinaciones = []; verDisponibilidadSiPuede();">{{i-1 >= mapping[new Date(viendoAño, viendoMes, 1).getDay()] ? i-mapping[new Date(viendoAño, viendoMes, 1).getDay()] : '-'}}  </td>

                      </tr>
                      <tr v-if="UltimoDia(viendoAño, viendoMes) >= 7-(mapping[new Date(viendoAño, viendoMes, 1).getDay()])+1 + 7*(tr-1)" v-for="tr in 5">
                        <td 
                        v-on:click="seleccionarDia(7-(mapping[new Date(viendoAño, viendoMes, 1).getDay()])+td + 7*(tr-1), viendoMes, viendoAño); tiSelected = -1; tfSelected = -1; combinaciones = []; verDisponibilidadSiPuede();"
                        :style="{'background-color': (7-(mapping[new Date(viendoAño, viendoMes, 1).getDay()])+td + 7*(tr-1) == selectedDia && viendoMes == selectedMes && viendoAño == selectedAño)  ? prestador.atributos.assets.colors.primary : ''}"
                        :class="{
                          'current-day ' : ((7-(mapping[new Date(viendoAño, viendoMes, 1).getDay()])+td + 7*(tr-1) == hoyDia && viendoMes == hoyMes && viendoAño == hoyAño)), 
                          'selected-day': ((7-(mapping[new Date(viendoAño, viendoMes, 1).getDay()])+td + 7*(tr-1) == selectedDia && viendoMes == selectedMes && viendoAño == selectedAño)),
                          'disponible' : disponibilidad_diaria[(7-(mapping[new Date(viendoAño, viendoMes, 1).getDay()])+td + 7*(tr-1))+'-'+(viendoMes+1)+'-'+viendoAño] == 1,
                          'no-disponible' : disponibilidad_diaria[(7-(mapping[new Date(viendoAño, viendoMes, 1).getDay()])+td + 7*(tr-1))+'-'+(viendoMes+1)+'-'+viendoAño] == 0,
                          }" 
                        v-for="td in 7">
                        
                    
                          {{UltimoDia(viendoAño, viendoMes) >= 7-(mapping[new Date(viendoAño, viendoMes, 1).getDay()])+td + 7*(tr-1) ? 7-(mapping[new Date(viendoAño, viendoMes, 1).getDay()])+td + 7*(tr-1):'-'}} 

                          </td>

                      </tr>
                    </tbody>

                  </table>

                </div>
                <div class="horarios boxy">
                  <div class="number">2</div>
                  <div class="msg-top">Seleccione un horario disponibles</div> 
                                
                <div style="text-align:left;" v-if="!cargando_disponibles">
                  <span v-if="buscadoDia != 0" style="font-size:14px; margin-bottom:0px; margin-left:5px; margin-top:15px;">Horas disponibles para el {{buscadoDia}} de {{meses[buscadoMes]}} de {{buscadoAño}}.</span>
                  

                  <!-- Incorporando regla de am-->

                  <div v-if="prestador.atributos.config.mostrar_primero_horas_hasta == undefined || isNaN(prestador.atributos.config.mostrar_primero_horas_hasta)">

                    <h4 v-if="horasDisponiblesC.length >0" style="margin-bottom:0px; margin-left:5px; margin-top:10px; font-size:14px;">Mañana</h4>
                    
                    <div  @mouseenter="h.hovering = true;" @mouseleave="h.hovering=false;" v-for="h in horasDisponiblesC" v-if="h.nombre == '' && horasDisponiblesC.length >0 && h.desde < 720" class="horas" v-on:click="selected_prioridad = h.prioridad; selected_recargo_suma = h.recargo_suma; selected_recargo_porcentaje = h.recargo_porcentaje; tiSelected = h.desde; tfSelected= h.hasta; categorias_selected_c = h.categorias;" :style="{background: h.desde == tiSelected && h.hasta == tfSelected ? prestador.atributos.assets.colors.primary: '', 'background-color': h.hovering || ( tiSelected && h.hasta == tfSelected) ? prestador.atributos.assets.colors.primary : '', color: (h.desde == tiSelected && h.hasta == tfSelected) || h.hovering ? 'white':  h.color, 'border-color': h.desde == tiSelected && h.hasta == tfSelected ? '':  h.color}  " >{{timeConvert(h.desde)}} - {{timeConvert(h.hasta)}}</div>
                    <h4 v-if="mostrar_tarde" style="margin-bottom:0px; margin-left:5px; margin-top:10px; font-size:14px;">Tarde</h4>
                    <div v-for="h in horasDisponiblesC" @mouseenter="h.hovering = true;" @mouseleave="h.hovering=false;" v-if="h.nombre == '' && horasDisponiblesC.length >0 && h.desde >= 720" class="horas" v-on:click="selected_prioridad = h.prioridad; selected_recargo_suma = h.recargo_suma; selected_recargo_porcentaje = h.recargo_porcentaje; tiSelected = h.desde; tfSelected= h.hasta; categorias_selected_c = h.categorias;" :style="{background: h.desde == tiSelected && h.hasta == tfSelected ? prestador.atributos.assets.colors.primary: '','background-color': h.hovering || ( tiSelected && h.hasta == tfSelected) ? prestador.atributos.assets.colors.primary : '', color: (h.desde == tiSelected && h.hasta == tfSelected) || h.hovering ? 'white':  h.color}" >{{timeConvert(h.desde)}} - {{timeConvert(h.hasta)}}   </div>
                    <div style="font-style: italic;" v-if="horasDisponiblesC.length == 0 && buscadoDia != 0">No se encontraron horas para este día. Por favor, intenta buscando en otro.</div>
                    <div style="margin-top:20px;" v-if="n.id != '00'" v-for="n in nombres">
                      <strong :style="{'color':horasDisponiblesC.find(x=>{return x.nombre == n.nombre}).color}">{{n.nombre}},
                      

                      <span v-if="n.recargo_suma >0 && n.recargo_porcentaje >0">con {{n.recargo_porcentaje}}% de recargo sobre el total y  ${{Intl.NumberFormat('de-DE').format(n.recargo_suma)}} de recargo adicional</span>
                      <span v-else-if="n.recargo_suma >0 && n.recargo_porcentaje == 0"> con ${{Intl.NumberFormat('de-DE').format(n.recargo_suma)}} de recargo adicional</span>
                      <span v-else-if="n.recargo_suma == 0 && n.recargo_porcentaje > 0"> con {{n.recargo_porcentaje}}% de recargo sobre el total</span>
                      <span v-else> sin recargo adicional</span>:
                      </strong>
                      <br />
                      
                        <div v-for="h in horasDisponiblesC" v-if="h.nombre == n.nombre && n.recargo_porcentaje == h.recargo_porcentaje && n.recargo_suma == h.recargo_suma" class="horas" v-on:click="selected_prioridad = h.prioridad; selected_recargo_suma = h.recargo_suma; selected_recargo_porcentaje = h.recargo_porcentaje; tiSelected = h.desde; tfSelected= h.hasta; categorias_selected_c = h.categorias; " :style="{background: h.desde == tiSelected && h.hasta == tfSelected ? 'rgb(0, 0, 0)': '', color: h.desde == tiSelected && h.hasta == tfSelected ? 'white':  h.color, 'border-color': h.desde == tiSelected && h.hasta == tfSelected ? '':  h.color}  " >{{timeConvert(h.desde)}} - {{timeConvert(h.hasta)}}</div>
                    </div>

                  
                  </div>
                  <div v-else>
                    <div v-if="horasDisponiblesC.some(x=>x.hasta < prestador.atributos.config.mostrar_primero_horas_hasta)">

                      <!--Hay horas disponibles bajo el corte-->

                      <h4 v-if="true" style="margin-bottom:0px; margin-left:5px; margin-top:10px; font-size:14px;">Mañana</h4>
                    
                    <div  
                    @mouseenter="h.hovering = true;" 
                    @mouseleave="h.hovering=false;" 
                    v-for="h in horasDisponiblesC" 
                    v-if="h.nombre == '' && horasDisponiblesC.length >0 && h.desde < 720 && (h.hasta < prestador.atributos.config.mostrar_primero_horas_hasta || mostrar_mas)" 
                    class="horas" 
                    v-on:click="selected_prioridad = h.prioridad; selected_recargo_suma = h.recargo_suma; selected_recargo_porcentaje = h.recargo_porcentaje; tiSelected = h.desde; tfSelected= h.hasta; categorias_selected_c = h.categorias;" 
                    :style="{
                      background: h.desde == tiSelected && h.hasta == tfSelected ? prestador.atributos.assets.colors.primary: '', 
                      'background-color': h.hovering || ( tiSelected && h.hasta == tfSelected) ? prestador.atributos.assets.colors.primary : '',
                       color: (h.desde == tiSelected && h.hasta == tfSelected) || h.hovering ? 'white':  h.color, 
                       'border-color': h.desde == tiSelected && h.hasta == tfSelected ? '':  h.color
                      }
                         " >
                    {{timeConvert(h.desde)}} - {{timeConvert(h.hasta)}}
                  
                  </div>
                    
                    <h4 v-if="mostrar_tarde" style="margin-bottom:0px; margin-left:5px; margin-top:10px; font-size:14px;">Tarde</h4>
                    <div
                     v-for="h in horasDisponiblesC" 
                     v-if="h.nombre == '' && horasDisponiblesC.length >0 && h.desde >= 720 && (h.hasta < prestador.atributos.config.mostrar_primero_horas_hasta || mostrar_mas)"                    
                      @mouseenter="h.hovering = true;" 
                      @mouseleave="h.hovering=false;" 
                      class="horas" 
                      v-on:click="selected_prioridad = h.prioridad; selected_recargo_suma = h.recargo_suma; selected_recargo_porcentaje = h.recargo_porcentaje; tiSelected = h.desde; tfSelected= h.hasta; categorias_selected_c = h.categorias;" 
                      :style="{
                        background: h.desde == tiSelected && h.hasta == tfSelected ? prestador.atributos.assets.colors.primary: '',
                        'background-color': h.hovering || ( tiSelected && h.hasta == tfSelected) ? prestador.atributos.assets.colors.primary : '',
                        color: (h.desde == tiSelected && h.hasta == tfSelected) || h.hovering ? 'white':  h.color
                      }"
                    >
                    
                       {{timeConvert(h.desde)}} - {{timeConvert(h.hasta)}}   
                      
                      </div>
                    <div style="font-style: italic;" v-if="horasDisponiblesC.length == 0 && buscadoDia != 0">No se encontraron horas para este día. Por favor, intenta buscando en otro.</div>
                    <div style="margin-top:20px;" v-if="n.id != '00'" v-for="n in nombres">
                      <strong :style="{'color':horasDisponiblesC.find(x=>{return x.nombre == n.nombre}).color}">{{n.nombre}},
                      

                      <span v-if="n.recargo_suma >0 && n.recargo_porcentaje >0">con {{n.recargo_porcentaje}}% de recargo sobre el total y  ${{Intl.NumberFormat('de-DE').format(n.recargo_suma)}} de recargo adicional</span>
                      <span v-else-if="n.recargo_suma >0 && n.recargo_porcentaje == 0"> con ${{Intl.NumberFormat('de-DE').format(n.recargo_suma)}} de recargo adicional</span>
                      <span v-else-if="n.recargo_suma == 0 && n.recargo_porcentaje > 0"> con {{n.recargo_porcentaje}}% de recargo sobre el total</span>
                      <span v-else> sin recargo adicional</span>:
                      </strong>
                      <br />
                      
                        <div v-for="h in horasDisponiblesC" v-if="h.nombre == n.nombre && n.recargo_porcentaje == h.recargo_porcentaje && n.recargo_suma == h.recargo_suma" class="horas" v-on:click="selected_prioridad = h.prioridad; selected_recargo_suma = h.recargo_suma; selected_recargo_porcentaje = h.recargo_porcentaje; tiSelected = h.desde; tfSelected= h.hasta; categorias_selected_c = h.categorias; " :style="{background: h.desde == tiSelected && h.hasta == tfSelected ? 'rgb(0, 0, 0)': '', color: h.desde == tiSelected && h.hasta == tfSelected ? 'white':  h.color, 'border-color': h.desde == tiSelected && h.hasta == tfSelected ? '':  h.color}  " >{{timeConvert(h.desde)}} - {{timeConvert(h.hasta)}}</div>
                    </div>





                    </div>

                    <div v-else>
                     <!--No hay ninguna hora disponible bajo el corte-->
                    <h4 v-if="horasDisponiblesC.length >0" style="margin-bottom:0px; margin-left:5px; margin-top:10px; font-size:14px;">Mañana</h4>
                    
                    <div  @mouseenter="h.hovering = true;" @mouseleave="h.hovering=false;" v-for="h in horasDisponiblesC" v-if="h.nombre == '' && horasDisponiblesC.length >0 && h.desde < 720" class="horas" v-on:click="selected_prioridad = h.prioridad; selected_recargo_suma = h.recargo_suma; selected_recargo_porcentaje = h.recargo_porcentaje; tiSelected = h.desde; tfSelected= h.hasta; categorias_selected_c = h.categorias;  " :style="{background: h.desde == tiSelected && h.hasta == tfSelected ? prestador.atributos.assets.colors.primary: '', 'background-color': h.hovering || ( tiSelected && h.hasta == tfSelected) ? prestador.atributos.assets.colors.primary : '', color: (h.desde == tiSelected && h.hasta == tfSelected) || h.hovering ? 'white':  h.color, 'border-color': h.desde == tiSelected && h.hasta == tfSelected ? '':  h.color}  " >{{timeConvert(h.desde)}} - {{timeConvert(h.hasta)}}</div>
                    <h4 v-if="mostrar_tarde" style="margin-bottom:0px; margin-left:5px; margin-top:10px; font-size:14px;">Tarde</h4>
                    <div v-for="h in horasDisponiblesC" @mouseenter="h.hovering = true;" @mouseleave="h.hovering=false;" v-if="h.nombre == '' && horasDisponiblesC.length >0 && h.desde >= 720" class="horas" v-on:click="selected_prioridad = h.prioridad; selected_recargo_suma = h.recargo_suma; selected_recargo_porcentaje = h.recargo_porcentaje; tiSelected = h.desde; tfSelected= h.hasta; categorias_selected_c = h.categorias; " :style="{background: h.desde == tiSelected && h.hasta == tfSelected ? prestador.atributos.assets.colors.primary: '','background-color': h.hovering || ( tiSelected && h.hasta == tfSelected) ? prestador.atributos.assets.colors.primary : '', color: (h.desde == tiSelected && h.hasta == tfSelected) || h.hovering ? 'white':  h.color}" >{{timeConvert(h.desde)}} - {{timeConvert(h.hasta)}}   </div>
                    <div style="font-style: italic;" v-if="horasDisponiblesC.length == 0 && buscadoDia != 0">No se encontraron horas para este día. Por favor, intenta buscando en otro.</div>
                    <div style="margin-top:20px;" v-if="n.id != '00'" v-for="n in nombres">
                      <strong :style="{'color':horasDisponiblesC.find(x=>{return x.nombre == n.nombre}).color}">{{n.nombre}},
                      

                      <span v-if="n.recargo_suma >0 && n.recargo_porcentaje >0">con {{n.recargo_porcentaje}}% de recargo sobre el total y  ${{Intl.NumberFormat('de-DE').format(n.recargo_suma)}} de recargo adicional</span>
                      <span v-else-if="n.recargo_suma >0 && n.recargo_porcentaje == 0"> con ${{Intl.NumberFormat('de-DE').format(n.recargo_suma)}} de recargo adicional</span>
                      <span v-else-if="n.recargo_suma == 0 && n.recargo_porcentaje > 0"> con {{n.recargo_porcentaje}}% de recargo sobre el total</span>
                      <span v-else> sin recargo adicional</span>:
                      </strong>
                      <br />
                      
                        <div v-for="h in horasDisponiblesC" v-if="h.nombre == n.nombre && n.recargo_porcentaje == h.recargo_porcentaje && n.recargo_suma == h.recargo_suma" class="horas" v-on:click="selected_prioridad = h.prioridad; selected_recargo_suma = h.recargo_suma; selected_recargo_porcentaje = h.recargo_porcentaje; tiSelected = h.desde; tfSelected= h.hasta; categorias_selected_c = h.categorias; " :style="{background: h.desde == tiSelected && h.hasta == tfSelected ? 'rgb(0, 0, 0)': '', color: h.desde == tiSelected && h.hasta == tfSelected ? 'white':  h.color, 'border-color': h.desde == tiSelected && h.hasta == tfSelected ? '':  h.color}  " >{{timeConvert(h.desde)}} - {{timeConvert(h.hasta)}}</div>
                    </div>
                    


                    </div>
                    <div v-if="horasDisponiblesC.length > 0" style="text-align: center;"> <button v-on:click="mostrar_mas = !mostrar_mas" class="btn-mostrar-mas" :style="{'background-color': prestador.atributos.assets.colors.primary }" >Mostrar {{ mostrar_mas ? 'menos' : 'más'}}</button></div>
                   


                  </div>


                </div>
                <div v-if="cargando_disponibles">
                    <div style="width:100%; text-align:center; padding:20px; padding-bottom:40px;">
        
                    <div class="spinner-border text-secondary" style="margin: 0 auto; " role="status"></div>
                      <div style="display: inline-block; transform:translate(6px, -5px);"> Cargando...</div>
                  </div>  
                </div>
                </div>   

                  
              </div>
              <button v-on:click="checkout_fecha_y_hora()" :style="{'background-color': prestador.atributos.assets.colors.primary }" class="btn-pagar"> Continuar</button>
   </div>

   <div v-else-if="estado == 4 && !loading_transferencia && !orden_enviada">
    <div class="tu-orden">
5.- Incluye las recetas médicas.
</div>




<div class="home" >

    
    <div>
      
 
        <div class="outer-container">

        <div v-for="p, i in examenes_agregados" style="box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; border-radius:15px; margin-top:15px; ">
          <div class="inner-container">
            <div style="padding: 5px 0px; margin-top:0px; position:relative;" >
              
              <b-icon class="icon-excl" v-if="((p.examenes.some(x=>{ return x.requiere_orden == 1}) || p.examenes.length == 0)) && p.orden_medica == null" icon="exclamation-triangle"></b-icon> 
              <b-icon class="icon-check" v-else icon="check"></b-icon>
              <div class="barrita-separadora"></div>
                <div class="name-container">
                  {{p.nombre}} {{p.apellido1}}
                </div>
              </div>
            </div>
 

            <div style="display:inline-block; position:relative; width:160px; height:60px; vertical-align:middle;">
              <div style="" v-if="pacienteRequiereReceta(p) || p.examenes.length == 0 ">
                <input multiple v-on:change="change_file(i)" :id="'file'+i" :ref="'file'+i"  type="file" accept="image/*, application/pdf" style="visibility:hidden; width:0px;" />
                <label :style="{'background-color': prestador.atributos.assets.colors.primary }" class="btn-receta"  :for="'file'+i">Subir orden <b-icon icon="camera"></b-icon></label>
              </div>

              <div style="cursor: not-allowed; position:absolute; border-radius:5px; color:rgba(0,0,0, 0.4); top:13px; left:0px; padding:5px 40px; background-color:rgb(241,241,241);" v-else>
                  No aplica
              </div>
            </div>
            
            <div v-if="p.orden_medica" >
            <div v-if="p.orden_medica.length > 0" style="padding: 10px;">
            <div   style="max-width: 450px; padding-bottom: 20px; display: inline-block; background-color: rgb(251,251,251); border-radius: 4px; padding:5px;">

              <table style="width: 100%; table-layout: fixed;">
                <tr>
                  <td style="text-align: left; font-weight: 700; height: 30px;" >Archivos</td>
                  <td style="width: 18px;" ></td>
                </tr>
                <tr v-for="o, j in p.orden_medica">
                    <td style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; text-align: left;">{{ o.nombre }}</td>
                    <td style="width: 15px;  text-align: left;"> <b-icon v-on:click="removerOrdenMedica(i, j)" style="color: #FF4136; cursor: pointer;" icon="x-circle"/></td>
                </tr>
              </table>

            </div>
          </div>
          </div>
        </div>

        
      </div>
      <div style="max-width: 600px; margin: 0 auto;" v-if="prestador.atributos.config.mensaje_orden_medica_html != undefined && prestador.atributos.config.mensaje_orden_medica_html != ''">
        <div v-html="prestador.atributos.config.mensaje_orden_medica_html"></div>
        </div>
 

    </div>


    


<!--     <div v-if="error_archivo" v-on:click="error_archivo = false" style="height:100vh; width:100vw; position:fixed; top: 0; left: 0; background-color:rgba(0,0,0,0.4); z-index:99998">
    </div>

    <div v-if="error_archivo" v-on:click="error_archivo = false"  class="comuna_error move">
      <b-icon icon="x-circle" style="height:30px; width:30px; margin-bottom:10px; fill:#FF4136;" alt=""/>
      <div  v-on:click="error_archivo = false"  style="color:#FF4136;">
        Archivo en <b>formato equivocado</b>. Por favor, suba un archivo en png, jpg, jpeg o pdf.
      </div>
    </div> -->

  </div>
  <button v-on:click="transferencia()" :style="{'background-color': prestador.atributos.assets.colors.primary }" class="btn-pagar"> Enviar orden</button>

   </div>

   <div v-else-if="estado == 3 && !loading_transferencia && !orden_enviada">

    <div class="home" style="padding-bottom:80px;">

<!--     <ModalGenerico :mensaje="'Estimado cliente <br/><br/> La edad mínima para realizar una atención domiciliaria son <b> '+prestador.atributos.config.edad_minima+' años<b/>.'" ref="modaledad"/>
 -->
<div class="spa" style="background-color:rgb(252,252,252);">



    <div class="container" style=" text-align:left;">
      <div class="tu-orden">
4.- Datos de paciente

</div>

      <p>






        

     

      </p>
    <!--  <span class="ribbon"> <b-icon icon="lock"></b-icon> Privado &nbsp; </span>--> 






 <div  >


<div v-for="p, i in examenes_agregados" style="margin-bottom:20px; border-radius: 15px; background-color:white; ">
        <div v-on:click="examen_buscado = ''; agregando_examen = false;"   v-b-toggle="'accordion-'+(i+1)" 
        style="width:100%; border-bottom:1px solid rgb(214, 214, 214);  padding:15px 15px 15px 30px; background:white;" > 

          <b-icon icon="person"></b-icon> 
          
          {{p.nombre != '' ? p.nombre+' '+p.apellido1 : 'Paciente '+(i+1)}} {{  }}

           
          <span style="float:right; margin-right:10px;">
            <b-icon style="transition:0.2s;" :style="{'transform': p.visible ? 'rotate(180deg)': 'none'}" icon="chevron-down"></b-icon>          
          </span>


        </div>

      <b-collapse v-model="p.visible" v-bind:id="'accordion-'+(i+1)" accordion="my-accordion" class="main-c">


      <div style="border-radius: 0px 0px 5px 5px;">
        <div class="telefono" style="position:relative; z-index:99; width:33%; position:relative; display:inline-block; font-size:13px; padding:4px; vertical-align:bottom;">
        <div>
           Rut o identificador 
           <span style="position: absolute; right:20px; top:32px" v-if="p.cargando_rut && prestador.atributos.config.requiere_verificar_ruts == 1 && verificar_rut(p.rut) && !focused_rut"><div style="height: 20px; width: 20px;" class="spinner-border text-secondary"></div></span>
           <span style="position: absolute; right:20px; top:32px; color:green;" v-if="prestador.atributos.config.requiere_verificar_ruts == 1 && p.habilitado == 1 && !p.cargando_rut && verificar_rut(p.rut)"> Admisible <b-icon icon="check-circle"></b-icon> </span>
           <span style="position: absolute; right:20px; top:32px; color:red;" v-if="prestador.atributos.config.requiere_verificar_ruts == 1 && p.habilitado != 1 && !p.cargando_rut && verificar_rut(p.rut)"> No admisible <b-icon style="" icon="x-circle"></b-icon> </span>
          <span v-if="!verificar_rut(p.rut) && p.rut != '' && !focused_rut && p.tipo_identificador == 'rut'" style="color:red; float:right; height:0px;">Formato Incorrecto  </span>
        </div>
          <div class="ident_prefico" 
               :style="{ 'border': !verificar_rut(p.rut) && p.rut != '' && !focused_rut && p.tipo_identificador == 'rut' ? '1px solid rgb(217,83,79)' :'1px solid rgb(210,210,210)'}"
          v-on:click="p.seleccionando_rut = !p.seleccionando_rut" style="height:38px; position:relative; float:left; cursor:pointer; display:inline-block; border-radius:5px 0px 0px 5px; padding:4px 4px 8px 8px; vertical-align:top;">
           
            <div class="ident_prefico" style="display:inline-block; vertical-align:middle; padding-left:7px; padding-right:15px;">
              {{tipo_identificador.find(x=>{return p.tipo_identificador == x.valor}).nombre}}
            </div>
             <b-icon class="ident_prefico" style="position:absolute; top:12px; right:4px; cursor:pointer; height:12px; width:12px;" icon="caret-down"></b-icon>
          </div>
         <div style=" float: none; overflow: hidden;">
           <input :class="{'is-invalid':!verificar_rut(p.rut) && p.rut != '' && !focused_rut && p.tipo_identificador == 'rut'}"
                  style="border-left:0px; display:inline-block; border-radius: 0px 5px 5px 0px; width:100%;"
                  v-model="p.rut" @focus="focused_rut = true;" @blur="focused_rut = false; autocompleteClient(p)" 
                  v-on:input="p.rut = $event.target.value; p.cargando_rut = true;"  
                  placeholder="12345678-9" 
                  class="form-control" type="text" name="" id="">
         </div>
         <div class="ident_prefico" v-if="p.seleccionando_rut" style="position:absolute; overflow-y: scroll; max-height:200px; top:60px; background:white; border-radius:5px; border: 1px solid rgb(210,210,210); padding:5px 0px;">
           <div class="ident_prefico" v-for="t in tipo_identificador" v-on:click="p.tipo_identificador = t.valor; p.seleccionando_rut = false;"  style="padding:6px 10px; cursor:pointer; ">
             <div style="display:inline-block; vertical-align:middle;">
               <div style="display:inline-block; padding:0px 5px 0px 5px;">{{t.nombre}} {{t.desc}}</div>
             {{t.nom}} 
             </div>
             
           </div>
         
         </div>
       </div>
      <div class="nombre" style="padding:4px; display:inline-block; font-size:13px; padding-right:7px; margin-top:0px; padding-top:0px;">Nombre <input @focus="focused_nombre = true;" @blur="focused_nombre = false" v-model="p.nombre" v-on:input="p.nombre = $event.target.value" placeholder="Maximiliano" class="form-control" type="text" name="" id=""></div>
      <div class="apellido1" style="display:inline-block;  font-size:13px; padding:4px; margin-top:0px; padding-top:0px;">Apellidos Paterno y Materno <input @focus="focused_apellido1 = true;" @blur="focused_apellido1 = false" v-model="p.apellido1"  v-on:input="p.apellido1 = $event.target.value"  placeholder="Pérez" class="form-control" type="text" name="" id=""></div>
      



      <div class="email" style=" padding:4px; width:33%; vertical-align:top; display:inline-block; font-size:13px; padding-right:7px;">Email <input :class="{'is-invalid':!verificar_correo(p.email) && !focused_email}" v-model="p.email" v-on:input="p.email = $event.target.value" @focus="focused_email = true;" @blur="focused_email = false" placeholder="maxpe@gmail.com" class="form-control" type="text" name="" id=""><span v-if="!verificar_correo(p.email) && !focused_email" style="color:red; float:right; height:0px;">
        Formato Incorrecto 
        </span>
        </div>

      <div class="telefono" style="width:33%; position:relative; display:inline-block; font-size:13px; padding:4px;">
        <div>Teléfono</div>
          <div class="num_prefico" v-on:click="p.seleccionando_telefono = !p.seleccionando_telefono" style="position:relative; float:left; cursor:pointer; display:inline-block; border: 1px solid rgb(210,210,210); border-radius:5px 0px 0px 5px; padding:4px 4px 8px 8px; vertical-align:top;">
           
            <img class="num_prefico" style="height:20px; vertical-align:middle; margin-top:4px;" :src="getFlagUrl(banderas.filter(z=>{return p.tel_prefijo == z.num})[0].arch)" alt="">
            <div class="num_prefico" style="display:inline-block; vertical-align:middle; padding-left:7px; padding-right:15px;">
              {{p.tel_prefijo}}
            </div>
             <b-icon class="num_prefico" style="position:absolute; top:12px; right:4px; cursor:pointer; height:12px; width:12px;" icon="caret-down"></b-icon>
          </div>
         <div style=" float: none; overflow: hidden;">
           <input oninput="this.value = this.value.replace(/[^0-9]/g, '')"  onkeypress='return event.charCode >= 48 && event.charCode <= 57' style="border-left:0px; display:inline-block; border-radius: 0px 5px 5px 0px; width:100%;" v-model="p.telefono" @focus="focused_telefono = true;" @blur="focused_telefono = false" v-on:input="p.telefono = $event.target.value"  placeholder="963854321" class="form-control" type="text" name="" id="">
         </div>
         <div class="num_prefico" v-if="p.seleccionando_telefono" style="position:absolute; overflow-y: scroll; max-height:200px; top:60px; background:white; border-radius:5px; border: 1px solid rgb(210,210,210); padding:5px 0px;">
           <div class="num_prefico" v-for="b in banderas" v-on:click="p.tel_prefijo = b.num; p.seleccionando_telefono = false;"  style="padding:6px 10px; cursor:pointer; ">
             <img style="height:20px; vertical-align:middle; margin-top:2px;" :src="getFlagUrl(b.arch)" alt="">
             <div style="display:inline-block; vertical-align:middle;">
               <div style="display:inline-block; padding:0px 5px 0px 5px;">{{b.num}}</div>
             {{b.nom}} 
             </div>
             
           </div>
         
         </div>
       
      </div>
     
      <div class="telefono" style="width:33%; display:inline-block; font-size:13px; padding:4px; vertical-align:top;">
        
               <div style="display:inline-block; position:relative;">
                Género
        <span v-if="p.sexo == ''" style="position:absolute; pointer-events: none; top:27px; left:12px; color:rgba(0,0,0,0.6);">Seleccione género</span>
        <select class="selector form-select" style="display:block; width:100%" v-model="p.sexo">
          <option value="f">Femenino</option>
          <option value="m">Masculino</option>
        </select>
       </div>
      </div>
      <div class="email" style=" padding:4px; width:33%; vertical-align:top; display:inline-block; font-size:13px; padding-right:7px;">Nacionalidad <input  v-model="p.nacionalidad" v-on:input="p.nacionalidad = $event.target.value" @focus="focused_nacionalidad = true;" @blur="focused_nacionalidad = false" placeholder="maxpe@gmail.com" class="form-control" type="text" name="" id=""></div>

      <div class="fechan" style="width:33%; display:inline-block; font-size:13px; padding:4px; vertical-align:top;">
        Fecha de nacimiento 
<div style="display:block;">
        <div style="display:inline-block; position:relative;">
        <span v-if="p.fecha_nacimiento_d == 0" style="position:absolute; pointer-events: none; top:8px; left:6px; color:rgba(0,0,0,0.6);">Día</span>
          <select @change="cambio_edad(p)" class="selector form-select" style="width:75px" v-model="p.fecha_nacimiento_d">
            <option v-for="x in getDaysInMonth(p.fecha_nacimiento_m, p.fecha_nacimiento_a)" :value="x">{{x}}</option>
        </select>
        </div> 

       <div style="display:inline-block; position:relative;">
        <span v-if="p.fecha_nacimiento_m == 0" style="position:absolute; pointer-events: none; top:8px; left:7px; color:rgba(0,0,0,0.6);">Mes</span>
        <select @change="cambio_edad(p)" class="selector form-select" v-model="p.fecha_nacimiento_m">
          <option v-for="x, y in meses" :value="y+1">{{x}}</option>
        </select>
       </div>


       <div style="display:inline-block; position:relative;">
        <div style=" z-index:2000;">
      <select @change="cambio_edad(p)"  ref="s_año" class="selector form-select" style="width:95px; background-color: transparent !important;" v-model="p.fecha_nacimiento_a">
        <option v-for="x in 120" :value="new Date().getFullYear()-x+1">{{new Date().getFullYear()-x+1}}</option>
      </select>
      </div>
      <span v-if="p.fecha_nacimiento_a == 0" style="position:absolute; pointer-events: none; top:8px; left:7px; color:rgba(0,0,0,0.6); z-index:1;">Año</span>
       </div>

      </div>
      </div>
      <div class="isapre" style="width:33%; display:inline-block; font-size:13px; padding:4px; height:90px; vertical-align:top;">
        Aseguradora 
        <select class="selector form-select" style="display:block; width:100%" v-model="p.aseguradora_id">
          <option :value="0">Seleccionar</option>
          <option v-for="a in aseguradoras" :value="a.id">{{a.nombre}}</option>
          
        </select>

      </div>


            <div v-if="prestador.atributos.config.agregar_mas_pacientes != 0"  v-on:click="removerPaciente(i);" :style="{'color': prestador.atributos.assets.colors.secondary, 'border-color': prestador.atributos.assets.colors.secondary }" class="btn-agregar-examen-inverso2" style="width:170px; margin-bottom:0px;" >
           <b-icon icon="trash"></b-icon> Quitar paciente
        </div>
  </div>
  


      </b-collapse>

  </div>

<!--   <div v-if="prestador.atributos.config.agregar_mas_pacientes != 0" :style="{'color': prestador.atributos.assets.colors.secondary, 'border-color': prestador.atributos.assets.colors.secondary }" v-on:click="agregarPaciente()" style="width:280px; vertyi" class="btn-agregar-examen-inverso" >
    <b-icon icon="person" style=" height:20px; width:20px; margin-right:5px; margin-left:5px; transform:translate(0px, 2px);"> </b-icon> <b-icon icon="plus" style="position:absolute;   transform:translate(-19px, -1px);"></b-icon> Agregar un nuevo paciente
  </div> -->
  </div>









<!--

      <div class="nombre" style="display:inline-block; font-size:13px; padding-right:7px;">Nombre <input @focus="focused_nombre = true;" @blur="focused_nombre = false" v-model="nombre" placeholder="Maximiliano" class="form-control" type="text" name="" id=""></div>
      <div class="apellido1" style="display:inline-block; font-size:13px; padding:4px;">Apellido <input @focus="focused_apellido1 = true;" @blur="focused_apellido1 = false" v-model="apellido1"  placeholder="Pérez" class="form-control" type="text" name="" id=""></div>

      <div class="rut" style="display:inline-block; font-size:13px; padding-right:7px;">Rut <input :class="{'is-invalid':error_crear_rut != '' && !focused_rut}" @focus="focused_rut = true;" @blur="focused_rut = false" placeholder="1234567-8" v-model="rut" class="form-control" type="text" name="" id=""><span v-if="error_crear_rut != '' && !focused_rut" style="color:red; position:absolute;">{{error_crear_rut}} </span></div>

      <h5 style="margin-top:30px;">Contacto</h5>

      <div class="email" style="width:33%; display:inline-block; font-size:13px; padding-right:7px;">Email <input :class="{'is-invalid':error_crear_correo_electronico != '' && !focused_email}" v-model="email" @focus="focused_email = true;" @blur="focused_email = false" placeholder="maxpe@gmail.com" class="form-control" type="text" name="" id=""><span v-if="error_crear_correo_electronico != '' && !focused_email" style="color:red; position:absolute;">{{error_crear_correo_electronico}} </span></div>
      <div class="telefono" style="width:33%; display:inline-block; font-size:13px; padding:4px;">Teléfono <input v-model="telefono" @focus="focused_telefono = true;" @blur="focused_telefono = false"  placeholder="68247212" class="form-control" type="text" name="" id=""></div>
--> 
        <div class="recuadro-direccion"  style="border-radius: 15px; background-color:white;  margin-top:20px;">
      <h5>Dirección</h5>
      <div class="dir1">
      <div style="width:250px; display:inline-block; position:relative; font-size:13px; padding-right:7px;">
        Calle y número 
        <input :placeholder="direccion == '' ?'Avenida ClinicGo 4421':''" name="randomWord" autocomplete="cc-csc" style="width:100%; border-radius:4px; background:none; font-size:16px;   outline:none; padding:6px 10px; border:1px solid rgba(0,0,0,0.20);"  v-on:input="direccion_ = $event.target.value; getDireccion();" v-model="direccion_" @focus="focused_direccion = true;" class="dont-close-direccion" type="text" >
        <div v-if="direccion != '' && !focused_direccion" style="max-width:208px; white-space:nowrap; overflow:hidden; text-overflow: ellipsis; position:absolute; pointer-events: none; font-size:16px; top:27px; left:11px;">
          {{direccion}} 
        </div>
        <b-icon v-if="direccion != '' && !focused_direccion" style="position:absolute; height:18px; width:18px; right:14px; top:30px; color:rgb(0, 0, 0);" icon="check-circle"></b-icon>
      <div class="dont-close-direccion" v-if="focused_direccion" style="position:absolute; top:52px; background:white; border:1px solid rgba(0,0,0,0.25);  width:243px; border-radius: 0px 0px 10px 10px;">
        <div class="dont-close-direccion" v-if="opciones_calles.length > 0 && /\d/.test(direccion_)">
        <div class="dont-close-direccion" style="width:100%; padding:8px; cursor:pointer;" 
        v-on:click="direccion = o.calle + ' '+o.numero; direccion_ = ''; focused_direccion = false; lat = o.lat; lon = o.lon;"  
        v-for="o in opciones_calles"> 
          <b-icon class="dont-close-direccion" style="color:rgb(0, 0, 0);" icon="geo-alt"></b-icon> 
          {{o.calle}} {{o.numero}}
          
          </div>
        </div>
        <div class="dont-close-direccion" v-else style="width:100%; padding:8px;">
          Por favor, escribe <strong>calle y número</strong>.

        </div>
      </div>
      </div>

      <div style="display:inline-block; padding-right:7px;">
        <div style="font-size:13px;">Comuna</div>
      <div style="display:inline-block; width:100%; padding:6px; border-radius:5px; border: 1px solid rgb(220,220, 220); "><span style="opacity:0.6;">{{comunas.filter(x=>{return x.id == comuna})[0].Nombre}}</span></div> 
      </div>
      </div>

            <div class="dir2" style="margin-top:20px;">
              <div style="font-size:13px;">Tipo</div>
              <button :style="{'color': depto == 1 ? prestador.atributos.assets.colors.primary: '#fff', 'border-color': prestador.atributos.assets.colors.primary, 'background-color': depto != 1 ? prestador.atributos.assets.colors.primary: '#fff',}" style="margin-right:10px; transition:0s;" v-on:click="depto = 0" :class="{'opcionClicked': depto==0, 'opcionNoClicked': depto==1}" class="btn">Casa</button>
              <button :style="{'color': depto == 0 ? prestador.atributos.assets.colors.primary: '#fff', 'border-color': prestador.atributos.assets.colors.primary, 'background-color': depto != 0 ? prestador.atributos.assets.colors.primary: '#fff',}" style="transition:0s;" v-on:click="depto = 1" :class="{'opcionClicked': depto==1, 'opcionNoClicked': depto==0}" class="btn">Departamento</button>
            </div>
            <div class="dir3" style="margin-top:20px; display:inline-block; width:250px;">
              <div style="max-width:250px;" v-if="depto == 1">
              <div style="font-size:13px;">Número de departamento</div>
              <input placeholder="A32" maxlength="8" v-model="n_depto" @focus="focused_n_depto = true;" @blur="focused_n_depto = false" class="form-control" type="text" name="" id="">
              </div>

            </div>
                          <div class="dir4" style="margin-top:20px;">
                            <div style="font-size:13px;">Información adicional (opcional)</div>
              <input placeholder="Condominio CG" maxlength="119" v-model="info_adicional"  class="form-control" type="text" name="" id="">
              </div>
            
        </div>
            




    </div>
  </div>
  <button v-on:click="checkout_datos_paciente()" :style="{'background-color': prestador.atributos.assets.colors.primary, 'opacity':this.puedeContinuarError == '' ? '1' : '0.5'}" class="btn-pagar"> Continuar</button>
  </div>

   </div>
   <div v-else-if="loading_transferencia && !orden_enviada">
    <Loader :height="100" :width="100" />

   </div>
   <div v-else-if="orden_enviada">
    <h2>¡La orden ha sido enviada con éxito!</h2>
    <button v-on:click="estado = -1; orden_enviada = false;" :style="{'background-color': prestador.atributos.assets.colors.primary }" class="btn-pagar"> Hacer otra</button>
  </div>
  </div>

  </div>


</transition>
<div v-if="showing" class="background-screen"></div>
<div v-if="subiendo_archivo"  style="height:100vh; width:100vw; position:fixed; top: 0; left: 0; background-color:rgba(0,0,0,0.4); z-index:999989">
    </div>
    <div v-if="subiendo_archivo"   class="subiendo_archivo ">
      <div class="spinner-border text-secondary" style="margin: 0 auto; " role="status"></div>
      <div  style=" margin-top: 10px; font-size: 16px;">
        
        Un momento por favor, estamos subiendo su órden médica...
      </div>
    </div>
</div>


</template>

<script>
/* eslint-disable */

import {mapGetters, mapActions} from 'vuex';
import Fuse from "fuse.js";
import axios from 'axios';
import { funcionesCalculoCosto } from '../../helpers/funciones.js'
import Loader from '@/components/Loader.vue';

export default {
  name: 'ModalAgendar',
  components: {
    Loader,
  },
  props: {

  },
  data(){
    return {

      default_id:0,

      estado:-1,


      examenes_agregados:[{nombre:'', tipo_identificador:'rut', nacionalidad:'Chilena', sexo:'',  fecha_nacimiento_a: 0, fecha_nacimiento_m: 0, fecha_nacimiento_d: 0, aseguradora_id:1, apellido1:'', email:'', rut:'', telefono:'', tel_prefijo:'+56', seleccionando_telefono:false, seleccionando_rut:false, orden_medica:null, examenes:[]}],
      
      /* Primer estado */
      resultado_fuse_comuna: [],
      fComunas: null,
      comuna_buscado:'',
      comuna:0,

      /* Segundo estado */

      valores_codigo:[],

      asegurador_default:1,
      agregando_examen_paciente:-1,
      buscando_examen:'',
      buscando_examen_principal:'',

      resultado_fuse_examen: [],
      fExamen: null,

      /* Tercer estado */
      loading:true,
      datos:[],
      alerta:false,
      horasDisponibles:[],
      descripcion:'',
      nombre:'',

      mostrar_mas:false,

      horasDisponiblesC:[],
      combinaciones:[],

      tiSelected:-1,
      tfSelected:-1,
      categorias_selected_c:[],
      
      viendoMes:0,
      viendoAño:0,

      selectedDia:-1,
      selectedMes:-1,
      selectedAño:-1,
      selected_prioridad:-1,
      selected_recargo_porcentaje:0,
      selected_recargo_suma:0,

      buscadoDia:0,
      buscadoMes:0,
      buscadoAño:0,

      hoyMes:0,
      hoyDia:0,
      hoyAño:0,
      modalState:0,

      hoy:0,

      mapping:[6, 0, 1, 2, 3, 4, 5],

      meses:['Enero', 'Febrero', 'Marzo', 'Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
      dias:['Lunes','Martes','Miércoles','Jueves','Viernes', 'Sabado', 'Domingo'],
      dias_av:['Lu','Ma','Mi','Ju','Vi', 'Sa', 'Do'],

      cargando_disponibles:false,
      cargando_combinaciones:false,

      buscadoHoras:false,
      disponibilidad_diaria:{},

      /* cuarto estado */

      meses:['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],

      
direccion:'',
apellido1:'',
apellido2:'',
nombre:'',
depto:1,
rut:'',
email:'',
telefono:'',
n_depto:'',
info_adicional:'',
alerta:false,
fexamenes:null,

agregando_examen:false,

visible:[true, false, false, false],
focused_direccion:false,
focused_apellido1:false,
focused_apellido2:false,
focused_nombre:false,
focused_rut:false,
focused_email:false,
focused_telefono:false,
focused_n_depto:false,
focused_info_adicional:false,
focused_nacionalidad:false,

pacientes:[],
resultado_fuse_examen:[],
examen_buscado:'',
tipo_identificador:[
  {nombre:'Rut', valor:'rut', desc:'(RUT nacional)'},
  {nombre:'Pasaporte', valor:'pasaporte', desc:'(Pasaporte extranjero)'},
  {nombre:'DNI', valor:'dni', desc:'(DNI extranjero)'},
  ],

banderas:[
  {arch:'cl.svg', num:'+56', nom:'Chile'},
  {arch:'ar.svg', num:'+54', nom:'Argentina'},
  {arch:'br.svg', num:'+55', nom:'Brasil'},
  {arch:'us.svg', num:'+1', nom:'Estados Unidos'},
  {arch:'co.svg', num:'+57', nom:'Colombia'},        
  {arch:'mx.svg', num:'+52', nom:'Mexico'},
  {arch:'pe.svg', num:'+51', nom:'Peru'},
  {arch:'uy.svg', num:'+598', nom:'Uruguay'},
  {arch:'bo.svg', num:'+591', nom:'Bolivia'},
],


opciones_calles:[],
editando_paciente:0,
direccion_:'',
lat:0,
lon:0,
info_adicional:'',

np:1,
/* Enviando... */
loading_transferencia:false,
orden_enviada:false,
es_principal:false,


      showing:false,
      estados : ['Anulado', 'Citado',  'Confirmado', 'Atendido', 'No Atendido'],

      prestadores:[],
      selectedPrestador:1,
      loading_prestador:false,
      subiendo_archivo:false,
    }
  },
  methods:{
    ...mapActions(['reconfigure']),
   ...funcionesCalculoCosto,
   calculateAge(yearOfBirth, monthOfBirth, dayOfBirth){
            const today = new Date();
        const birthDate = new Date(yearOfBirth, monthOfBirth - 1, dayOfBirth);

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        return age;
    },
    autocompleteClient(paciente) {
      // Make sure the paciente object has a rut
      if (!paciente.rut) {

        return;
      }

      // Define the API endpoint with the rut query parameter
      const endpoint = `api/admin/autocomplete_cliente?rut=${paciente.rut}`;

      // Make an HTTP GET request to the endpoint using axios
      axios.get(window.hostname+endpoint)
        .then(response => {
          // Check if the response is successful
          if (response.status === 200) {
            const clientData = response.data;

            // Fill the paciente object with the returned data
            paciente.nombre = clientData.nombre;
            paciente.apellido1 = clientData.apellido1;
            paciente.apellido2 = clientData.apellido2;
            paciente.telefono = clientData.telefono;
            paciente.email = clientData.email;
            this.direccion = clientData.direccion_ultima_orden;  // Fill the address
            this.departamento = clientData.departamento_ultima_orden;
            this.n_depto = clientData.n_depto_ultima_orden;
            if (clientData.fecha_nacimiento) {
              const [year, month, day] = clientData.fecha_nacimiento.split('T')[0].split('-');
              paciente.fecha_nacimiento_a = parseInt(year, 10);  // Convert to integer
              paciente.fecha_nacimiento_m = parseInt(month, 10); // Convert to integer
              paciente.fecha_nacimiento_d = parseInt(day, 10);   // Convert to integer
            }
            paciente.aseguradora_id = clientData.aseguradora_id;
           
            paciente.sexo = clientData.sexo_biologico;

            // Display success notification
      
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            // Client not found or no orders with this prestador
    
          } else {
            // General error handling
     
          }
        });
    },
   removerExamen(paciente, examen){
    console.log(this.examenes_agregados[paciente])
    this.examenes_agregados[paciente].examenes.splice(examen, 1)
   },
   async onSelectPrestadorChange(){
    this.loading_prestador = true;
    await this.reconfigure({prestador_id:this.selectedPrestador})
    this.examenes_agregados = [{nombre:'', tipo_identificador:'rut', nacionalidad:'Chilena', sexo:'',  fecha_nacimiento_a: 0, fecha_nacimiento_m: 0, fecha_nacimiento_d: 0, aseguradora_id:1, apellido1:'', email:'', rut:'', telefono:'', tel_prefijo:'+56', seleccionando_telefono:false, seleccionando_rut:false, orden_medica:null, examenes:[this.examenes[0].id]}]
    this.fExamen = new Fuse(this.examenes, { keys: ["nombre"] });
    this.loading_prestador = false;
   },
   transferencia() {
  
        for(var f = 0; f < this.examenes_agregados.length; f++) {
          this.examenes_agregados[f].asegurador = this.examenes_agregados[f].aseguradora_id
        }
        const json_ob = JSON.stringify({
          examenes:this.examenes_agregados,

          dia:this.selectedDia,
          mes:this.selectedMes,
          año:this.selectedAño,

          comuna_id:this.comuna,
          desde:this.tiSelected,
          hasta:this.tfSelected,
          direccion:this.direccion,
          departamento:this.depto,
          n_depto:this.n_depto,
          orden:this.examenes_agregados,
          recargo_porcentaje:this.selected_recargo_porcentaje,
          recargo_suma:this.selected_recargo_suma,
          prioridad:this.selected_prioridad,
          codigo:this.codigo,
          lat:this.lat,
          lon:this.lon,
          info_ad: this.info_adicional,
          prestador_id:this.prestador.id != 1 ? this.prestador.id : this.selectedPrestador,
          config_id:this.config_id,
        })
        const blob = new Blob([json_ob], {type:'application/json'});
        let data = new FormData();
        data.append("document", blob)
/*         for(var indice_paciente = 0; indice_paciente < this.examenes_agregados.length; indice_paciente++) {
          if(this.examenes_agregados[indice_paciente].orden_medica != null) {
            for(var indice_orden = 0; indice_orden < this.examenes_agregados[indice_paciente].orden_medica.length; indice_orden++)
            {
              data.append(indice_paciente+'_'+indice_orden, this.examenes_agregados[indice_paciente].orden_medica[indice_orden])
            }
          }
        } */
        this.loading_transferencia = true;
        axios({method:'post', url:window.hostname+'api/prueba', data:data})
        .then( (r) => {
          this.loading_transferencia = false;
          this.orden_enviada = true;
        })
        .catch( (e) => {
          this.loading_transferencia = false;
          this.error_hora_sin_horario = true;
          this.$notify({
      group: 'foo',
      title: '¡Error!',
      text: 'Ha habido un error con la orden.',
      type:'error'
      });
        })
        
      

    },
   getDireccion(){
      if (/\d/.test(this.direccion_))
      axios.post(window.hostname+'api/get_direccion',
        {comuna:this.comunas.filter(x=>{return x.id == this.comuna})[0].Nombre,
         calle:this.direccion_
        })
        .then(r=>{
          this.opciones_calles = r.data.opciones;
        })
        .catch(e=>{
          console.log(e)  
        })

    },
    close(e){
      if (!(e.target.classList.contains('search-exam-popup') || e.target.classList.contains('dont-close-exam') ) && this.focused_exam)
      {
        this.focused_exam = false;
        this.examen_buscado = ''
      }
      if (!(e.target.classList.contains('dont-close-direccion')) && this.focused_direccion) 
      {

          if (this.opciones_calles.length > 0)
          {
            this.direccion = this.opciones_calles[0].calle + ' '+this.opciones_calles[0].numero
            this.lat = this.opciones_calles[0].lat;
            this.lon = this.opciones_calles[0].lon;
          }
          else
          {
            this.direccion = this.direccion_;
          }
        
        this.focused_direccion = false;
        this.direccion_ = ''
      }
      if (!(e.target.classList.contains('num_prefico')))
      {
        for(var j = 0; j < this.pacientes.length; j++){
          this.pacientes[j].seleccionando_telefono = false;
        }
      }
      if (!(e.target.classList.contains('ident_prefico')))
      {
        for(var j = 0; j < this.pacientes.length; j++){
          this.pacientes[j].seleccionando_rut = false;
        }
      }
    },
   verificar_correo(c){
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(c != '')
        return re.test(c) ? true: false;
      return true;
    },
    getFlagUrl(pic) {
    return require('../../assets/banderas/'+pic)
    },
    getDaysInMonth(month,year) {
      return new Date(year, month, 0).getDate();
     },
   verificar_rut(r)
    {
      function dv(T){
        var M=0,S=1;
        for(;T;T=Math.floor(T/10))
          S=(S+T%10*(9-M++%6))%11;
        return S?S-1:'k';
      }
    function validaRut (rutCompleto) {
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
          return false;
        var tmp 	= rutCompleto.split('-');
        var digv	= tmp[1]; 
        var rut 	= tmp[0];
        if ( digv == 'K' ) digv = 'k' ;
          return (dv(rut) == digv ) ? true : false;
      }
      return validaRut(r)
    },
   removerOrdenMedica(paciente, archivo){
    if(this.examenes_agregados[paciente].orden_medica == undefined || !Array.isArray(this.examenes_agregados[paciente].orden_medica))
            return

        if(this.examenes_agregados[paciente].orden_medica[paciente] == undefined)
            return
        this.examenes_agregados[paciente].orden_medica.splice(archivo, 1);

   },

   change_file(i){
    //console.log(this.$refs['file'+i][0].files[0].name, "ASDASD")
    //console.log(this.$refs)
    console.log(this.$refs['file'+i][0].files[0])
    if (['jpg', 'png', 'jpeg', 'pdf'].includes(this.$refs['file'+i][0].files[0].name.split('.').pop()))
      {
        let archivo = this.$refs['file'+i][0].files[0];

        if(this.examenes_agregados[i].orden_medica == undefined || !Array.isArray(this.examenes_agregados[i].orden_medica))
        this.examenes_agregados[i].orden_medica = [archivo];
        else
        this.examenes_agregados[i].orden_medica.push(archivo)

/*         this.agregarOrdenMedica({archivo:this.$refs['file'+i][0].files[0], indice:i}) */
      }
    else
    {
      this.error_archivo = true;
    }
/*
    for(var j = 0; i < this.orden_final.length; i++){
      for(var k = 0; k < this.orden_final[i].orden_medica.length; k++){
        console.log(j, k, this.orden_final[i].orden_medica[k])
      }
    }
    */
  },
  async change_file(i){
    //console.log(this.$refs['file'+i][0].files[0].name, "ASDASD")
    //console.log(this.$refs)

    const file = this.$refs['file'+i][0].files[0];
    
    if (['jpg', 'png', 'jpeg', 'pdf'].includes(file.name.split('.').pop()))
      {

        try {
          this.subiendo_archivo = true;
          const formData = new FormData();
          formData.append('file', file);
          const response = await axios.post(window.hostname+'api/preupload_recetas', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          this.subiendo_archivo = false;
          const token = response.data.token;
          //this.savePreuploadOrdenMedica({token:token, nombre:file.name, indice:i})
          let obj = {token:token, nombre:file.name}
          if(this.examenes_agregados[i].orden_medica == undefined || !Array.isArray(this.examenes_agregados[i].orden_medica))
          {
            this.examenes_agregados[i].orden_medica = [obj];
          }
          else
          {
            this.examenes_agregados[i].orden_medica.push(obj)
          }

          this.$gtag.event('ordenmedica_subir_archivo', 
        {'event_category': this.prestador.nombre, 
        'event_label': 'correcto', 
        'value': 1} );
      } catch (error) {
        this.subiendo_archivo = false;
        this.error_subiendo_archivo = true;
        console.error('File upload failed:', error);
        if(error.response){
          console.log(error.response)
        }
      }

      this.subiendo_archivo = false;

      }
    else
    {
      this.$gtag.event('ordenmedica_subir_archivo', 
        {'event_category': this.prestador.nombre, 
        'event_label': 'incorrecto', 
        'value': 1} );
      this.error_archivo = true;
    }
/*
    for(var j = 0; i < this.orden_final.length; i++){
      for(var k = 0; k < this.orden_final[i].orden_medica.length; k++){
        console.log(j, k, this.orden_final[i].orden_medica[k])
      }
    }
    */
  },
   pacienteRequiereReceta(paciente){
      let requiere = false;
      console.log("partiendo")
      if(paciente.examenes.length == 0 && this.prestador.atributos.config.orden_medica_obligatoria == 1)
        { 
          console.log("t1")
        return true;
        } 
        for(var examen of paciente.examenes){
        if(this.examenes.find(z=>{ return z.id == examen}).requiere_orden == 1)
        {
          requiere = true;
          break;
        }
      }
      console.log("t2", requiere)
      return requiere;
    },

   sumarMes(){
      if (this.viendoMes == 11)
      {
        this.viendoMes = 0;
        this.viendoAño += 1
      }
      else{
        this.viendoMes += 1
      }
      this.getDisponiblesMes(this.viendoMes, this.viendoAño)
    },
      restarMes(){
      if (this.viendoMes == 0)
      {
        this.viendoMes = 11;
        this.viendoAño -= 1
      }
      else{
        this.viendoMes -= 1
      }
      this.getDisponiblesMes(this.viendoMes, this.viendoAño)

    },
   click_servicio(serv, pos){
      
    this.examenes_agregados[pos].examenes.push(serv.id)

        

    },
    checkout(){

      if (this.examenes_agregados.length > 0)
      {
        this.estado = 2;
      }
      this.disponibilidad_diaria = {}
      this.horasDisponiblesC = [];
      this.selectedAño = -1;
      this.selectedDia = -1;
      this.selectedMes = -1;
      this.getDisponiblesMes(this.hoyMes, this.hoyAño)
    },
    checkout_fecha_y_hora(){
      if (this.selectedDia != -1 && this.selectedMes != -1 && this.selectedAño != -1 && this.tiSelected != -1 && this.tfSelected != -1 && this.selected_prioridad != -1 && this.selected_recargo_porcentaje != -1 && this.selected_recargo_suma != -1)
      {      
        this.estado = 3;
      }
    },
    checkout_datos_paciente(){
      if (this.puedeContinuarError != '')
      {
        this.$notify({
        group: 'foo',
        title: '¡Error!',
        text: this.puedeContinuarError,
        type:'error'
        });  
        return;
      }
      if (this.selectedDia != -1 && this.selectedMes != -1 && this.selectedAño != -1 && this.tiSelected != -1 && this.tfSelected != -1 && this.selected_prioridad != -1 && this.selected_recargo_porcentaje != -1 && this.selected_recargo_suma != -1)
      {      
        this.estado = 4;
      }
    },
   _blur(){
      setTimeout(()=>{this.agregando_examen_paciente = -1}, 200);
    },
   agregarPaciente(){
    this.examenes_agregados.push({nombre:'', tipo_identificador:'rut', nacionalidad:'Chilena', sexo:'',  fecha_nacimiento_a: 0, fecha_nacimiento_m: 0, fecha_nacimiento_d: 0, aseguradora_id:1, apellido1:'', email:'', rut:'', telefono:'', tel_prefijo:'+56', seleccionando_telefono:false, seleccionando_rut:false, orden_medica:null, examenes:[]})
   },
   removerPaciente(pos){
    if (this.examenes_agregados.length != 1)
      this.examenes_agregados.splice(pos, 1)
    else
    this.$notify({
      group: 'foo',
      title: '¡Error!',
      text: 'No puedes eliminar a todos los pacientes',
      type:'error'
      });
   },
   getDisponiblesMes(m, y){
      if(this.estado != 2)
         return;
      const nextMonth = new Date(y, m + 1, 1);
      const lastDayOfMonth = new Date(nextMonth.getTime() - 1);
      if(this.disponibilidad_diaria.hasOwnProperty(lastDayOfMonth.getDate()+'-'+(m+1)+'-'+y))
      {
        return;
      }
      let exs_ = this.examenes_agregados.length > 0 ? (this.examenes_agregados.length > 1 ? this.examenes_agregados.reduce((x, y)=>{  return x.concat(y.examenes)}, []) : this.examenes_agregados[0].examenes).map(j=>{return {id:j}})
        : this.categorias_selected.map(x=>{ return {id: this.examenes.find(y=>{return y.categoria_id == x.id}).id}})
      console.log(exs_)
      axios.post(window.hostname+'api/getDisponiblesMes',
        {
          prestador_id:this.prestador.id != 1 ? this.prestador.id : this.selectedPrestador,
          
        examenes: exs_ , 
  
        mes:m,
        año:y,
        comuna_id:this.comuna,
        
        })
        .then(resp => 
        {

          
          this.disponibilidad_diaria = Object.assign({}, resp.data.horarios_posibles, this.disponibilidad_diaria)

        })
        .catch(e=>{          
         console.log(e)
        })

    },
    verDisponibilidadSiPuede(){
        this.cargando_disponibles = true;
        console.log(this.selectedDia,
this.selectedMes,
this.selectedAño,)
        axios.post(window.hostname+'api/getDisponibles',
        {
          prestador_id:this.prestador.id != 1 ? this.prestador.id : this.selectedPrestador,
          
        examenes: this.examenes_agregados.length > 0 ? (this.examenes_agregados.length > 1 ? this.examenes_agregados.reduce((x, y)=>{  return x.concat(y.examenes)}, []) : this.examenes_agregados[0].examenes).map(j=>{return {id:j}})
        : this.categorias_selected.map(x=>{ return {id: this.examenes.find(y=>{return y.categoria_id == x.id}).id}}) , 
        dia:this.selectedDia,
        mes:this.selectedMes,
        año:this.selectedAño,
        comuna_id:this.comuna,
        
        })
        .then(resp => 
        {
          this.horasDisponibles = resp.data.horarios_posibles;
          this.cargando_disponibles = false;
          this.buscadoDia = this.selectedDia;
          this.buscadoMes = this.selectedMes;
          this.buscadoAño = this.selectedAño;
          //window.scrollTo(0,document.body.scrollHeight);

        })
        .catch(e=>{          
          this.cargando_disponibles = false;
          console.log(e)
        })

    },
   timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    seleccionarDia(d,m,a){
      let dia = new Date(a, m, d)
      let hoyDia = new Date(this.hoy.getFullYear(), this.hoy.getMonth(), this.hoy.getDate())
      if(dia.getTime() >= hoyDia.getTime())
      {
        this.selectedDia = d
        this.selectedMes = m
        this.selectedAño = a
      }
      else
      {

        //this.$notify({ title: 'Error', text: 'Este día ya pasó!.', type: 'error'})
      }
      

    },
   UltimoDia(y, m){
      return new Date(y, m+1, 0).getDate()
    },

   clickearComuna(comuna){
      if(this.comunas_usadas.some(x=>{return x.id == comuna.id})){
         this.comuna = comuna.id
         this.estado = 1;
      }
      else{
         this.$notify({
      group: 'foo',
      title: '¡Error!',
      text: 'Comuna inhabilitada',
      type:'error'
      });
      }
      console.log(comuna)
    },

   send_to(route){      
      document.body.style.position = '';
      document.body.style.top = '';
      this.$router.push({path:route})
      this.hide();
    },
    show(){
      this.showing = true;
      this.orden_enviada = false;
      /*document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.top = `-${window.scrollY}px`;
      */
    },
    hide(){
      this.showing = false;
      if(this.prestador.id != this.selectedPrestador){
        this.reconfigure({prestador_id:this.prestador.id})
      }
     /* document.body.style.position = '';
      document.body.style.top = '';
      */
    },
    get_prestadores(){
      

      axios.get(window.hostname+'/api/admin/get_prestadores')
      .then(r=>{

        this.prestadores = r.data.prestadores
        console.log("JJOJOJO")
        console.log(this.prestadores)


      })
      .catch(r=>{

        
        })
      
    },


  },
  beforeDestroy() {
    if(this.prestador.id != this.selectedPrestador){
        this.reconfigure({prestador_id:this.prestador.id})
      }
      document.removeEventListener('click',this.close)
  },
  mounted(){

    if(this.prestador.id == 1){
      this.get_prestadores();
      this.es_principal = true;
    }
    document.addEventListener('click', this.close) 

    this.examenes_agregados = [{nombre:'', tipo_identificador:'rut', nacionalidad:'Chilena', sexo:'',  fecha_nacimiento_a: 0, fecha_nacimiento_m: 0, fecha_nacimiento_d: 0, aseguradora_id:1, apellido1:'', email:'', rut:'', telefono:'', tel_prefijo:'+56', seleccionando_telefono:false, seleccionando_rut:false, orden_medica:null, examenes:[]}]
      
   this.fComunas = new Fuse(this.comunas, { keys: ["Nombre"] });
   this.fExamen = new Fuse(this.examenes, { keys: ["nombre"] });

   this.hoy = new Date();
    this.hoyMes = this.hoy.getMonth();
    this.hoyDia = this.hoy.getDate();
    this.hoyAño = this.hoy.getFullYear();
    this.viendoDia = this.hoyDia;
    this.viendoMes = this.hoyMes;
    this.viendoAño = this.hoyAño;
    this.getDisponiblesMes(this.hoyMes, this.hoyAño)
  
  },
  computed:{
   ...mapGetters([
    'prestador',
    'comunas',
    'comunas_usadas',
    'aseguradoras',
    'categorias',
    'costo_servicio_pendiente',
    'examenes',
    'prestador_id',
    'categorias_selected',
    'prestador',
    'codigo',
/*       'valores_codigo', */
    ]),
    puedeContinuarError(){
      for (var i = 0; i<this.examenes_agregados.length; i++){
          
          if(!(

          this.examenes_agregados[i].nombre.length > 1 &&
          
          
          this.examenes_agregados[i].email.length > 2 && 
          this.examenes_agregados[i].apellido1.length > 1 &&
          this.examenes_agregados[i].telefono.length > 6 &&
          this.examenes_agregados[i].nacionalidad.length > 1 &&
          
          this.verificar_correo(this.examenes_agregados[i].email) 
          
          
          ))
          {
            return 'Hay pacientes con información incompleta. (' + (this.examenes_agregados[i].nombre.length > 1 ? this.examenes_agregados[i].nombre : 'Paciente '+(i+1) )+').'
          }
          if (!['f', 'm'].includes(this.examenes_agregados[i].sexo))
            return '' + (this.examenes_agregados[i].nombre.length > 1 ? this.examenes_agregados[i].nombre : 'Paciente '+(i+1) )+' no tiene sexo seleccionado.'
          if (this.examenes_agregados[i].fecha_nacimiento_a == 0 || this.examenes_agregados[i].fecha_nacimiento_d == 0 || this.examenes_agregados[i].fecha_nacimiento_m == 0)
            return '' + (this.examenes_agregados[i].nombre.length > 1 ? this.examenes_agregados[i].nombre : 'Paciente '+(i+1) )+' no tiene fecha de nacimiento seleccionada.'
          if (this.examenes_agregados[i].examenes.length == 0 && this.examenes_agregados.length != 0)
            return 'Todos los pacientes deben tener al menos un examen. (' + (this.examenes_agregados[i].nombre.length > 1 ? this.examenes_agregados[i].nombre : 'Paciente '+(i+1) )+' no tiene).'
          if (!this.aseguradoras.some(z=> {return z.id == this.examenes_agregados[i].aseguradora_id}))
           return '' + (this.examenes_agregados[i].nombre.length > 1 ? this.examenes_agregados[i].nombre : 'Paciente '+(i+1) )+' no tiene aseguradora seleccionada.'
          if(!(this.examenes_agregados[i].rut.length>4))
           return '' + (this.examenes_agregados[i].nombre.length > 1 ? this.examenes_agregados[i].nombre : 'Paciente '+(i+1) )+' debe ingresar rut o pasaporte (5 o mas caracteresa).'  
          if(!(this.verificar_rut(this.examenes_agregados[i].rut) || this.examenes_agregados[i].tipo_identificador != 'rut'))
           return '' + (this.examenes_agregados[i].nombre.length > 1 ? this.examenes_agregados[i].nombre : 'Paciente '+(i+1) )+' tiene que tener un rut o pasaporte valido.'
          if(this.prestador.atributos.config.requiere_verificar_ruts == 1){
            if(this.examenes_agregados[i].cargando_rut == true)
              return 'Se deben verificar todos los pacientes.';
            if(this.examenes_agregados[i].habilitado != 1)
            return 'Hay ruts que no son admisibles.';
          }
          if(this.prestador.atributos.config.edad_minima != undefined){
            if(this.calculateAge(this.examenes_agregados[i].fecha_nacimiento_a, this.examenes_agregados[i].fecha_nacimiento_m, this.examenes_agregados[i].fecha_nacimiento_d) < this.prestador.atributos.config.edad_minima)
              return 'Todos los pacientes deben tener '+this.prestador.atributos.config.edad_minima+' años o más';
          }

          
        
      }
      if (this.direccion.length < 3)
        return 'Falta agregar dirección.'
    /*  if (this.examenes_agregados.length == 0)
        return 'Debes agregar al menos un examen.'
*/

      return ''
    },
    mostrar_mañana(){
      if(this.prestador.atributos.config.mostrar_primero_horas_hasta != undefined){
        if(this.mostrar_mas){
          return this.horasDisponiblesC.some(x=>{ return x.hasta < 720});
        }
        return this.horasDisponiblesC.some(x=>{ return x.hasta < 720 && x.hasta < this.prestador.atributos.config.mostrar_primero_horas_hasta});

      }
      return this.horasDisponiblesC.some(x=>{ return x.hasta < 720 && x.hasta});
    },
    mostrar_tarde(){
      if(this.prestador.atributos.config.mostrar_primero_horas_hasta != undefined){
        if(this.mostrar_mas){
          return this.horasDisponiblesC.some(x=>{ return x.desde >= 720});
        }
        return this.horasDisponiblesC.some(x=>{ return x.desde >= 720 && x.hasta < this.prestador.atributos.config.mostrar_primero_horas_hasta});

      }
      return this.horasDisponiblesC.some(x=>{ return x.desde >= 720});
    },

    nombres(){
      let ret = []
      for(var i = 0; i<this.horasDisponiblesC.length; i++)
      {
        if(!ret.some(x=>{ return x.id == this.horasDisponiblesC[i].nombre+this.horasDisponiblesC[i].recargo_suma+this.horasDisponiblesC[i].recargo_porcentaje}))
          ret.push({id: this.horasDisponiblesC[i].nombre+this.horasDisponiblesC[i].recargo_suma+this.horasDisponiblesC[i].recargo_porcentaje, nombre:this.horasDisponiblesC[i].nombre, recargo_suma: this.horasDisponiblesC[i].recargo_suma, recargo_porcentaje:this.horasDisponiblesC[i].recargo_porcentaje})
      }
      return ret
    },


  },
  watch:{
   comuna_buscado(val) {
      this.resultado_fuse_comuna = this.fComunas.search(val);
      //if(!this.focused_comuna)
      //this.focused_comuna = true;
    },
    buscando_examen(val) {
      this.resultado_fuse_examen = this.fExamen.search(val);
      //if(!this.focused_comuna)
      //this.focused_comuna = true;
    },
    horasDisponibles : function(){
      var ret = []

      for (var i  = 0; i < this.horasDisponibles.length; i++)
      {
        ret.push({
        'desde': this.horasDisponibles[i].desde,
        'hasta': this.horasDisponibles[i].hasta, 
        'dia': this.horasDisponibles[i].dia, 
        'mes': this.horasDisponibles[i].mes, 
        'año': this.horasDisponibles[i].año,
        'categorias': this.horasDisponibles[i].categorias,
        'prioridad': this.horasDisponibles[i].prioridad,
        'recargo_porcentaje': this.horasDisponibles[i].recargo_porcentaje,
        'recargo_suma': this.horasDisponibles[i].recargo_suma,
        'color':this.horasDisponibles[i].color,
        'nombre':this.horasDisponibles[i].nombre,
        'hovering':false,
        'seleccionado':false})
      }
      this.horasDisponiblesC = ret

    },
  },
}
</script>


<style scoped>
.subiendo_archivo{
  position:fixed; 
  z-index:999999; 
  top:50%; 
  transform: translate(-50%, -50%); 
  left:50%; 
  max-width:320px; 
  padding:20px; 
  background-color:white; 
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
  border-radius:15px;
}
.btn-volv{
  position: absolute; top:20px; left: 20px;
  padding: 5px 20px;
  padding-left: 10px;
  color:white;
  border-radius: 4px;
  cursor: pointer;
}
.btn-volv:hover{
  opacity: 0.8;
}
.button-modal-m{
   padding:10px 40px;
   border: 2px solid rgba(0, 0, 0, 1);
   color: rgba(0, 0, 0, 1);
   font-weight: 600;
   display: inline-block;
   width: 300px;
   margin:10px;
   border-radius: 3px;
   cursor: pointer;
}
.button-modal-m:hover{
   background-color: rgba(0, 0, 0, 1);
   transition: 0.3s;
   color:white;
   box-shadow: rgba(0, 0, 0, 0.45) 0px 2px 10px 3px;
}
.background-screen{
  height: 100vh;
  width: 100vw;
  position: fixed;
 
/*   backdrop-filter: blur(2px); */
  z-index: 9999;

}
.titulo1{
  margin-bottom:30px;
  color: rgba(0, 0, 0, 1) !important;
}
.texto{
  text-align: center;
  padding: 0px 10px;
}
.main-m{

   flex-wrap: wrap;
  width: 100%;
  text-align: center;
  padding: 10px;
  gap:10px;
}
.filtro{
   width: Calc(50% - 10px);
   background-color: rgb(250, 250, 250);
   border-radius: 15px;
}
.greeny{
  color:#FF8C00;
  cursor:pointer;
  text-decoration: underline;
}
.subtitulo2{
  color:#FF8C00;
}
#modal-receta-m{
   transition: 0.5s;
   position:fixed;

   min-height: 400px;
   max-height: 100vh;


   overflow-y:scroll; 
   border-radius:10px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:10px; 
   background:white; 
   top: 50%;
   left:0px;
    transform: translateY(-50%);
  
   width:800px;
   max-width:90%;
   margin: 0px auto; 
   right: 0;
   opacity:1;
   z-index:99999;
}
.flaco{
   width:600px !important;
   left: 10% !important;
   transform: translate(-10%, -50%) !important;
}
#modal-receta-m h2{
     text-align: center;
     font-weight: bold;
     color:rgba(0,0,0,0.6);
     margin-top:20px;
}
#modal-receta-m h4{

  color:rgb(0, 0, 0) !important;
  margin-bottom:15px;
  margin-top:15px;
  font-size: 20px;
  font-weight: 700;
}
#modal-receta-m h5{
  text-align: center;
  vertical-align: middle;
  color:rgba(0, 0, 0, 0.3) !important;
 
}
.cont{
  text-align: center;
  vertical-align: middle;
  position:absolute;
   width:Calc(100% - 40px);
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
#modal-receta-m .icon{
   height:40px;
   width:40px;
   color:white;
}
#modal-receta-m .contenedor-icono{
   cursor:pointer;
   position:absolute;
   right:20px;
   top:20px;
   height:40px;
   width:40px;
   background-color: rgba(0, 0, 0, 0.6);
   border-radius:50px;
   z-index: 100001;
}
#modal-receta-m .subtitulo{
   text-align: center;
   font-size:22px;
   margin-bottom:40px;
   margin-top:20px;
   color:rgb(181, 181, 181);
   font-weight:350;
}
#modal-receta-m .input-wrapper{
   padding:0px 5px;
}
#modal-receta-m .btn-examinar{
   background-color:#FF8C00;
   padding:8px 20px;
   font-size:14px;
   font-weight:600;
   width:200px;
   max-width:Calc(100vw - 80px);
   color:white !important;
   outline: none;
   margin-top:20px;
}
#modal-receta-m .draggable-container{
   padding: 15px 5px 20px 5px;
   max-width: 600px;
   margin: 0 auto;
   background-color: rgb(247, 247, 247);
   border-style:dashed;
   border: 2px dashed rgb(200, 200, 200);
   border-radius:5px;
}
#modal-receta-m .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white;
   z-index: 999;
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 10px); 
   border: 1px solid rgb(200, 200, 200);
}
#modal-receta-m .placeholder-c{
   position:absolute; top:33px; left:20px;
}
#modal-receta-m .receta-label-mobile{
   display: none;
}
#modal-receta-m .btn-enviar{
   margin:0 auto; 
   cursor:pointer; 
   background-color:rgba(255, 179, 25, 1);
   max-width:300px; 
   height:45px; 
   color:white; 
   font-size:18px; 
   border-radius:4px;
   vertical-align: middle;
   font-weight:500;
}
#modal-receta-m .btn-enviar-d{
   margin:0 auto; 
   cursor:pointer; 
   background-color:rgba(255, 179, 25, 0.4);
   max-width:300px; 
   height:45px; 
   color:white; 
   font-size:18px; 
   border-radius:4px;
   vertical-align: middle;
   font-weight:500;
}
#modal-receta-m .upload-img{
   height:70px;
   width:70px;
   margin:0 auto; 
   margin-bottom:40px;
   margin-top:30px;
}
#modal-receta-m .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:50%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding-right:7px;
}
#modal-receta-m .comuna{
   position:relative;
}
#modal-receta-m .input-container{
   max-width:610px;
   margin: 0 auto;
}
@media only screen and (max-width: 1000px) {
   #modal-receta-m .btn-enviar{
   margin:0 auto; 
   margin-top:30px; 
   cursor:pointer; 
   background-color:rgba(103,30,117,1);
   max-width:100%;
   margin: 20px 20px; 
   height:55px; 
   line-height:55px;
   color:white; 
   font-size:18px; 
   border-radius:4px;
   font-weight:500;
}
#modal-receta-m .btn-examinar{
   padding:10px 20px;
   font-size:18px;
   font-weight:600;
   width:300px;
   max-width:Calc(100vw - 80px);
   color:white !important;
}
#modal-receta-m .receta-label-mobile{
   display: inline-block;
}
#modal-receta-m .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:100%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding:5px 20px;
 color:grey;
 
}
#modal-receta-m .placeholder-c{
   position:absolute; top:33px; left:30px;
}
#modal-receta-m .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white; 
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 40px); 
}
#modal-receta-m .receta-label-desk{
   display: none;
}
#modal-receta-m .draggable-container{
   border:none;
   background: none;
}
#modal-receta-m .contenido{
   z-index: 999999;
   position:relative;
      overflow-y:scroll !important;
   min-height:Calc(100vh);
   -webkit-overflow-scrolling: touch;
}
#modal-receta-m{
   
   position:fixed !important;
   height:Calc(100vh) !important;
   border-radius:0px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:0px; 
   background:white; 
   top:0px !important;
   width: 100vw !important;
   transform: none !important;
   max-width:100%;
   max-height: 100%;
   margin: 0px auto !important; 
   left: 0px !important;
   right: 0px !important;
   opacity:1;
   z-index:90001;
}
.primary{
   display: block;
}
.texto{
  text-align: center;
}
}

.zoom-m-enter-active,
.zoom-m-leave-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: zoom-m;
}

.zoom-m-leave-active {
  animation-direction: reverse;
}

@keyframes zoom-m {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}







/* Parten */
.buscador-cont{
   position: relative;
   max-width: 300px;
   margin: 0 auto;
}
.popup-comuna
{
   position: absolute;
   top:42px;
   left:0px;
   border: 1px solid rgba(0,0,0,0.4);
   border-radius: 4px;
   width: 100%;
   text-align: left;
}
.item-comuna{
   cursor: pointer;
   padding: 2px 10px;
   background-color: #fff;
   border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.comuna_error{
  position:fixed; 
  z-index:99999; 
  top:50%; 
  transform: translate(-50%, -50%); 
  left:50%; 
  max-width:320px; 
  padding:20px; 
  background-color:white; 
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
  border-radius:15px;
      -webkit-animation: move 5s linear infinite;
    -moz-animation: move 5s linear infinite;
    animation: move 0.18s linear infinite;
    animation-iteration-count:1;
}

/* Segundo estado */

.btn-mostrar-mas{
  margin-top: 10px;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 4px 12px;
  color: white;

}
.btn-mostrar-mas:hover{
  opacity: 0.8;
}
.no-disponible{
  background: linear-gradient(45deg, rgba(255, 0, 0, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 0, 0, 0.4) 50%, rgba(255, 0, 0, 0.2) 75%, transparent 75%, transparent);
  background-size: 6px 6px;
  
}

.disponible{
  position: relative;
}
.disponible::after {
  content: "";
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: green;
  
}


.btn-volver{
  border: 2px solid rgba(0, 0, 0,1);
  color: rgba(0, 0, 0,1);
}
.btn-volver:hover{
  border: 2px solid rgba(0, 0, 0,1);
  color: rgba(0, 0, 0,1);
}
.btn-bien{
opacity: 1;

}
.btn-bien:hover{
color:white;

}
.btn-mal{
  color:white;
  opacity: 0.5;
}
.btn-mal:hover{
  color:white;
  
}
.glow {
  color: #fff;
  -webkit-animation: glow 0.5s ease-in-out infinite alternate;
  -moz-animation: glow 0.5s ease-in-out infinite alternate;
  animation: glow 0.5s ease-in-out infinite alternate;
  animation-iteration-count: 7;
  opacity: 1;
}

@keyframes glow {
  from {
    transform: rotate(-3deg);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  to {
    transform: rotate(3deg);
    box-shadow: none;
  }
}

.alerta{
  padding:10px;
  background-color: #dc3545;
  color:white;
  position:absolute;
  
  right:3px;
  font-weight: 700;
  top: -41px;
  font-size: 12px;
  transition-duration: 0.2s;
}
.alerta:after{

  content:'';
  right:30px;
  top:33px;
  width: 10px;
  height: 10px;
  background: #dc3545;
  border-right: 1px solid #dc3545;
  border-bottom: 1px solid #dc3545;
  transform: rotate(45deg);
  position: absolute;
}

.form-group{
text-align: left;
padding-left:4px;

}
.bottom-spacer{
  height:0px;
}
.fade-leave-active{
  transition: opacity 0s;
}
.fade-enter-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.msg-top{
  text-align:left; margin-left:60px; height:40px; line-height:30px;
}
/* ---------- GENERAL ---------- */
.combinaciones{
  padding: 10px; width:100%; border:2px solid rgba(0,0,0,0.3); border-radius:5px; margin-bottom:14px; cursor: pointer;
}
.combinaciones:hover{
  border:2px solid #00addf;
}
.horas{
  border:1px solid rgba(0,0,0,0.7); 
  border-radius:5px; 
  margin: 4px 5px; 
  padding:4px 5px 3px 5px; 
  font-size: 16px;
  color: rgba(0,0,0,0.7);
  display:inline-block; 
  transition: 200ms;
}
.horas{

  cursor: pointer;
}
.isActive{
  max-height:450px;
  padding:20px !important;
  transition: max-height 0.25s ease-in;
}
.isInActive{
  max-height:0px;
  padding:0px;
  transition: max-height 0.15s ease-out;
}


table {
	border-collapse: collapse;
	border-spacing: 0;
  width:100%;
}


/* ---------- CALENDAR ---------- */
.mobile{
  display: none;
}
.number{
  text-align: center;
  position:absolute;
  left:30px;
  border: 2px dashed rgba(0,0,0, 0.7);
  color: rgba(0,0,0, 0.7);
  border-radius: 50%;
  width:30px;
  height:30px;
  line-height: 30px;
}
.doble-columna{
  vertical-align: top;
  margin-top:0px;
  display: grid;
  
}
.calendar {
  position: relative;
	text-align: center;
  width:390px;
  display: inline-block;
    grid-column: 1;
  grid-row: 1;
  min-height:434px;


}
.horarios{

  margin-top:0px;
  position: relative;
  display: inline-block;

  width:390px;
    grid-column: 2;
  grid-row: 1;
}

.calendar header {
  position: relative;
  width:350px;
	
}
.button-container{
  position: relative;
  display: inline-block;
  margin-top:0px;
  height:20px;
  width:300px;
}
.calendar h2 {
	/*text-transform: uppercase;*/
}

.calendar thead {
	font-weight: 600;
	text-transform: uppercase;
}

.calendar tbody {
	color: #7c8a95;
}

.calendar tbody td:hover {
	border: 2px solid rgb(0, 0, 0);
}

.calendar td {
  font-size: 12px;
  cursor: pointer;
	border: 2px solid transparent;
	border-radius: 50%;
	display: inline-block;
	height: 50px;
	line-height: 50px;
	text-align: center;
	width: 50px;
}

.calendar .prev-month,
.calendar .next-month {
	color: #cbd1d2;
}

.calendar .prev-month:hover,
.calendar .next-month:hover {
	border: 2px solid #cbd1d2;
}

.current-day {
	background: black;
	color: #f9f9f9;
}
.selected-day {
	background: rgb(0, 0, 0);
	color: #f9f9f9;
}



.event:after {
	background: rgb(0, 0, 0);
	border-radius: 50%;
	bottom: .5em;
	display: block;
	content: '';
	height: .5em;
	left: 50%;
	margin: -.25em 0 0 -.25em;
	position: absolute;
	width: .5em;
}

.event.current-day:after {
	background: #f9f9f9;
}

.btn-prev,
.btn-next {
	border: 2px solid #cbd1d2;
	border-radius: 50%;
	color: #cbd1d2;
	height: 30px;
	font-size: .75em;
	line-height: 2em;
	top: -3px;
  cursor: pointer;

	position:absolute;
	width: 30px;
}

.btn-prev:hover,
.btn-next:hover {
	background: #cbd1d2;
	color: #f9f9f9;
}

.btn-prev {
	left: 10px;
}

.btn-next {
	right: 10px;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
h1{
  color: rgba(0,0,0, 0.6);
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0 4px;
}
li:hover {
  background: rgb(40, 150, 249);
  color:white !important;

}

.object-container{
  padding: 0px 0px 10px 10px;
  display:block;
  float:left;
}
.editIcons{
  cursor: pointer;
  margin-right:10px;
}

.object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  width:100%;
  display:block;
  float:left;
  height:auto;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
  padding:20px;
}
.shadow-object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  height:auto;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 3px 8px;
  padding:2px;
}

.main{
  width:100%;
  min-height:100vh;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:80px;
  text-align:left;
  width:100%;
  max-height:100vh;
}
.column-left{

  height:100vh;
  float: left;

}

.boxy{
  padding:10px;
  height:100%;

  border-radius: 20px;
}
h4{
  font-size: 16px;
  font-weight: 700;
  margin-bottom:30px;
}
.top-spacer{
  height:130px;
}
@media only screen and (max-width: 350px) {


.calendar {
  position: relative;
	text-align: center;
  width:310px !important;
  display: inline-block;
    grid-column: 1;
  grid-row: 1;
  min-height:434px;
}

  .calendar header {
  position: relative;
  width:320px;
	
}
.button-container{
  position: relative;
  display: inline-block;
  margin-top:0px;
  height:20px;
  width:300px;
}
.calendar h2 {
	/*text-transform: uppercase;*/
}

.calendar thead {
	font-weight: 600;
	text-transform: uppercase;
}

.calendar tbody {
	color: #7c8a95;
}

.calendar tbody td:hover {
	border: 2px solid rgb(0, 0, 0);
}

.calendar td {
  font-size: 12px;
  cursor: pointer;
	border: 2px solid transparent;
	border-radius: 50%;
	display: inline-block;
	height: 40px;
	line-height: 40px;
	text-align: center;
	width: 40px;
}

.calendar .prev-month,
.calendar .next-month {
	color: #cbd1d2;
}

.calendar .prev-month:hover,
.calendar .next-month:hover {
	border: 2px solid #cbd1d2;
}

.current-day {
	background: black;
	color: #f9f9f9;
}
.selected-day {
	background: rgb(0, 0, 0);
	color: #f9f9f9;
}


}
@media only screen and (max-width: 1200px) {
  .top-spacer{
  height:85px;
}
.bottom-spacer{
  height:90px;
}
}
@media only screen and (max-width: 1000px) {
  .top-spacer{
  height:85px;
}
.bottom-spacer{
  height:60px;
}
  .mobile{
    display: block;
  }
  .main-cont{
  padding-top:10px;
}
  .calendar{
    width:100%;
    float:none;
  }

  .number{
  position:absolute;
  left:5px;
  top:10px;
  width:30px;
  height:30px;
  line-height: 30px;
}
.doble-columna{
  margin-top:10px;
  text-align: center;
}
.calendar {
  float:none;
  position: relative;
	text-align: center;
  width:350px;
  max-width:480px;
  display: inline-block;
  margin:auto;

}
.boxy{
  padding:0px;
  margin: 0;
}
.msg-top{
  padding-top:10px;
  margin-left:40px;
}
.horarios{

  position: relative;
  display: block;
  max-width:480px;
  width:100%;
  margin-left:0;
  margin-top:0px;
  grid-column: 1;
  grid-row: 2;
}
}
.btn-cg{
  background-color: rgb(255, 200, 9) !important;
  color:white !important;
}
.btn-cg:hover{
  background-color: rgb(255, 180, 7) !important;
  color:white !important;
}
.btn-cg:active, .btn-cg:visited{
  outline-color: red;
}


@media (min-width: 576px) { 
.btn-volver{
  width:180px !important;
}
.btn-bien{
  width:180px !important;
}
.btn-mal{
  width:180px !important;
}
}


@media (min-width: 768px) { 
.btn-volver{
  width:240px !important;
}
.btn-bien{
  width:240px !important;
}
.btn-mal{
  width:240px !important;
}
 }


@media (min-width: 992px) { 
.btn-volver{
  width:240px !important;
}
.btn-bien{
  width:240px !important;
}
.btn-mal{
  width:240px !important;
}
 }


@media (min-width: 1200px) {
.btn-volver{
  width:240px !important;
}
.btn-bien{
  width:240px !important;
}
.btn-mal{
  width:240px !important;
}

}


@media (min-width: 1400px) { 
.btn-volver{
  width:240px !important;
}
.btn-bien{
  width:240px !important;
}
.btn-mal{
  width:240px !important;
}
}
/* Tercer estado */


.tu-orden-sub{
  color:rgb(120,120,120);
  margin:10px 0px;
  text-align: left;
}
.icono-b img{
  max-height:80px;

}
.icono-b{
  color:#fff;
  display: inline-block;
  
  text-align: left;
}
.iconos{
  max-width: 1400px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0 auto;
}


.barra-abajo{
  height:170px;
  background-color: #0085e4;
  border-top:10px solid rgba(0, 121, 200, 1);
  line-height: 170px;
}
.orden-container{
/*   border: 1px solid rgb(240, 240, 240); */
  border-radius: 15px;
}
.tu-orden{
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;

}
.popup-examen-2{
  position: absolute;
  z-index: 999;
  top:57px;
  left:0px;
  max-width: Calc(100% - 20px);
  background-color: #fff;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  text-align: left;
}
.hero-input {
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    margin-top: 20px;
    transition: 0.3s;

}

#heroInput {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 25px; /* Rounded corners */
    padding: 10px;
    font-size: 16px;
    box-sizing: border-box;
    outline: none;
}
#heroInput:focus > .hero-input {
  box-shadow: 0 4px 8px 0 red; /* Subtle shadow for depth */

}
.input-add {
    width: 100%;
    height: 100%;
    border: none;
    
    padding: 10px;
    font-size: 16px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    outline: none;
}
#input-add::placeholder{
  color:rgb(200,200,200);
}
#heroInput::placeholder{
  color:rgb(200,200,200);
}
.subtitulo{
  color:rgb(140,140,140);
}
.titulo1{
  font-size: 34px;
  line-height: 45px;
  margin-bottom: 20px;
  font-weight: 600;
  color:rgb(60,60,60);
}
.titulos{
  text-align: left;
}
.onright{
  width: 400px;
  padding-left: 40px;
  text-align: left;
  height: 100%;
}
.resumen-cont{
  background-color: rgb(248,248,248);
  border-radius: 4px;
  margin-bottom: 10px;
  padding:10px;
  border: 1px solid rgb(230,230,230);
  position: relative;
}
.total-o{
 

  font-weight: 600;
  font-size: 18px;

}
.total-c{
  margin-top: 10px;


}

.main-tit{
  padding-top:40px;
  font-weight:700;
  color: rgb(52,52,52);

}

.main-grid{ 
  position: relative;
  max-width: 1200px;
  min-height: 600px;
  margin: 0 auto;
  display: flex;
  flex-grow: 1;
  
  align-items: center;
  justify-content: center; 

}
.main-grid-2{ 
  max-width: 1200px;
  width: 100%;
  min-height: 600px;
  margin: 0 auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding:20px;



}

.leftyx2{
  flex-grow: 1;


  height: 100%;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rightyx{
  position: relative;
  
  background-color: rgb(250,250,250);
  border-radius: 15px;
  padding: 10px 20px;
  border-radius: 5px;
  height: 100%;
  text-align: left;
  padding-bottom:80px;
  
}
.rightyx2{
  position: relative;
  
  background-color: rgb(250,250,250);
  border-radius: 15px;
  padding: 10px 20px;
  border-radius: 15px;
  height: 100%;
  text-align: left;
  padding-bottom:80px;

  min-width: 320px;
  
}
.paciente{
  position: relative;

  background-color: rgb(250,250,250);
  border-radius: 15px;
  text-align: left;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.title-center {
  padding-top:30px;
  display: table;
}
.title-center strong {
  vertical-align: middle;
  display: table;
}
.card-body p {
  text-align: left;
}

.card-text p {
  padding-top: 20px;
  text-align: center;
}



.slidepack{
  padding: 20px;
  margin-top: 20px;
}

.card-text p a {
  text-decoration: none !important;
  font-weight: bold;
}
.card-body {
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.modal-header {
  border-bottom: 0px !important;
  text-align: center;
}
.modal-header button {
  color: white;
  border-radius: 100px;
  border: 0px;
  background-color: #0085e4;
  width: 30px;
  height: 30px;
  font-size: 33px;
  line-height: 24px;
  font-weight: bold;
  padding: 0px;
  display: grid;
  position: absolute;
  top: 30px;
  right: 30px;
}

.modal-header h5 {
  text-align: center;
  margin: auto;
  color: #0085e4;
  font-weight: bold;
  font-size: 35px;
  
}

.mobile .modal-header h5 {
  margin-top: 30px !important;
}
.modal-header h5 p {
  font-weight: normal;
  font-size: 25px;
  color: #afafaf;
  margin: 0px;
}
.modal-content {
  border-radius: 30px !important;
  padding: 10px;
}

.accordion header {
  background-color: transparent;
  border: 0px;
  border-top: 0.1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.accordion .card {
  background-color: transparent;
  border: 0px;
  margin: 0px !important;
}
.card-header:last-child {
  border-bottom: 1px solid !important;
}
header.collapsed {
  border-bottom: 0px solid;
}

header.card-header {
  padding-bottom: 15px !important;
  padding-top: 15px !important;

  font-size: 20px;
  font-weight: bold;
}

.accordion .header:last-child {
  border-bottom: 1px solid;
}

.not-collapsed .down-collapse {
  display: none;
}
.collapsed .up-collapse {
  display: none;
}
.accordion header {
  cursor: pointer;
}

.accordion header span {
  text-align: left !important;
  float: left;
}

.accordion header span {
  text-align: left;
}

.center-horizontal h3{
    font-family: museo-300;
    color:#4a29d3 !important;
}
.center-horizontal{

    margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
      text-align: left;
    padding-left: 50px;
}

.container-doctor{
  position: relative;
}

.container-doctor img{
      position: absolute;
    bottom: -40px;
    width: 290px;
}
.center-horizontal p{
  max-width: 330px;

}
.modal .modal-dialog {
 
  margin: auto;
  margin-top: 170px;
}

.VueCarousel-pagination {
  width: 100%;
  text-align: right !important;
  position: absolute;
  top: -60px;
}

.banner-container {
  background-color: #f5f5f573;
  padding-top: 50px;
  padding-bottom: 50px;
}

.banner-container strong {
  color: #b5b6ba;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.banner-container img {
  width: 130px;
  filter: grayscale(0.9);
  transition: 500ms;
}
.banner-container img:hover {
  width: 130px;
  filter: grayscale(0);
}
.VueCarousel-dot-container button {
  transition: width 150ms;
}

.VueCarousel-dot-container button:focus {
  outline: 1px solid transparent !important;
}

.VueCarousel-dot-container button.VueCarousel-dot--active {
  width: 50px !important;
  border-radius: 40px;
}
.VueCarousel-dot-container .VueCarousel-dot {
  background-color: #c4c4c4;
}

.how-works-general {
  margin-top: 30px;
}

.how-works-container strong {
  color: #4a29d3;
}

/*contact*/
#contact .from-contact {
  padding-bottom: 70px;
  padding-top: 30px;
  margin-top: 0px;
}
#contact {
  background: rgba(74,41,211,.8);

  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}
#contact p.title {
  color: white;
  font-size: 35px;
  padding: 30px;
}
#contact input {
  width: 100%;
  border: 0px;
  border-radius: 12px;
  padding: 10px 10px 10px 30px;
  margin-bottom: 10px;
}
#contact textarea {
  width: 100%;
  border: 0px;
  border-radius: 11px;
  margin-top: 10px;
  padding: 10px 22px;
}
#contact button {
  background: #ffb319;
  border: 0px;
  padding: 11px;
  width: 250px;
  margin: 15px;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  display: grid;
  float: right;
}
/*contact*/
/*testimony*/
#testimony p.up {
  color: #1486e7;
  font-size: 75px;
  font-family: -webkit-body;
  font-weight: 700;
  margin-top: -20px;
  margin-left: 25px;
}
#testimony .down {
  color: #1486e7;
  font-size: 75px;
  font-family: -webkit-body;
  font-weight: 700;
  transform: rotate(180deg);
  margin-right: 35px;
}

#testimony .card img {
  width: 60px;
  border-radius: 11px;
}
#testimony .card p {
  text-align: left;
  margin-bottom: 0px;
}
#testimony .card .name {
  font-weight: 600;
  margin-bottom: 5px;
  color: #003e6a;
}
#testimony .card p.type {
  color: gray;
}
#testimony .card {
  padding: 45px;
  margin: 25px;
  border: 0px;
  box-shadow: 2px 3px 7px 0px #0c97fb1f;
  border-radius: 12px;
}

#testimony p.title {
  color: #003860;
  font-weight: bold;
  font-size: 35px;
  padding: 30px;
  text-align: left;
}

/*testimony*/
/*services*/
#services .card img {
  width: 100%;
  object-fit: cover;
  height: 200px;
}
.card .content {
  text-align: left;
  padding: 30px 40px 30px;
}
.card button.border-btn {
  border: solid 2px #ffb319;
  width: 100%;
  background: white;
  padding: 10px;
  color: #ffb319;
  border-radius: 7px;
  margin-bottom: 10px;
}
#services .card {
  margin-bottom: 15px;
}
p.pack-name {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
}
p.sub-text {
  margin-bottom: 0px;
  font-size: 12px;
  color: #4a29d3;
  font-weight: 700;
}
p.price {
  color: #4a29d3;
  font-size: 30px;
  font-weight: 700;
}
.card p.description {
  color: gray;
  line-height: 26px;
}
.card button.color {
  background: #ffb319;
  width: 100%;
  padding: 10px;
  border-radius: 7px;
  color: white;
  border: solid 2px #ffb319;
}
.card .buttons {
  padding: 20px 40px 20px;
}
#services .card {
  border: 0px;
  border-radius: 15px;
  box-shadow: 0px 0px 12px 0px #8080802b;
}
/*services*/
/*resept start*/
#recipe {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 100px;
}

.content-color {


  background: rgba(74,41,211, 1);
  padding: 40px 90px 40px 90px;
  line-height: 60px;
  color: white;
  border-radius: 31px;
  text-align: left;
}
.btn-upload {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.btn-upload input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: 0;
  max-width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}
.btn-upload  {
  padding: 8px 20px;
  background: #ffb319 !important;
  border: 1px solid #ffb319 !important;
  border-radius: 40px !important;
 
  color: #fff !important;
  border: 0;
  width: 100%;
  max-width:100%;
  width: 325px;
  
}
#recipe h2 {
  font-weight: 700;
  font-size: 35px;
}

#recipe p {
  width: 85%;
  line-height: 30px;
  font-size: 20px;
  padding-top: 15px;
}
.btn-upload:hover  {
  padding: 8px 20px;
  background: #ffb319 !important;
  color: #fff !important;
  border: 1px solid #2e6da4 !important;
  border: 0;
  width: 325px;
}
button.primary.btn {
  border: solid #1486e7 2px;
  width: 100%;
  margin: 30px 10px 30px 10px;
  color: #1486e7;
  padding: 15px;
  font-weight: 600;
}
button.primary.btn:hover {
  background: #1486e7;
  color: white;
  box-shadow: #1486e773 0px 2px 10px 3px;
  font-weight: 600;
}
#services .opctions {
  padding: 50px 50px 50px 50px;
}
#services .title {
  font-size: 40px;
  padding-top: 100px;
  padding-bottom: 10px;
}
#services .text p {
  color: #adaaaa;
  line-height: 20px;
  margin-bottom: 0px;
  display: contents;
  font-size: 17px;
}
/*recept end*/
title {
  display: inline;
}
.show-region {
  opacity: 1;
}
.select-region {
  opacity: 0.6;
}
</style>
 
<style scoped>
.g1{
  line-height: 40px;

  font-weight: 600;
  font-size: 18px;
  color:#666666;
}
.grid-2-right{
  text-align: right;

}
.buscando-item{
  padding: 10px 7px;
  border-bottom: 1px solid rgb(200, 200, 200);
  cursor: pointer;
  display: flex;
}
.buscando-item-2{
  padding: 10px 7px;
  border-bottom: 1px solid rgb(240, 240, 240);
  cursor: pointer;
  display: flex;
}
.buscando-item-2:hover{
  background-color: rgb(250,250,250);
}
.buscando-item-nombre{
  flex-grow: 1;
  
}
.buscando-item-precio{

}
.popup-examen{
  position: absolute;
  z-index: 999;
  top:53px;
  left:10px;
  max-width: Calc(100% - 20px);
  background-color: #fff;
  width: 400px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 4px;
}
.seleccionar-comuna-btn{
  background-color: #0FB1F9;
  color: #fff;
  border-radius: 5px;
  padding: 2px 12px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.grid-2{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.grid-2-left{
  grid-column: 1/3;
}

.btn-rem{
  
  outline: none;
  border-radius: 5px;
  background: #FF4136;
  border:none;
  color:#FFFFFF;
  border-radius: 9px;
  padding: 10px 18px;
  display: block;
  
  left:20px;

  bottom:20px;

  right:0px;
}
.btn-pac{
  
  outline: none;
  border-radius: 5px;
  background-color: #fff;
  border-color: #0FB1F9;
  color:#0FB1F9;

  border-radius: 9px;
  padding: 5px 6px;
  display: block;
  width: 230px;
  left:20px;

  bottom:20px;

  right:0px;
}
.btn-pagar{
  
  outline: none;
  border-radius: 5px;
  background: #0FB1F9;
  border:none;
  color:#FFFFFF;
  border-radius: 9px;
  padding: 10px 12px;
  display: block;
  width: Calc(100% - 40px);
  left:20px;
  position: absolute;
  bottom:20px;
  font-weight: 700;

  right:0px;
}
.btn-pagar:hover{
  

  background: #51cafd;

}
.gridd{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.g2{
  text-align: right;
}
.second-tit{
  color:rgb(170,170,170);
}
.table-cont{

  border-radius: 4px;
  margin-bottom: 0px;
  padding:5px 10px;

  position: relative;
}
.spacer-top{
  height: 190px;
}
@media only screen and (max-width: 860px) {

  .paciente{
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  background-color: #fff;
  border-radius: 5px;
  text-align: left;
  padding: 5px 5px;
  margin-bottom: 20px;
}

  .main-grid{ 
  max-width: 1200px;
  padding: 5px;

  grid-template-columns: 1fr !important;

  

}
.leftyx2{

grid-column:  1 / 1;
}
.rightyx{
position: relative;
box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
background-color: #fff;
padding: 10px 10px;
border-radius: 5px;
grid-column:  1 / 1;
text-align: left;
padding-bottom:80px;
}
}






/* Seccion ordenes medicas */
.comuna_error{
  position:fixed; 
  z-index:99999; 
  top:50%; 
  transform: translate(-50%, -50%); 
  left:50%; 
  max-width:320px; 
  padding:20px; 
  background-color:white; 
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
  border-radius:15px;
      -webkit-animation: move 5s linear infinite;
    -moz-animation: move 5s linear infinite;
    animation: move 0.18s linear infinite;
    animation-iteration-count:1;
}




.name-container{
  text-align:left; padding-left:60px;  white-space: nowrap;  text-overflow: ellipsis; width:160px;
}
.icon-check{
  float:left; margin-left:5px; margin-top:0px; height:25px; width:25px; color:green;
}
.icon-excl{
  color:black; float:left; margin-left:5px; margin-top:0px; height:25px; width:25px;
}
.inner-container{
  display:inline-block; padding: 10px;  vertical-align:middle;
}
.outer-container{
  padding: 20px; display:inline-block; margin:0 auto; border-radius:10px; position:relative;
}

.barrita-separadora{
  border-right:1px solid rgb(231,231,231); height:45px; left:45px; top:-5px; position:absolute;
}






.btn-subir-foto{
  background-color: white;
  color:rgba(0, 0, 0, 1);
  font-weight:700;
  border: 3px solid rgba(0, 0, 0, 1);
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition:0.1s;
  width:220px;
  margin: 5px auto;
}
.btn-subir-foto:hover{


}
.btn-receta{
  margin-top:13px; 
  border-radius:5px; 
  padding:5px 0px; 
  width:150px; 
  position:absolute; 
  top:0px; 
  right:0px;
  color:white; 
  background-color:rgba(0, 0, 0, 1);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
  margin-right:10px; 
  cursor:pointer;
}
.btn-receta:hover{

}





</style>
