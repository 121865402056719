<!-- eslint-disable -->
<template>
<div>
<transition name="zoom-m">
   
  <div v-if="showing" id="modal-receta-m" >
   <a :href="link" ref="hiddenLink" style="display:none;">Hidden Link</a>
  <div v-on:click="hide();" class="contenedor-icono">       
    <b-icon class="icon" icon="x"></b-icon>
  </div>
 <div v-if="orden != null">




 
  <div class="main-m">

<div class="filtro">
   <h4>Detalles</h4>
   <div class="listado">
   <div class="item-listado">
    <b>Id de orden</b>: {{ orden.id }}
  </div>
   <div class="item-listado">
    <b>Profesional</b>: {{  orden.profesional.nombre }} {{  orden.profesional.apellido }}
  </div>
  <div class="item-listado">
    <b>Dirección</b>: {{ orden.direccion }} ({{ orden.departamento == 1 ? 'Departamento' +(orden.n_depto != '' ? (' '+orden.n_depto) : '')  : 'Casa' }})
  </div>

  <div class="item-listado">
    <b>Comuna</b>: {{ comunas.find(x=>x.id == orden.comuna_id).Nombre }}
  </div>
  <div class="item-listado">
    <b>Fecha</b>: {{ new Date(orden.fecha).toLocaleDateString("es-ES", {   weekday: 'long',  year: 'numeric',  month: 'long',  day: 'numeric', }) }}
  </div>
  <div class="item-listado">
    <b>Horario</b>: {{ timeConvert(orden.desde) }} - {{ timeConvert(orden.hasta) }}
  </div>
  <div class="item-listado">
    <b>Estado</b>:               <select
                id=""
                v-model="orden.estado"
                name=""
                style="padding:5px; display: inline-block !important; width: 150px;"
                class="no-menu form-select form-select-lg mb-3"
                @change="cambiarEstado2(orden.id, orden.estado)"
              >
                <option
                  v-for="e, z in estados"
                  :value="z"
                >
                  {{ e }}
                </option>
              </select>
  </div>
  </div>

</div>


<div class="filtro">
   <h4>Pacientes <i style="font-size: 16px; font-weight: 100;">(Puedes hacer clic en el paciente para ver sus prestaciones)</i></h4>
   
   <table style="width: 100%;">
      <tr>
         <td style="font-weight: 700;">Nombre</td>
         <td style="font-weight: 700;">Apellidos</td>
         <td style="font-weight: 700;">Rut</td>
         <td style="font-weight: 700;">Email</td>
         <td style="font-weight: 700;">Teléfono</td>
         <td style="font-weight: 700;">Sexo</td>
         <td style="font-weight: 700;">Previsión</td>
         <td style="font-weight: 700;">Recetas</td>

      </tr>
      <template  v-for="p in orden.clientes">
         <tr style="cursor: pointer;" v-on:click="viendo != p.id ? viendo = p.id : viendo = 0;">
         <td>{{ p.nombre }}</td>
         <td>{{ p.apellido1 }} {{ p.apellido2 }}</td>
         <td>{{p.rut}} </td>
         <td>{{p.email}} </td>
         <td>{{p.telefono}} </td>

         <td>{{ p.sexo_biologico == 'f' ? 'femenino' : 'masculino' }} </td>
         <td>{{ aseguradoras.find(x=>x.id == p.id_asegurador).nombre }}</td>
         <td> 
            <span :style="{'color': prestador.admin.assets.colors.primary}" style="text-decoration: underline;" v-if="orden.recetas.some(x=>x.cliente_id == p.id)" v-on:click.prevent="fetchAndClickLink(i.id)" v-for="i, r in orden.recetas.filter(x=>x.cliente_id == p.id)">({{ r+1 }})</span> 
            <span v-if="!orden.recetas.some(x=>x.cliente_id == p.id)"><i style="color:#FF4136;">no tiene</i></span>
         </td>
        
      </tr>
      <template v-if="viendo == p.id">
         <tr >
            <td colspan="8">
               <b> Prestaciones de {{ p.nombre }}</b>
         <tr  >
         <table>
            <tr v-for="examen in orden.examenes.filter(e=>e.cliente_id == p.id)">- {{ examen.nombre }} {{ examen.codigo != null ? '('+examen.codigo+')' : '' }}</tr>
         </table>
      </tr>  
            </td>

         </tr>

      </template>
 
      </template>


</table>
</div>



  </div>

  </div>


  <div v-else>
   <Loader :height="100" :width="100" />

  </div>
</div>


</transition>
<div v-if="showing" class="background-screen"></div>
</div>


</template>

<script>
/* eslint-disable */

import {mapGetters, mapActions} from 'vuex';
import axios from 'axios';
import Loader from '@/components/Loader.vue';


export default {
  name: 'ModalGenerico',
  components: {
   Loader,

  },
  props: {


  },
  data(){
    return {
      link: '',
      viendo:0,
      orden:null,
      showing:false,
      estados : ['Anulado', 'Citado',  'Confirmado', 'Atendido', 'No Atendido'],
    }
  },
  methods:{

   cambiarEstado2(o, e){
    axios.post(window.hostname+'/api/admin/cambiarEstado', 
    {orden_id:o, estado_id:e})
    .then(response => 
    {
      this.$notify({
      group: 'foo',
      title: '¡Éxito!',
      text: '¡Se ha cambiado el estado exitosamente!',
      type:'success'
      });
    })

    .catch(e=>{
      this.$notify({
      group: 'foo',
      title: '¡Error!',
      text: '¡Ha ocurrido un error!',
      type:'error'
      });
      if (e.response.data.message)
        this.resultado = e.response.data.message           
    })

  },

   async fetchAndClickLink(recetaId) {
      try {
        const response = await axios.post(window.hostname+'/api/admin/ver_receta', { receta_id: recetaId });
        this.link = response.data.link; // Adjust this based on your API response structure
        this.$nextTick(() => {
          this.$refs.hiddenLink.click();
        });
      } catch (error) {
        console.error('Error fetching the link:', error);
      }
    },
   timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },





   send_to(route){      
      document.body.style.position = '';
      document.body.style.top = '';
      this.$router.push({path:route})
      this.hide();
    },
    show(orden){
      this.orden = orden;
      this.showing = true;
      /*document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.top = `-${window.scrollY}px`;
      */
    },
    hide(){
      this.showing = false;
     /* document.body.style.position = '';
      document.body.style.top = '';
      */
    }


  },
  created(){
   
  },
  mounted(){


  
  },
  computed:{
   ...mapGetters(['comunas', 'prestador', 'categorias_usadas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes', 'aseguradoras']),





  },
  watch:{

  },
}
</script>


<style scoped>
.item-listado{
   margin: 8px 0px;
}
.button-modal-m{
   padding:10px 40px;
   border: 2px solid rgba(0, 0, 0, 1);
   color: rgba(0, 0, 0, 1);
   font-weight: 600;
   display: inline-block;
   width: 300px;
   margin:10px;
   border-radius: 3px;
   cursor: pointer;
}
.button-modal-m:hover{
   background-color: rgba(0, 0, 0, 1);
   transition: 0.3s;
   color:white;
   box-shadow: rgba(0, 0, 0, 0.45) 0px 2px 10px 3px;
}
.background-screen{
  height: 100vh;
  width: 100vw;
  position: fixed;
 
/*   backdrop-filter: blur(2px); */
  z-index: 9999;

}
.titulo1{
  margin-bottom:30px;
  color: rgba(0, 0, 0, 1) !important;
}
.texto{
  text-align: center;
  padding: 0px 10px;
}
.main-m{
   display: flex;
   flex-wrap: wrap;
  width: 100%;
  text-align: center;



  max-height: Calc(100vh - 100px);
}
.filtro{
   text-align: left;
   background-color: rgb(250, 250, 250);
   border-radius: 15px;
   width: 100%;
   padding: 0px 10px;
   margin: 10px 0px;
}
.greeny{
  color:#FF8C00;
  cursor:pointer;
  text-decoration: underline;
}
.subtitulo2{
  color:#FF8C00;
}
#modal-receta-m{
   transition: 0.5s;
   position:fixed;



   overflow-y:auto; 
   border-radius:10px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:10px; 
   background:white; 
   top: 50%;
   left:0px;
    transform: translateY(-50%);
  
   width:900px;
   max-width:90%;
   margin: 0px auto; 
   right: 0;
   opacity:1;
   z-index:99999;
}
.flaco{
   width:600px !important;
   left: 10% !important;
   transform: translate(-10%, -50%) !important;
}
#modal-receta-m h2{
     text-align: center;
     font-weight: bold;
     color:rgba(0,0,0,0.6);
     margin-top:20px;
}
#modal-receta-m h4{

  color:rgb(0, 0, 0) !important;
  margin-bottom:15px;
  margin-top:15px;
  font-size: 20px;
  font-weight: 700;
}
#modal-receta-m h5{
  text-align: center;
  vertical-align: middle;
  color:rgba(0, 0, 0, 0.3) !important;
 
}
.cont{
  text-align: center;
  vertical-align: middle;
  position:absolute;
   width:Calc(100% - 40px);
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
#modal-receta-m .icon{
   height:40px;
   width:40px;
   color:white;
}
#modal-receta-m .contenedor-icono{
   cursor:pointer;
   position:absolute;
   right:20px;
   top:20px;
   height:40px;
   width:40px;
   background-color: rgba(0, 0, 0, 1);
   border-radius:50px;
   z-index: 100001;
}
#modal-receta-m .subtitulo{
   text-align: center;
   font-size:22px;
   margin-bottom:40px;
   margin-top:20px;
   color:rgb(181, 181, 181);
   font-weight:350;
}
#modal-receta-m .input-wrapper{
   padding:0px 5px;
}
#modal-receta-m .btn-examinar{
   background-color:#FF8C00;
   padding:8px 20px;
   font-size:14px;
   font-weight:600;
   width:200px;
   max-width:Calc(100vw - 80px);
   color:white !important;
   outline: none;
   margin-top:20px;
}
#modal-receta-m .draggable-container{
   padding: 15px 5px 20px 5px;
   max-width: 600px;
   margin: 0 auto;
   background-color: rgb(247, 247, 247);
   border-style:dashed;
   border: 2px dashed rgb(200, 200, 200);
   border-radius:5px;
}
#modal-receta-m .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white;
   z-index: 999;
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 10px); 
   border: 1px solid rgb(200, 200, 200);
}
#modal-receta-m .placeholder-c{
   position:absolute; top:33px; left:20px;
}
#modal-receta-m .receta-label-mobile{
   display: none;
}
#modal-receta-m .btn-enviar{
   margin:0 auto; 
   cursor:pointer; 
   background-color:rgba(255, 179, 25, 1);
   max-width:300px; 
   height:45px; 
   color:white; 
   font-size:18px; 
   border-radius:4px;
   vertical-align: middle;
   font-weight:500;
}
#modal-receta-m .btn-enviar-d{
   margin:0 auto; 
   cursor:pointer; 
   background-color:rgba(255, 179, 25, 0.4);
   max-width:300px; 
   height:45px; 
   color:white; 
   font-size:18px; 
   border-radius:4px;
   vertical-align: middle;
   font-weight:500;
}
#modal-receta-m .upload-img{
   height:70px;
   width:70px;
   margin:0 auto; 
   margin-bottom:40px;
   margin-top:30px;
}
#modal-receta-m .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:50%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding-right:7px;
}
#modal-receta-m .comuna{
   position:relative;
}
#modal-receta-m .input-container{
   max-width:610px;
   margin: 0 auto;
}
@media only screen and (max-width: 1000px) {
   #modal-receta-m .btn-enviar{
   margin:0 auto; 
   margin-top:30px; 
   cursor:pointer; 
   background-color:rgba(103,30,117,1);
   max-width:100%;
   margin: 20px 20px; 
   height:55px; 
   line-height:55px;
   color:white; 
   font-size:18px; 
   border-radius:4px;
   font-weight:500;
}
#modal-receta-m .btn-examinar{
   padding:10px 20px;
   font-size:18px;
   font-weight:600;
   width:300px;
   max-width:Calc(100vw - 80px);
   color:white !important;
}
#modal-receta-m .receta-label-mobile{
   display: inline-block;
}
#modal-receta-m .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:100%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding:5px 20px;
 color:grey;
 
}
#modal-receta-m .placeholder-c{
   position:absolute; top:33px; left:30px;
}
#modal-receta-m .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white; 
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 40px); 
}
#modal-receta-m .receta-label-desk{
   display: none;
}
#modal-receta-m .draggable-container{
   border:none;
   background: none;
}
#modal-receta-m .contenido{
   z-index: 999999;
   position:relative;
      overflow-y:scroll !important;
   min-height:Calc(100vh);
   -webkit-overflow-scrolling: touch;
}
#modal-receta-m{
   
   position:fixed !important;
   height:Calc(100vh) !important;
   border-radius:0px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:0px; 
   background:white; 
   top:0px !important;
   width: 100vw !important;
   transform: none !important;
   max-width:100%;
   max-height: 100%;
   margin: 0px auto !important; 
   left: 0px !important;
   right: 0px !important;
   opacity:1;
   z-index:90001;
}
.primary{
   display: block;
}
.texto{
  text-align: center;
}
}

.zoom-m-enter-active,
.zoom-m-leave-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: zoom-m;
}

.zoom-m-leave-active {
  animation-direction: reverse;
}

@keyframes zoom-m {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
</style>
