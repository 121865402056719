<template>
  <!-- eslint-disable -->
<div  style="text-align:left; background-color: rgb(242, 247, 251);">
 
  
  <div class="column-left">
    <SideNav />
  </div>

  
  <div class="column-right">
    <TopNav />
    <div class="main-container">   
      
      <div v-if="!loaded" class="articulo-principal">
        <div style="height: calc(100vh - 140px); display: flex; justify-content: center; align-items: center;">


<Loader :height="100" :width="100" />


          </div>

      </div>
      <div v-else class="articulo-principal">

        <div style="position: relative; display: inline-block;">
          <h2 style="display: inline-block; margin-right: 10px;">

          
        </h2> 

        <button :style="{'background-color':prestador.admin.assets.colors.primary}" style="transform: translateY(-4px);" class="btn-ir" v-on:click="mostrando=!mostrando;">{{ mostrando ? 'Ocultar': 'Mostrar'}} tomadores</button>

        </div>

        <div class="main-flex">
          <div class="left-f">

            <div class="boxy">
<!--           cargando...
          <Loader :height="100" :width="100" /> -->
          <div
      id="mapa"
      ref="mapa"
      class="map"
      style=""
    >
      <l-map 
        :options="{attributionControl:false, zoomControl: false}"
        :zoom="zoom"
        :center="center"
        @update:zoom="zoomUpdate" 
        @update:center="centerUpdate"
      >
        <l-tile-layer :url="url" /> 
        <l-circle-marker
          v-if="circulo.mostrando"
          :key="key"
          :lat-lng="[circulo.lat, circulo.lon]"
          :radius="25"
          :pane="'shadowPane'"
        />



        <l-moving-rotated-marker
          v-for="p in profesionales_filtrados"
          :key="p.id"
          :ref="'marker_'+p.id"

          :lat-lng="[p.lat, p.lon]"
          :visible="true"
          :duration="2000"
          :rotation-angle="0"
      
          :icon="createCustomIcon(p)"
          @mouseenter="openPopup"
          @mouseleave="closePopup"
        >
          <l-popup
            :id="p.id"
            :options="{ autoClose: false, autoPan: false, closeOnClick: false, closeButon: false, maxWidth: 'auto'}"
          >
            <span>
              {{ p.nombre }} {{ p.apellido }}
            </span>
          </l-popup>
        </l-moving-rotated-marker>
        <l-control-zoom position="topright" />
      </l-map>
    </div>
        </div>
          </div>
          <div v-show="mostrando" class="right-f">
            <div class="boxy">
              <div v-if=" socket.connected">
                <div >
        <div style="padding: 0px 20px;">
          <h5 style="text-align:left; margin-bottom:5px; font-weight:700;">
            Profesionales en ruta
          </h5>

          <div style="text-align:left; font-size:14px;">
            Actualmente hay {{ profesionales_filtrados.length }} conectados.
          </div>
  
          <hr>
        </div>
        <div style="">
          <div
            v-for="p in profesionales_filtrados"
            style="text-align:left; width:100%;"
            class="profesionales"
            @mouseenter="listMouseOver(p)"
            @mouseleave="listMouseLeave(p)"
          >
            <div style="margin-bottom:20px; padding: 0px 20px;">
              <!--<div style="display:inline;"> {{p.id}} </div>
    -->
              <div
                v-if="containsKey(p, 'nombre')"
                style="display:inline; color:rgb(7, 136,224);"
              >
                {{ p.nombre }}
              </div>
              <div
                v-if="containsKey(p, 'apellido')"
                style="display:inline; color:rgb(7, 136,224);"
              >
                {{ p.apellido }} -
              </div>
              <div
                v-if="containsKey(p, 'email')"
                style="display:inline;"
              >
                {{ p.email }}
              </div>
              <div
                v-if="containsKey(p, 'ultima_vez')"
                style="display:inline-block; font-size:14px; font-style: italic;"
              >
                Última actualización hace {{ getTimeDifference(p.ultima_vez) }}.
              </div>
              <button
              :style="{'background-color':prestador.admin.assets.colors.primary}"
                style="display:block; margin-top:4px;"
                class="btn-ir"
                @click="goto(p.lat, p.lon)"
              >
                Ver en el mapa
              </button>
            </div>
          </div>
        </div>
      </div>

      </div>
      <div
        v-else
        style=" padding-top:70px;"
      >
        <div
          style="margin: auto auto;"
          class="loader"
        />
        <div style="color:rgb(140, 140, 140); margin-top:10px; font-style: italic;">
          Cargando...
        </div>
      </div>
            </div>
          </div>


      </div>
        

      </div>
    </div>
  </div>



</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import * as L from 'leaflet'
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LGeoJson, LCircle, LCircleMarker, LPopup, LIcon, LControlAttribution, LRectangle, LPolygon,LPolyline, LControlZoom } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import io from 'socket.io-client';
import {mapGetters, mapActions} from 'vuex';
import TopNav from '@/components/TopNav.vue';
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import LMovingRotatedMarker from 'vue2-leaflet-moving-rotated-marker'
import defaultImage from "@/assets/ia/emptyuser.webp";

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    Loader,
    TopNav,
    LMap,
    LTileLayer,
    LControlZoom,
    LMovingRotatedMarker,
    TopNav,
    LPopup,
    LCircleMarker,
  },
  data() {
    return {
      loaded:false,
      icon: L.icon({
          iconUrl: require('@/assets/persona.png'),
          iconSize: [30,30],
          iconAnchor: [15,15],
          popupAnchor: [0,-7]
      }),
      circulo:{'mostrando':false, 'lat':0, 'lon':0},
      profesionales:[],
      profesionales_filtrados:[],
      profesionales_posibles:[],
      key:1,
      socket:undefined,
      mostrando:false,
      zoom:12,
      center: L.latLng(-33.438926, -70.651746),
      currentCenter: L.latLng(-33.438926, -70.651746),
      url:'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',

    }
  },
  created(){
  

  },

  methods:{
    ...mapActions(['loginAdmin', 'logoutAdmin']),

    createCustomIcon(profes) {
      let profesional_atributos = this.profesionales_posibles.find(x=>x.id == profes.id) != null ?  this.profesionales_posibles.find(x=>x.id == profes.id).atributos : null
      const imageUrl = profesional_atributos && profesional_atributos.foto ? 'https://assets-fotos-profesionales.s3.sa-east-1.amazonaws.com/'+profesional_atributos.foto : defaultImage;
      const iconHtml = `
        <div style="background: white; transform:translate(-2px, -3px); border: 3px solid ${this.prestador.admin.assets.colors.primary}; border-radius: 50%; overflow: hidden; width: 45px; height: 45px;">
          <img src="${imageUrl}" style="width: 100%; height: 100%; object-fit: cover;">
        </div>
      `;

      return L.divIcon({
        html: iconHtml,
        iconSize: [40, 40],
        className: ''
      });
    },

    getProfesionales(){
      this.loaded = false;
      this.profesionales_posibles = [];
      axios({url: window.hostname+'/api/admin/getprofesionalesgeo', method:'GET'})
      .then((r)=>{
        
        this.profesionales_posibles = r.data.profesionales;
        this.loaded = true;
      })
      .catch((error)=> {
        this.loaded = true;
        console.log(error);
      })
    },

      openPopup: function (event) {
        console.log("LOL")
          event.target.openPopup();
      },
      closePopup: function (event) {
          event.target.closePopup();
      },

    getTimeDifference(t){
      let diff = Date.now() - new Date(t).getTime()
      let minutos = Math.floor(((diff % 86400000) % 3600000) / 60000)
      let segundos = Math.floor((((diff % 86400000) % 3600000) % 60000)/1000)
      if (minutos > 0)
        return minutos+' minutos y '+segundos+ (segundos > 1 && segundos != 0 ? ' segundos' : ' segundo')
      return segundos+ (segundos > 1 && segundos != 0 ? ' segundos' : ' segundo')

    },
    containsKey(obj, key ) {
        return Object.keys(obj).includes(key);
    },
    listMouseOver: function(x){
      this.circulo.lat = x.lat;
      this.circulo.lon = x.lon;
      this.circulo.mostrando = true;

    },
    listMouseLeave: function(x){
      this.circulo.mostrando = false;

    },
    goto: function(lat, lon){
      if (lat != null && lon != null)
        this.center =  L.latLng(lat+(Math.random()*0.000001), lon+(Math.random()*0.000001));
    },
    zoomUpdate: function(zoom){
      this.currentZoom = zoom;
    },
      centerUpdate: function(center){
      this.currentCenter = center;
    },


  },
  watch:{
    profesionales: function(val){
      this.key += 1;
      this.profesionales_filtrados = []
      let ret = []
      for(var z of this.profesionales)
      {
        if(!this.profesionales_posibles.some(x=>x.id == z.id) && this.prestador.id != 1)
          continue;
        if(z.lat != null && z.lon != null)
        this.profesionales_filtrados.push(z)

      }
      //this.profesionales_filtrados = ret
      this.$forceUpdate();
    }
  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestador', 'prestaciones', 'tipos', 'admin_datos', 'comunas_usadas', 'token']),
  },
  mounted(){

    this.getProfesionales();
    this.socket = io(window.socket,   {extraHeaders: {
    "token": this.token,
    "tipo": "admin",
    "usuario": JSON.stringify(this.admin_datos)
  }})
  console.log("token: ", this.token)
    this.socket.on("connect", ()=>{
      console.log("Se conecto")
    })
    this.socket.on("emitir_info_admin", (data) => {
      this.profesionales = JSON.parse(data);
    });

    setInterval(() => {
    this.socket.emit('escuchar_info_admin', this.token)

    }, 3000);
  }

}
</script>


<style scoped>
.btn-ir{
  border: none;
  border-radius: 4px;
  padding: 2px 20px;
  color:#fff;
}
.btn-ir:hover{
  opacity: 0.8;
}
.main-flex{
  display: flex;
}
.right-f{
  padding-left:10px;
  width: 350px;
}
.left-f{
  flex-grow: 1;
  position: relative;
}
  .map{
    
    /*-moz-animation: 
    height: 320px;
    width: 320px;
    */
    /*transform: translate(0px, -120px);*/
    height: Calc(100vh - 100px);
    /*min-width: 400px;*/
    min-width: 400px;

    margin-right:0 !important;
    z-index: 1;
}

td{
  text-align: left;
}
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;


  height: 100vh;
  
  margin: 0 auto;




}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);

  padding-top:0px;
  padding-left:275px;
  width:100%;
  

}
.main-container{
  background-color: rgb(242, 247, 251);
  padding: 10px;

  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

