<template>
  <div>
    <TopNav />
    <div class="container">
      <div v-if="loaded">
        <h2>Modificar profesional: {{ profesional.nombre }}</h2>

        <hr>
        <br>
        <h4 style="text-align:left; margin-bottom:20px;">
          Horarios <b-icon icon="clock" />
        </h4>
        <button
          style="margin-bottom:10px;"
          class="btn btn-dark"
          @click="reasignar_horario()"
        >
          Reasignar ordenes
        </button>
        <table style="width:100%;">
          <tr style="background-color:rgba(0,0,0,0.8); color:white;">
            <td>Id</td>
            <td>Días</td>
            <td> Horario</td>
            <td> Color</td>
            <td> Comunas</td>      
            <td> Categorias</td>

            <td>Intervalo</td>
            <td>Anticipacion</td>
            <td>Prioridad</td>
            <td>Recargo (%)</td>
            <td>Recargo (monto)</td>
            <td>Editar</td>
          </tr>
          <tr
            v-for="h in profesional.horarios"
            style="text-align:left;"
          >
            <td>
              {{ h.id }} 
            </td>
            <td>
              <span
                v-for="r, i in h.repeticion"
                v-if="r==1"
              >{{ dias[i] }}, </span> 
            </td>
            <td>
              {{ timeConvert(h.inicio) }} - {{ timeConvert(h.termino) }}
            </td>
            <td>
              <div
                style="border-radius: 5px; height:10px; width:10px;"
                :style="{'background-color':h.color}"
              />
            </td>

            <td>   
              <div v-for="c in h.comunas">
                {{ comunas.filter(x=>{return x.id == c})[0].Nombre }}
              </div>
            </td>
            <td>   
              <div v-for="c in h.categorias">
                {{ categorias.filter(x=>{return x.id == c})[0].nombre }}
              </div>
            </td>

            <td>{{ h.intervalo }} min</td>
            <td>{{ timeConvert2(h.anticipacion) }}</td>
            <td>{{ h.prioridad }}</td>
            <td>{{ h.recargo_porcentaje }}%</td>
            <td>${{ Intl.NumberFormat('de-DE').format(h.recargo_suma) }}</td>
            <td>
              <!-- <b-icon
                style="color:red; cursor:pointer;"
                icon="trash"
                @click="eliminar_horario(h.id)"
              /> -->
              <router-link :to="'/ModificarHorarioPro/'+h.id">
                <b-icon
                  style="color:blue; cursor:pointer;"
                  icon="pen"
                />
              </router-link>
            </td>
          </tr>
        </table>
        <br>

        <table style="width:100%;">
          <td>
            <button
              style="margin-right:6px;"
              class="btn btn-info"
              @click="semanaAnterior();"
            >
              <b-icon
                icon="arrow-left-circle"
                style="margin-right:6px;"
              /> Semana Anterior
            </button>
          </td>
          <td style="text-align:center;">
            <h2> Semana del {{ desdeD.getDate() }} de {{ meses[desdeD.getMonth()] }} del {{ desdeD.getFullYear() }}</h2>
          </td>
          <td style="text-align:right;">
            <button
              class="btn btn-info"
              @click="semanaSiguiente()"
            >
              Semana Siguiente <b-icon
                icon="arrow-right-square"
                style="margin-left:6px;"
              />
            </button>
          </td>
        </table>
        <div
          style="padding:0px; margin-bottom:40px; position:relative; margin-top:0px;"
          class="shadow-object"
        >
          <div
            v-if="!loaded_calendario"
            style="position:absolute; height: 100%; width:100%; background:rgba(0,0,0,0.4); z-index:999;"
          >
            <div style="position:absolute; top:50%; color:white; left:50%;">
              <div
                class="spinner-border spinner-border-sm"
                role="status"
              />
              Cargando...
            </div>
          </div>

          <table
            class="Table-Normal"
            style="width:100%; text-align:center;"
          >
            <td style="width:12.5%; overflow: hidden; text-overflow: ellipsis; white-space:nowrap; background-color:rgb(38,53,66); border:1px solid white; color:white;">
              Horario
            </td>
            <td
              v-for="d, i in dias2"
              style="width:12.5%; overflow: hidden; text-overflow: ellipsis; white-space:nowrap; background-color:rgb(38,53,66); border:1px solid white; color:white;"
            >
              {{ d }} {{ viendoSemana[i] }}
            </td>
          </table>
                  
          <table style="width:12.5%; text-align:center; ">
            <!--display: -webkit-box-->
            <tr
              v-for="i in Math.ceil((horaMax-horaMin)/60)"
              style="text-align:center; font-size:10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-height:50px; height:50px; line-height:48px; background-color:rgb(38,53,66); border:1px solid white; color:white;"
            >
              {{ timeConvert(horaMin+(i-1)*60) }}-{{ timeConvert(horaMin+(i)*60) }}
            </tr>
          </table>

          <div
            v-for="h in calendario.ordenes"
            v-if="new Date(h.fecha.a, h.fecha.m-1, h.fecha.d) >= desdeD && new Date(h.fecha.a, h.fecha.m-1, h.fecha.d) <= hastaD"
            :key="h.id" 
            :style="{
              'left':(12.5*(viendoSemana.findIndex(x=>{return x == h.fecha.d})+1)).toString()+'%', 'line-height':(50*(h.hasta-h.desde)/60)+'px', 
              'height':(50*(h.hasta-h.desde)/60)+'px', 'top':(((h.desde-horaMin)*50/60)+25).toString()+'px',
              'background': h.estado == 2 ? 'repeating-linear-gradient(45deg,#606dbc,#606dbc 10px,#465298 10px,#465298 20px)': h.estado == 1 ? 'repeating-linear-gradient(45deg,#FF851B,#FF851B 10px,#fcb577 10px,#fcb577 20px)' : 'repeating-linear-gradient(45deg,#3D9970,#3D9970 10px,#549c7c 10px,#549c7c 20px)'
            }" 
            style="font-size:12px; position:absolute; vertical-align:middle; text-align:center; color:white; width:12.5%;"
          >
            Id: {{ h.id }}
          </div>
        </div>
                        




        <br>
        <h4 style="margin-bottom:20px; text-align:left;">
          Agregar horario <b-icon icon="plus" />
        </h4>
        <div
          class="container"
          style="text-align:left;"
        >
          <div style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; padding:20px; border-radius:15px;">
            <div style="display:inline-block; width:40%;">
              <div style="margin-bottom:20px;">
                Nombre:
                <input
                  id=""
                  v-model="nombre"
                  type="text"
                  name=""
                >
              </div>
              <div style="margin-bottom:20px;">
                Intervalo:
                <select v-model="periodo">
                  <option
                    v-for="o in periodos_posibles"
                    :value="o"
                  >
                    {{ o }} minutos
                  </option>
                </select>
              </div>
              <table style="margin: 0; margin-bottom:20px">
                <td style="vertical-align:middle; padding-right:20px;">
                  <tr>
                    Inicio:
                  </tr>
                  <tr>
                    <select v-model="agregando_horario.inicio">
                      <option
                        v-for="v in Math.round(1440/5)"
                        :value="(v-1)*5"
                      >
                        {{ timeConvert((v-1)*5) }}
                      </option>
                    </select>
                  </tr>
                </td>
                <td style="vertical-align:middle; padding-right:20px;">
                  <tr>
                    Termino: 
                  </tr>
                  <select v-model="agregando_horario.termino">
                    <option
                      v-for="v in Math.round((1440-agregando_horario.inicio)/periodo)"
                      :value="(v)*periodo + agregando_horario.inicio"
                    >
                      {{ timeConvert((v)*periodo + agregando_horario.inicio) }}
                    </option>
                  </select>
                </td>
                        
                <td style="vertical-align:middle;">
                  <table>
                    <tr>
                      <td
                        v-for="x in dias"
                        style="text-align:center; width:30px;"
                      >
                        {{ x }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        v-for="x in agregando_horario.repeticion"
                        style="text-align:center;"
                      >
                        <input
                          v-model="x.value"
                          type="checkbox"
                          :true-value="1"
                          :false-value="0"
                        >
                      </td>
                    </tr>
                  </table>
                </td>
              </table>
              <div style="margin-bottom:20px">
                Anticipación necesaria (en horas):
                <select v-model="anticipacion">
                  <option
                    v-for="v in 25"
                    :value="(v-1)*60"
                  >
                    {{ timeConvert((v-1)*60) }}
                  </option>
                </select>
              </div>    
              <div style="margin-bottom:20px">
                Anticipación mínima (en horas):
                <select v-model="anticipacion_minima">
                  <option
                    v-for="v in 25"
                    :value="(v-1)*60"
                  >
                    {{ timeConvert((v-1)*60) }}
                  </option>
                </select>
              </div>
              <div style="margin-bottom:20px">
                Anticipación autoconfirmación (en horas): 
                <select v-model="anticipacion_autoconfirmacion">
                  <option
                    v-for="v in 25"
                    :value="v-1"
                  >
                    {{ timeConvert(((v-1)*60)) }}
                  </option>
                </select>
              </div>
              <div style="margin-bottom:20px">
                Solo se puede agendar hasta (días antes y hora):
                <select v-model="anticipacion_fija_minima_dias">
                  <option
                    v-for="day in [0,1,2,3]"
                    :value="day"
                  >
                    {{ day }} Días antes
                  </option>
                </select>
                <select v-model="anticipacion_fija_minima_hora">
                  <option
                    v-for="v in 25"
                    :value="v-1"
                  >
                    A las {{ timeConvert(((v-1)*60)) }}
                  </option>
                </select>
              </div>
              <div style="margin-bottom:20px">
                Solo se puede agendar desde(días antes y hora):
                <select v-model="disponible_desde_dias">
                  <option
                    v-for="day in [0,1,2,3]"
                    :value="day"
                  >
                    {{ day }} Días antes
                  </option>
                </select>
                <select v-model="disponible_desde_hora">
                  <option
                    v-for="v in 25"
                    :value="v-1"
                  >
                    A las {{ timeConvert(((v-1)*60)) }}
                  </option>
                </select>
              </div>
              <div style="margin-bottom:20px">
                Prioridad:
                <select v-model="prioridad">
                  <option
                    v-for="v in prioridades"
                    :value="v"
                  >
                    {{ v }}
                  </option>
                </select>
              </div>    
              <div style="margin-bottom:20px">
                Recargo (%):
                <select v-model="recargo_porcentaje">
                  <option
                    v-for="v in recargos"
                    :value="v"
                  >
                    {{ v }}
                  </option>
                </select>
              </div>
              <div style="margin-bottom:20px">
                Recargo (monto):
                <input
                  id=""
                  v-model="recargo_suma"
                  name=""
                >
              </div>
              Color:
              <div style="display:inline-block; transform:translateY(8px);">
                <InputColorPicker v-model="color" />
              </div>
              {{ color }}
            </div>  
            <div style="display:inline-block; width:30%; vertical-align:top;">
              Comunas
              <div>
                <input
                  v-model="comunas_buscadas"
                  type="text"
                >
                        
                <div style="margin:0; position:relative; text-align:center; width:200px;">
                  <div
                    v-if="comunas_buscadas != ''"
                    style="background:white; position:absolute; left:1px; border: 1px solid black; margin: 0; width:202px;"
                  >
                    <div
                      v-for="r in resultado_fuse_comuna.slice(0,10)"
                      style="cursor:pointer;"
                    >
                      <span @click="agregar_comunas(r.item.id)">{{ r.item.Nombre }}<b-icon
                        v-if="comunas_selected.includes(r.item.id)"
                        icon="check"
                      /> </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-for="c in comunas_selected">
                - {{ comunas.filter(x=>{return x.id == c})[0].Nombre }}
              </div>
            </div>
            <div style="display:inline-block; width:30%; vertical-align:top;">
              Categorías
              <table style="width:100%; margin: 0px 0px;">
                <tr style="background:black; color:white;">
                  <td>Id</td>
                  <td>Nombre</td>
                  <td>Puede?</td>
                </tr>
                <tr v-for="c in editando_categorias">
                  <td style="padding:5px;">
                    {{ c.id }}
                  </td>
                  <td style="padding:5px;">
                    {{ c.nombre }}
                  </td>
                  <td style="text-align:center; padding:5px;">
                    <input
                      v-model="c.puede"
                      type="checkbox"
                    >
                  </td>
                </tr>
              </table>
            </div>
            <div style="text-align:center;">
              <button
                class="btn btn-success"
                style="margin: 10px auto; "
                @click="agregar_horario(profesional.id, agregando_horario.inicio, agregando_horario.termino, agregando_horario.repeticion)"
              >
                <b-icon
                  icon="plus-circle"
                  style="margin-right:6px;"
                />Agregar horario
              </button>
            </div>
          </div>
        </div>
        <br>
        <br>


        <br>
        <h4 style="margin-bottom:20px; text-align:left;">
          Atención a prestadores <b-icon icon="plus" />
        </h4>
        <div
          class="container"
          style="text-align:left;"
        >
          <div style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; padding:20px; border-radius:15px;">
            <div style="display:inline-block; width:40%; vertical-align:top;">
              Prestadores
              <table style="width:100%; margin: 0px 0px;">
                <tr style="background:black; color:white;">
                  <td>Id</td>
                  <td>Nombre</td>
                  <td>Habilitado</td>
                </tr>
                <tr v-for="prestador in prestadores">
                  <td style="padding:5px;">
                    {{ prestador.id }}
                  </td>
                  <td style="padding:5px;">
                    {{ prestador.nombre }}
                  </td>
                  <td style="text-align:center; padding:5px;">
                    <input
                      v-model="prestador.activo"
                      type="checkbox"
                    >
                  </td>
                </tr>
              </table>
            </div>
            <div style="text-align:center;">
              <button
                class="btn btn-success"
                style="margin: 10px auto; "
                @click="post_atencion_profesional_prestador()"
              >
                <b-icon
                  icon="plus-circle"
                  style="margin-right:6px;"
                />Guardar cambios de atención a prestadores
              </button>
            </div>
          </div>
        </div>
        <br>

        <hr>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import InputColorPicker from 'vue-native-color-picker'
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  components: {
    TopNav,
    InputColorPicker,
  },
  props:['id'],
  data() {
    return {
      profesional:{},
      agregando_horario:{inicio:-1, termino:-1, repeticion:[{value:0},{value:0},{value:0},{value:0},{value:0},{value:0},{value:0}]},
      dias:['Lu','Ma','Mi','Ju','Vi','Sa','Dom'],
      dias2:['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
      periodo:60,
      periodos_posibles:[15,30,45,60,90,120],
      loaded:false,
      comunas_selected:[],
      resultado_fuse_comuna:[],
      comunas_buscadas:'',
      editando_examenes:[],
      categorias:[],
      editando_categorias:[],
      anticipacion:60,
      color:'#000000',
      anticipacion_minima:0,
      anticipacion_autoconfirmacion:0,
      anticipacion_fija_minima_dias:0,
      anticipacion_fija_minima_hora:0,
      disponible_desde_dias:0,
      disponible_desde_hora:0,
      nombre: '',

      prioridades:[1,2,3,4,5],
      prioridad:1,
      recargo_porcentaje:0,
      recargo_suma:0,
      recargos:[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],

      loaded_calendario:false,
      calendario:{ordenes:[], bloqueos:[]},
      hastaD:0,
      desdeD:0,
      mapping:[6, 0, 1, 2, 3, 4, 5],
      meses:['Enero', 'Febrero', 'Marzo', 'Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Nobiembre','Diciembre'],
      prestadores_activos: [],
      prestadores: [],
  
    }
  },
  created(){
  
    this.initialize_(this.$route.params.id);

    this.hoy = new Date();
    this.hoyMes = this.hoy.getMonth();
    this.hoyDia = this.hoy.getDate();  
    this.hoyAño = this.hoy.getFullYear();
    this.desdeD = new Date()
    this.desdeD.setDate(this.desdeD.getDate() - this.getDia(this.hoyAño, this.hoyMes, this.hoyDia))
    this.hastaD = new Date()
    this.hastaD.setDate(this.hastaD.getDate() + 6-this.getDia(this.hoyAño, this.hoyMes, this.hoyDia))

    this.viendoDia = this.hoyDia;
    this.viendoMes = this.hoyMes;
    this.viendoAño = this.hoyAño
    this.ver_calendario()
  },

  methods:{
    ...mapActions(['initialize']),
    semanaSiguiente(){
      var D = new Date(this.desdeD.getFullYear(), this.desdeD.getMonth(), this.desdeD.getDate()) 
      D.setDate(D.getDate()+7)    
      this.desdeD = D
      var H = new Date(this.hastaD.getFullYear(), this.hastaD.getMonth(), this.hastaD.getDate())   
      H.setDate(H.getDate()+7)   
      this.hastaD = H
      this.ver_calendario();
    },
    semanaAnterior(){
      var D = new Date(this.desdeD.getFullYear(), this.desdeD.getMonth(), this.desdeD.getDate()) 
      D.setDate(D.getDate()-7)    
      this.desdeD = D
      var H = new Date(this.hastaD.getFullYear(), this.hastaD.getMonth(), this.hastaD.getDate())   
      H.setDate(H.getDate()-7)   
      this.hastaD = H
      this.ver_calendario();
    },
    getDia(año, mes, dia){
      return this.mapping[new Date(año, mes, dia).getDay()];

    },
    reasignar_horario(){
  
      axios.post(window.hostname+'/api/admin/reasignar_ordenes', 
      {profesional_id: this.$route.params.id})
      .then(response => 
      {            
          this.initialize_(this.$route.params.id);
          this.initialize()
      })

      .catch(e=>{
        console.log("Error al cargar...")
      })
    },
    agregar_comunas(cid){
        this.comunas_buscadas = ''
        if(!this.comunas_selected.includes(cid))
          this.comunas_selected.push(cid)
        else
          this.comunas_selected.splice(this.comunas_selected.indexOf(cid), 1)

    },
   timeConvert2 (n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return  + rhours + " h " + rminutes + " m.";
    },
    modificar_anticipacion(){
      axios.post(window.hostname+'/api/admin/modificar_anticipacion', 
        {profesional_id: this.profesional.id, anticipacion:this.anticipacion})
        .then(response => 
        {            
            this.initialize_(this.$route.params.id);
            this.initialize()
        })

        .catch(e=>{

            console.log("Error al cargar...")
        })

    },
    cambiarPuedeCategorias(){
      var ret = []
      for(var i = 0; i < this.categorias.length; i++){
        if (this.profesional.categorias.some(x=>{return x == this.categorias[i].id}) != this.editando_categorias.filter(x=>{return x.id == this.categorias[i].id})[0].puede)
          ret.push({id:this.categorias[i].id, puede: this.editando_categorias.filter(x=>{return x.id == this.categorias[i].id})[0].puede})
      }
      
      axios.post(window.hostname+'/api/admin/modificar_categorias_pro', 
      {id: this.profesional.id,
      categorias:ret})
      .then(response => 
      {          
          this.initialize_(this.$route.params.id);
      })

      .catch(e=>{

          console.log("Error al cargar...")
      })
      
    },
    eliminar_horario(hid){
      axios.post(window.hostname+'/api/admin/eliminar_horario', 
        {horario_id: hid})
        .then(response => 
        {
            
            this.initialize_(this.$route.params.id);
        })

        .catch(e=>{

            console.log("Error al cargar...")
        })

    },
    eliminar_profesional(hid){
      axios.post(window.hostname+'/api/admin/eliminar_profesional', 
        {profesional_id: hid})
        .then(response => 
        {
            
            this.initialize_(this.$route.params.id);
        })

        .catch(e=>{

            console.log("Error al cargar...")
        })

    },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    
    initialize_(pid){
      if (typeof(this.$route.params.id === 'number'))
      {
        this.loaded = false;
        axios.post(window.hostname+'/api/admin/init_modificar_pro', 
        {id: pid})
        .then(response => 
        {
            this.loaded = true;
            this.profesional = response.data.profesional
            this.categorias = response.data.categorias
        })

        .catch(e=>{
            this.loaded = true;
            console.log("Error al cargar...")
        })
      }
    },

    ver_calendario()
    {
      if (typeof(this.$route.params.id === 'number'))
          {
            this.loaded_calendario = false;
            axios.post(window.hostname+'/api/admin/ver_calendario_pro', 
            {profesional_id: this.$route.params.id,
            dia:this.desdeD.getDate(),
            mes:this.desdeD.getMonth()+1,
            año:this.desdeD.getFullYear(),
            dia2:this.hastaD.getDate(),
            mes2:this.hastaD.getMonth()+1,
            año2:this.hastaD.getFullYear(),
            })
            .then(response => 
            {
                this.loaded_calendario = true;
                this.calendario.ordenes = response.data.ordenes
                //this.categorias = response.data.categorias
            })

            .catch(e=>{
                this.loaded = true;
                console.log("Error al cargar...")
            })
          }
        },

    agregar_horario(agenda_id, inicio, termino, repeticion){

      var error_msg = ''
      if(agenda_id == 0)
          error_msg = 'Por favor, ingrese una agenda. '
      if(inicio == -1)
        error_msg += 'Por favor, ingrese una hora de inicio. '
      if(termino == -1)
        error_msg += 'Por favor, ingrese una hora de término. '

      if(inicio > termino)
        error_msg += 'La hora de inicio no puede ser mayor a la de término. '  
      var x = '';
      for (var i = 0; i < repeticion.length; i++)
        x += repeticion[i].value.toString();
      
      if(x == '0000000')
         error_msg += 'Debes especificar al menos un día. '

      let cat = []
      for(var j = 0; j < this.editando_categorias.length; j++){
        if (this.editando_categorias[j].puede){
          cat.push(this.editando_categorias[j].id)
        }

      }
      if(cat.length == 0)
        error_msg = 'Debes agregar al menos una categoría'
      if(this.comunas_selected.length == 0)
        error_msg = 'Debes agregar al menos una comuna'

      if(error_msg == ''){

        axios.post(window.hostname+'/api/admin/addHorario', 
        {profesional_id: agenda_id,
          inicio: inicio,
          termino: termino,
          repeticion:x,
          categorias:cat,
          comunas:this.comunas_selected,
          anticipacion:this.anticipacion,
          anticipacion_minima:this.anticipacion_minima,
          anticipacion_autoconfirmacion:this.anticipacion_autoconfirmacion,
          anticipacion_fija_minima_dias:this.anticipacion_fija_minima_dias,
          anticipacion_fija_minima_hora:this.anticipacion_fija_minima_hora,
          disponible_desde_dias:this.disponible_desde_dias,
          disponible_desde_hora:this.disponible_desde_hora,
          prioridad:this.prioridad,
          recargo_porcentaje:this.recargo_porcentaje,
          recargo_suma:this.recargo_suma,
          intervalo:this.periodo,
          color:this.color,
          nombre:this.nombre,
          } )
        .then(resp=>{

          this.$notify({duration:5000, title: 'Horario agregado', text: '¡Se ha añadido el horario a la base de datos!', type: 'success'})
          this.initialize_(this.$route.params.id);
        })
        .catch(e=>{ 
          this.$notify({duration:5000, title: 'Error!', text: e.response.data.message, type: 'error'})
        })
      }
      else{
        console.log("LOL", error_msg)
        this.$notify({duration:5000, title: 'Error!', text: error_msg, type: 'error'})
      }
    },

    get_atencion_profesional_prestador(){
      const profesional_id = this.$route.params.id;
      axios.get(window.hostname+`/api/admin/atencion_profesional_prestador/${profesional_id}`)
        .then( (resp) => {
          this.prestadores = resp.data.prestadores;
          this.prestadores_activos = resp.data.prestadores_activos;
          this.prestadores.forEach( (prestador) => {
            if (this.prestadores_activos.includes(prestador.id)) {
              prestador.activo = true;
            } else {
              prestador.activo = false;
            }
          });
          console.log(this.prestadores);
        })
        .catch( (e) => { 
          this.$notify({duration:5000, title: 'Error!', text: e.response.data.message, type: 'error'})
        })
    },

    post_atencion_profesional_prestador() {
      const profesional_id = this.$route.params.id;
      let prestadores_activos = [];
      this.prestadores.forEach( (prestador) => {
        if (prestador.activo) {
          prestadores_activos.push(prestador.id);
        }
      });
      this.prestadores_activos = prestadores_activos;
      axios.post(window.hostname+`/api/admin/atencion_profesional_prestador/${profesional_id}`, {prestadores_activos})
        .then( (resp) => {
          this.$notify({duration:5000, title: 'Prestador actualizadors', text: '¡Se han realizado los cambios con éxito!', type: 'success'});
          this.get_atencion_profesional_prestador();
        })
        .then( () => alert('Se han realizado los cambios con éxito!'))
        .catch( (e) => { 
          this.$notify({duration:5000, title: 'Error!', text: e.response.data.message, type: 'error'});
          alert('Error al actualizar los prestadores');
        })
    },

  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes']),

    viendoSemana: function(){
      var o;
      var f = [new Date(this.viendoAño, this.viendoMes-1, this.desdeD.getDate())];
      var ret = [this.desdeD.getDate()]
      for (var i = 1; i < 7; i++)
      {
        o = new Date(f[i-1].getFullYear(), f[i-1].getMonth(), f[i-1].getDate())
        o.setDate(o.getDate() + 1)
        f.push(o)
        ret.push(o.getDate())
      }

      return ret;
     
    },
    horas: function(){
      let h = []
      for(var i = 0; i<this.profesional.horarios.length; i++)
      {
        for(var j = 0; j < this.profesional.horarios[i].ordenes.length; j++)
        {

          h.push(this.profesional.horarios[i].ordenes[j])
      
        }

      }
      return h;

    },
    horaMin: function(){
      var min = 360;
      for(var i = 0; i<this.profesional.horarios.length; i++)
      {
        for(var j = 0; j < this.profesional.horarios[i].ordenes.length; j++)
        {
          if (this.profesional.horarios[i].ordenes[j].desde < min)
          {
            min = this.profesional.horarios[i].ordenes[j].desde
          }
        }

      }
      return min
    },

    horaMax: function(){
      var max = 1080;
      for(var i = 0; i<this.profesional.horarios.length; i++)
      {
        for(var j = 0; j < this.profesional.horarios[i].ordenes.length; j++)
        {
          if (this.profesional.horarios[i].ordenes[j].desde > max)
          {
            max = this.profesional.horarios[i].ordenes[j].desde
          }
        }

      }
      return max
    },
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
    profesional(){
      this.editando_categorias = []
      for(var i = 0; i < this.categorias.length; i++){
        this.editando_categorias.push({id:this.categorias[i].id, nombre:this.categorias[i].nombre, puede:this.profesional.categorias.some(x=>{return x == this.categorias[i].id})})
      }
    }
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
    this.get_atencion_profesional_prestador();
  }

}
</script>


<style scoped>
td{
  text-align: left;
}
.shadow-object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  height:auto;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 3px 8px;
  padding:2px;
}
table
{ 

}

</style>

