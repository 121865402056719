// 

const backendUrl = window.hostname;

function getHeaders() {
  return {
    'Accept': 'application/json',
    'Authorization': localStorage.getItem('token_admin'),
    'Content-Type': 'application/json',
  }
}

function fetchData(path, method = 'GET', body = {}) {
  console.log("SE LLAMOO")
  return new Promise((resolve, reject) => {
    const options = {
      method: method,
      headers: getHeaders(),
    };

    if (body && Object.keys(body).length > 0) {
      options.body = JSON.stringify(body);
    }

    console.log(backendUrl)
    console.log(path)

    fetch(backendUrl + path, options)
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          reject(new Error('Network response was not OK.'));
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export default fetchData;
