<template>
  <!-- eslint-disable -->
<div  style="text-align:left; background-color: rgb(242, 247, 251);">
 
  
  <div class="column-left">
    <SideNav />
  </div>

  
  <div class="column-right">
    <TopNav />
    <div class="main-container">   
      
      <div v-if="loaded" class="articulo-principal">
        <h2>Códigos de descuento </h2>    
        <div >
          <div>

        <div class="boxy">
          <h4>Agregar un nuevo código</h4>
          <table>
            <tr>
              <td style="padding-right:10px;">
                Nombre
              </td>
              <td>
                <input
                  id=""
                  v-model="nombre"
                  type="text"
                  name=""
                >
              </td>
              <td style="padding:0px 10px;">
                Código
              </td>
              <td>
                <input
                  id=""
                  v-model="codigo"
                  type="text"
                  name=""
                >
              </td>

              <td>
                Desde <input
                  id="x2"
                  v-model="dt_dia"
                  type="date"
                > Hasta: <input
                  id="x2"
                  v-model="dt_dia2"
                  type="date"
                >
              </td>
              <td style="padding-left:10px;">
                <button
                  class="btn-ir-gordo"
                  :style="{'background-color':prestador.admin.assets.colors.primary}"
                  @click="agregar_codigo()"
                >
                  Agregar Codigo <b-icon icon="plus-circle" />
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div class="boxy" style="margin-top: 20px;">
          <h4>Códigos existentes</h4>
          <table style="width:100%; margin-top:30px;">
            <thead style="">
              <td>Id</td>
              <td>Nombre</td>
              <td>Código</td>
              <td>Fecha Inicio</td>
              <td>Fecha Termino</td>
              <td style="width:7%">
                Acciones
              </td>
            </thead>
            <tr v-for="c in codigos">
              <td>{{ c.id }}</td> 
              <td>{{ c.nombre }}</td> 
              <td>{{ c.codigo }}</td> 
              <td>{{ new Date(c.fecha_inicio.a, c.fecha_inicio.m-1, c.fecha_inicio.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}</td> 
              <td>{{ new Date(c.fecha_termino.a, c.fecha_termino.m-1, c.fecha_termino.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}</td> 
          
              <td style="text-align:center;">
                <b-icon
                  icon="trash"
                  style="color:red; cursor:pointer;"
                  @click="eliminar_codigo(c.id)"
                />
                <router-link :to="{ path: '/EditarCodigo/'+c.id }">
                  <b-icon
                    icon="pen"
                    style="cursor:pointer;"
                  />
                </router-link>
              </td>
            </tr>
          </table>
        </div>
      </div>
        </div>
      </div>

      <div v-else class="articulo-principal">
        <div style="height: calc(100vh - 140px); display: flex; justify-content: center; align-items: center;">
          <Loader :height="100" :width="100" />
        </div>
      </div>

    </div>
  </div>



</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  components:{
    TopNav,
    Loader,
    SideNav,
  },
  data() {
    return {
      dt_dia:'',
      dt_dia2:'',
      loaded:false,
      codigos:[],
      nombre:'',
      codigo:'',
      desde:{d:0, m:0, a:0},
      hasta:{d:0, m:0, a:0},

    }
  },
  created(){
  
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),







    
  initialize_(){
    //this.loaded = false;
      axios.get(window.hostname+'/api/admin/initialize_codigos')
      .then(response => 
      {
        this.loaded = true;
        this.codigos = response.data.codigos;
      })         
      .catch(e=>
      {
          this.loaded = true;
      })
  },


  agregar_codigo(){
      axios.post(window.hostname+'/api/admin/agregar_codigo',
      {nombre:this.nombre, codigo:this.codigo,
        dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
         dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a}
         )
      .then(response => 
      {
        this.initialize_()
      })         
      .catch(e=>
      {

      })
  },


  eliminar_codigo(id){
      axios.post(window.hostname+'/api/admin/eliminar_codigo',
      {codigo_id:id}
         )
      .then(response => 
      {
        this.initialize_()
      })         
      .catch(e=>
      {

      })
  },


  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'prestador', 'admin_datos', 'examenes', 'aseguradoras', 'descuentos']),
  },
  watch:{
    dt_dia(val){

        var datos = val.split('-')
        this.desde = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
      
    },
    dt_dia2(val){

        var datos = val.split('-')
        this.hasta = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
      
    },
  },
  mounted() {
  }

}
</script>


<style scoped>
thead td{
  font-weight: 700;
  padding: 10px 0px;
}
td{
  text-align: left;
}
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;



  
  margin: 0 auto;




}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);

  padding-top:0px;
  padding-left:275px;
  width:100%;
  

}
.main-container{
  background-color: rgb(242, 247, 251);
  padding: 10px;

  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

