<template>
  <!-- eslint-disable -->
<div  style="text-align:left; background-color: rgb(242, 247, 251);">
 
  
  <div class="column-left">
    <SideNav />
  </div>

  
  <div class="column-right">
    <TopNav />
    <div class="main-container">   
      
      <div v-if="loaded" class="articulo-principal">
        <h2>Modificar precio de prestación por comuna </h2>    
        <div class="boxy">


        <div class="scroll-div">
          <table class="table">
          <thead>
            <tr>
              <th />
              <th
                v-for="(comuna, index) in comunas"
                scope="col"
              >
                {{ comuna }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(examen_data, examen_id, index) in precios">
              <th
                scope="row"
              >
                {{ examen_data.nombre }}
                <b-icon
                  :class="{ 'active-row': editando_examen == examen_id, 'inactive-row': editando_examen != examen_id }"
                  :icon="editando_examen === examen_id ? 'check-circle' : 'pen'"
                  @click="toggleEditarExamen(examen_id)"
                />
              </th>
              <td v-for="(comuna, comuna_id, index) in comunas">
                <span v-if="editando_examen != examen_id">
                  {{ examen_data.por_comuna[comuna_id] > -1 ? `$${examen_data.por_comuna[comuna_id]}` : '-' }}
                </span>
                <input
                  v-else
                  v-model="nuevos_precios[comuna_id]"
                  style="width:70px;"
                  type="text"
                >
              </td>
            </tr>
          </tbody>
        </table>
        </div>


        <div style="text-align:left; margin-top:10px;" />
          
        </div>
      </div>

      <div v-else class="articulo-principal">
        <div style="height: calc(100vh - 140px); display: flex; justify-content: center; align-items: center;">
          <Loader :height="100" :width="100" />
        </div>
      </div>

    </div>
  </div>



</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'ModificarPrecioExamenComuna',
  components:{
    TopNav,
    SideNav,
    Loader
  },
  data() {
    return {

      loaded:false,
      
      precios:{},
      comunas:{},

      editando_examen:-1,
      nuevos_precios:{},

    }
  },
  created(){
  
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),

      editar_descuento(){
    axios.post(window.hostname+'/api/admin/editar_descuentos',{descuentos:this.editando_linea})
        .then(response => 
        {
          this.initialize().then(()=>{
          this.initialize_();
          })
          
        })

        .catch(e=>{
            console.log("Error al cargar...")
        })
        
  },

  toggleEditarExamen(examen_id){
    if (this.editando_examen == examen_id) {
      const data = {
        examen_id,
        nuevos_precios: this.nuevos_precios,
      }
      axios.post(window.hostname+'/api/admin/precio_por_comuna', data)
        .then( (response) => {
          this.get_precios_examenes_por_comuna();
          alert('Precios actualizados');
        })
        .catch((e)=>{
          console.log("Error al cargar...");
          alert(e);
        })
      this.editando_examen = -1
    } else {
      this.editando_examen = examen_id
    }
    this.reset_nuevos_precios();
    this.$forceUpdate();
  },

  async get_precios_examenes_por_comuna() {
    await axios.get(window.hostname+'/api/admin/precio_por_comuna')
    .then( (response) => {
      this.comunas = response.data.comunas;
      this.precios = response.data.precios;
      this.reset_nuevos_precios();
    })
    .then( () => {
      this.loaded = true;
      this.$forceUpdate();
    })
    .catch( e => { console.log(e.response) })
  },

  reset_nuevos_precios() {
    this.nuevos_precios = Object.keys(this.comunas).reduce((obj, key) => {
      obj[key] = '';
      return obj;
    }, {});
  },
    
  initialize_(){
    this.loaded = false;
    this.get_precios_examenes_por_comuna();
  },


  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes', 'aseguradoras', 'descuentos']),
  },
  watch:{

  },
  mounted() {
    const scrollDiv = document.querySelector('.scroll-div');

let isDragging = false;
let lastX;

scrollDiv.addEventListener('mousedown', (e) => {
    isDragging = true;
    lastX = e.clientX;
    scrollDiv.style.cursor = 'grabbing';
});

document.addEventListener('mousemove', (e) => {
    if (!isDragging) return;

    const deltaX = lastX - e.clientX;
    scrollDiv.scrollLeft += deltaX;

    lastX = e.clientX;
});

document.addEventListener('mouseup', () => {
    isDragging = false;
    scrollDiv.style.cursor = 'grab';
});
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
  }

}
</script>


<style scoped>
thead th{
  font-weight: 700;
}
.scroll-div {


cursor: grab !important;
overflow-x: scroll;
overflow-y: hidden;
position: relative;

}
.active-row {
  cursor:pointer;
  color:green;
}

.inactive-row {
  cursor:pointer;
  color:blue;
}

.container {
  max-width: 768px;
}

table
{ 
  margin-left: auto;
  margin-right: auto;
}
.table-holder, .table-responsive {
  position: relative;
}

.table-responsive::-webkit-scrollbar {
  height: 10px;
}

.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #333333;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #333333;
  outline: 1px solid #333333;
}

.info {
  position: absolute;
  width: 200px;
  height: 100%;
  background: linear-gradient(to right, transparent, #ffffff);
  top: 0;
  right: 0;
  padding: 20px;
  text-align: right;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  pointer-events: none;
}

.show {
  opacity: 1;
  visibility: visible;
}

td{
  text-align: left;
}

tr th:first-child, tr td:first-child {
  position: sticky;
  min-width: 50px;
  left: 0;
  background: white;
}
td{
  text-align: left;
}
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;



  
  margin: 0 auto;




}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);

  padding-top:0px;
  padding-left:275px;
  width:100%;
  

}
.main-container{
  background-color: rgb(242, 247, 251);
  padding: 10px;

  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

