  <!-- eslint-disable -->
  <template>
    <div>
      <transition name="zoom-m">
        <div v-if="showing" id="modal-receta-m">
          <div v-on:click="hide" class="contenedor-icono">
            <b-icon class="icon" icon="x"></b-icon>
          </div>
          <h2>Copiar Orden </h2>
          <div class="main-m">
            
            <!-- Profesional Selection -->
            <div class="filtro">
              <h4>Seleccione Profesional</h4>
              <select v-model="selectedProfessional" @change="fetchHorarios" class="input-hour">
                <option v-for="prof in profesionales" :key="prof.id" :value="prof.id">
                  {{ prof.nombre }} {{ prof.apellido }}
                </option>
              </select>
            </div>
  
            <!-- Date Selection -->
            <div class="filtro">
              <h4>Seleccione Fecha</h4>
              <input type="date" v-model="selectedDate" @change="filterHorariosByDate" class="input-date" />
            </div>
  
            <!-- Horario Selection -->
            <div class="filtro" v-if="filteredHorarios.length > 0">
              <h4>Seleccione Horario</h4>
              <select v-model="selectedHorario" @change="fetchHoras" class="input-hour">
                <option v-for="horario in filteredHorarios" :key="horario.id" :value="horario.id">
                  {{ formatHorario(horario) }}
                </option>
              </select>
            </div>
  
            <!-- Hora Selection -->
            <div class="filtro" v-if="horas.length > 0">
              <h4>Seleccione Hora</h4>
              <select v-model="selectedHora" class="input-hour">
                <option v-for="hora in horas" :key="hora.start_time" :value="hora">
                  {{ hora.start_time }} - {{ hora.end_time }}
                </option>
              </select>
            </div>
            
          </div>
          <button
          @click="confirmCopyOrder"
            class="menuCol btn-ir-gordo"
            :style="{'background-color':prestador.admin.assets.colors.primary}"
          >
            Copiar orden
          </button>
        </div>
      </transition>
      <div v-if="showing" class="background-screen"></div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import axios from "axios";
  import {mapGetters} from 'vuex';
  
  export default {
    name: "ModalCopiarOrden",
    data() {
      return {
        showing: false,
        orden: null,
        profesionales: [],
        horarios: [],
        filteredHorarios: [],
        horas: [],
        selectedProfessional: null,
        selectedDate: null,
        selectedHorario: null,
        selectedHora: null,
      };
    },
    methods: {
      show(orden) {
        this.orden = orden;
        this.showing = true;
        this.fetchProfessionals();
      },
      hide() {
        this.showing = false;
        this.resetSelections();
      },
      async fetchProfessionals() {
        try {
          const response = await axios.get(`${window.hostname}/api/admin/profesional`);
          this.profesionales = response.data;
          this.selectedProfessional = this.orden.profesional_id;
          this.fetchHorarios()
        } catch (error) {
          console.error("Error fetching professionals:", error);
        }
      },
      async fetchHorarios() {
        if (!this.selectedProfessional) return;
        try {
          const response = await axios.get(
            `${window.hostname}/api/admin/horario?profesional_id=${this.selectedProfessional}`
          );
          this.horarios = response.data;
          this.filteredHorarios = []; // Clear filtered horarios until a date is selected
          this.horas = [];
          this.selectedHorario = null;
          this.selectedHora = null;
        } catch (error) {
          console.error("Error fetching horarios:", error);
        }
      },
      filterHorariosByDate() {
        if (!this.selectedDate) return;
  
        const selectedDay = new Date(this.selectedDate).getDay(); // 0 = Sunday, 6 = Saturday
        this.filteredHorarios = this.horarios.filter(
          (horario) => horario.repeticion[selectedDay] === "1"
        );
  
        // Reset horario and hora selections when date changes
        this.selectedHorario = null;
        this.selectedHora = null;
        this.horas = [];
      },
      async fetchHoras() {
        if (!this.selectedHorario || !this.selectedDate) return;
        try {
          const response = await axios.get(
            `${window.hostname}/api/admin/horario/bloque?date=${this.selectedDate}&selection=available&horario_id=${this.selectedHorario}`
          );
          this.horas = response.data;
          this.selectedHora = null;
        } catch (error) {
          console.error("Error fetching horas:", error);
        }
      },
      resetSelections() {
        this.orden = null;
        this.horarios = [];
        this.filteredHorarios = [];
        this.horas = [];
        this.selectedProfessional = null;
        this.selectedDate = null;
        this.selectedHorario = null;
        this.selectedHora = null;
      },
      formatHorario(horario) {
        return `${this.formatTime(horario.inicio)} - ${this.formatTime(horario.termino)} (id: ${horario.id})`;
      },
      formatTime(minutes) {
        const hours = Math.floor(minutes / 60).toString().padStart(2, "0");
        const mins = (minutes % 60).toString().padStart(2, "0");
        return `${hours}:${mins}`;
      },
      async confirmCopyOrder() {
        if (!this.selectedProfessional || !this.selectedDate || !this.selectedHorario || !this.selectedHora) {
          alert("Por favor, complete todos los campos.");
          return;
        }
  
        const desde = this.convertTimeToMinutes(this.selectedHora.start_time);
        const hasta = this.convertTimeToMinutes(this.selectedHora.end_time);
  
        try {
          await axios.post(`${window.hostname}/api/admin/copiar_orden`, {
            ordenId: this.orden.id,
            professionalId: this.selectedProfessional,
            date: this.selectedDate,
            horarioId: this.selectedHorario,
            desde: desde,
            hasta: hasta,
          });
          alert("Orden copiada exitosamente.");
          this.hide();
        } catch (error) {
          console.error("Error copying order:", error);
        }
      },
      convertTimeToMinutes(time) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
      },
    },
    computed:{
...mapGetters(['prestador']),

    },
  };
  </script>
  
  <style scoped>
  /* Reusing styles from example */
  .button-modal-m {
     padding: 10px 40px;
     border: 2px solid rgba(0, 0, 0, 1);
     color: rgba(0, 0, 0, 1);
     font-weight: 600;
     display: inline-block;
     width: 300px;
     margin: 10px;
     border-radius: 3px;
     cursor: pointer;
  }
  .button-modal-m:hover {
     background-color: rgba(0, 0, 0, 1);
     transition: 0.3s;
     color: white;
     box-shadow: rgba(0, 0, 0, 0.45) 0px 2px 10px 3px;
  }
  .background-screen {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 9999;
  }
  .main-m {
     display: flex;
     flex-wrap: wrap;
     width: 100%;
     text-align: center;
     padding: 10px;
     gap: 10px;
     overflow-y: scroll;
     max-height: Calc(100vh - 100px);
  }
  .filtro {
     width: Calc(50% - 10px);
     background-color: rgb(250, 250, 250);
     border-radius: 15px;
  }
  #modal-receta-m {
     transition: 0.5s;
     position: fixed;
     overflow-y: auto; 
     border-radius: 10px;
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
     padding: 10px; 
     background: white; 
     top: 50%;
     left: 0px;
     transform: translateY(-50%);
     width: 700px;
     max-width: 90%;
     margin: 0px auto; 
     right: 0;
     opacity: 1;
     z-index: 99999;
  }
  #modal-receta-m h2 {
       text-align: center;
       font-weight: bold;
       color: rgba(0,0,0,0.6);
       margin-top: 20px;
  }
  #modal-receta-m h4 {
    color: rgb(0, 0, 0) !important;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
  }
  .input-date, .input-hour {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
  }
  .btn-enviar {
     cursor: pointer; 
     background-color: rgba(255, 179, 25, 1);
     max-width: 300px; 
     height: 45px; 
     color: white; 
     font-size: 18px; 
     border-radius: 4px;
     font-weight: 500;
     margin: 20px auto;
  }
  .contenedor-icono {
     cursor: pointer;
     position: absolute;
     right: 20px;
     top: 20px;
     height: 40px;
     width: 40px;
     background-color: rgba(0, 0, 0, 1);
     border-radius: 50px;
     z-index: 100001;
  }
  .icon {
     height: 40px;
     width: 40px;
     color: white;
  }
  </style>