<template>
  <!-- eslint-disable -->
<div  style="text-align:left;">
  <ModalModificarProfesional  ref="modalmodificarprofesional"  />
 
  
  
  <div class="column-left">
    <SideNav />
  </div>

  
  <div class="column-right">
    <TopNav />
    <div class="main-container">   
      
      <div v-if="loaded" class="articulo-principal">
        <h2 style="margin: 20px 0;" >Profesionales</h2>    
        <div style="margin-bottom: 20px; text-align: right;">
          <router-link
            tag="button"
            to="/CrearProfesional"
            v-if="true"
            class="btn-ir-gordo"
            :style="{'background-color':prestador.admin.assets.colors.primary}"
          >
             Crear profesional <b-icon icon="plus-circle" />
          </router-link>
          <router-link
          style="margin-left:4px;"
          tag="button"
          class="btn-ir-gordo"
            :style="{'background-color':prestador.admin.assets.colors.primary}"
          to="/BloqueosHorario"
        >
          Bloqueos Horario <b-icon icon="clock" />
        </router-link>

        </div>
        <div class="boxy">
        
          <div v-for="p in profesionales">
            

          </div>
          <table class="table-pro" style="width: 100%;">
            <thead style=" ">
              <th></th>
              <th>NOMBRE</th>
              <th>APELLIDO</th>
              <th>EMAIL</th>
              <th>EMPRESA</th>
              <th>ACCIONES</th>
            </thead>
            <tbody>
              <template v-for="p in profesionales">
            <tr style="cursor: pointer;" v-on:click="p.viendo = !p.viendo" >
              <td style="width: 100px;">
                <div style="width: 100px; border-radius: 100%; height: 100px; background-position: top; background-size: cover; " :style="{
                  'background-image': p.atributos && p.atributos.hasOwnProperty('foto') ? 'url(https://assets-fotos-profesionales.s3.sa-east-1.amazonaws.com/'+p.atributos.foto+')' : 'url(' +require('@/assets/ia/emptyuser.webp')+ ')' ,
                  'border': '3px solid '+ (p.viendo ? prestador.admin.assets.colors.primary : prestador.admin.assets.colors.secondary)

                  }">

                </div>
              </td>
              <td>{{ p.nombre }}</td>
              <td>{{ p.apellido }}</td>
              <td>{{ p.email }}</td>
              <td>{{ p.atributos != null ? p.atributos.empresa : '' }}</td>
              <td>
                <div style="color:#fff; display: inline-block; padding: 2px 4px; font-size: 14px; border-radius: 4px;" v-if="p.prestador_id != prestador.id" :style="{'background-color': prestador.admin.assets.colors.secondary}">
                    Profesional Externo
                </div>
                <i v-if="p.prestador_id == prestador.id" v-on:click="$refs.modalmodificarprofesional.show(p)" :style="{'color': prestador.admin.assets.colors.primary}" class="action-icon tf-icons ti ti-pencil">
                  
                </i>
               <router-link v-if="p.prestador_id == prestador.id" :to="'/Profesional/'+p.id">
                <i v-if="p.prestador_id == prestador.id" :style="{'color': prestador.admin.assets.colors.primary}" class="action-icon tf-icons ti ti-calendar-event"></i>
               </router-link> 
                <i v-if="p.prestador_id == prestador.id" v-on:click="enviarAppProfesional(p.id)" :style="{'color': prestador.admin.assets.colors.primary}" class="action-icon tf-icons ti ti-device-mobile-code"></i>
                <i v-if="p.prestador_id == prestador.id" v-on:click="deleteProfesionalDialogue(p.id)" :style="{'color': '#FF4136'}" class="action-icon tf-icons ti ti-trash"></i>
                <confirm-dialogue :ref="'confirmDialogueProfesional' + p.id" />
              </td>


            </tr>
            <tr v-if="p.viendo">
           
              <!--   <td style="background-color: rgba(0,0,0,0.02);"></td> -->
                <td style="background-color: rgba(0,0,0,0.02);" colspan="10">
                  <div>
                    <i style="margin-right: 6px;" :style="{'color': prestador.admin.assets.colors.secondary}" class="action-icon tf-icons ti ti-map"></i>
                     <b>Comunas</b>:
                    <span v-for="c in p.comunas">{{comunas.find(x=>x.id==c).Nombre}} </span>
                    <br/>  
                    <br/>  
                    <i style="margin-right: 6px;" :style="{'color': prestador.admin.assets.colors.secondary}" class="action-icon tf-icons ti ti-category-2"></i>

                    <b>Categorías:</b>
                    <span v-for="c in p.categorias">{{categorias.find(x=>x.id==c).nombre}} </span>
                  </div>

                </td>

              </tr>
            </template>
          </tbody>
          </table>
          
        </div>
      </div>
      <div v-else class="articulo-principal">
        <div style="height: calc(100vh - 140px); display: flex; justify-content: center; align-items: center;">


<Loader :height="100" :width="100" />


          </div>

        

      </div>
    </div>
  </div>



</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import ConfirmDialogue from './utils/ConfirmDialogue.vue';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';
import ModalModificarProfesional from './utils/ModalModificarProfesional.vue';
import fetchData from '@/helpers/fetchData.js'



export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    Loader,
    TopNav,
    ModalModificarProfesional,
    ConfirmDialogue,
  },
  data() {
    return {
      loaded:false,
      profesionales:[],


    }
  },
  created(){
  
    this.initialize_(this.$route.params.id);
  },

  methods:{
    ...mapActions(['initialize']),

    mostrar_modal_horario(){

    },

    async deleteProfesionalDialogue(profesional_id) {
      const profesional = this.profesionales.filter(x=>{return x.id == profesional_id})[0];
      const confirmDialogue = this.$refs["confirmDialogueProfesional" + profesional_id][0];
      const ok = await confirmDialogue.show({
        title: `¿Eliminar profesional ${profesional.nombre} ${profesional.apellido}?`,
        message: 'Se eliminará el profesional y no podrá recuperarse.',
        okButton: 'Eliminar definitivamente',
      })
      // If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (ok) {
        await this.eliminar_profesional(profesional_id);
      } else {
        alert('Se canceló la eliminación.')
      }
    },
    async enviarAppProfesional(profesional_id) {
       axios({url: window.hostname+'/api/admin/enviar_app_profesional', method: 'POST', data:{profesional_id: profesional_id}})
      .then((data)=>{
        alert('¡Se le ha enviado el enlace al profesional!')
        this.$notify({duration:5000, title: 'Enlace enviado', text: '¡Se le ha enviado el enlace al profesional!', type: 'success'})
      })
      .catch((error)=> {
        console.log(error)
        console.log(this.$notify)
        alert('Ha ocurrido un error...')
        this.$notify({duration:5000, title: 'Error', text: 'Ha ocurrido un error...', type: 'error'})
      })
    },

    getProfesionales(){
      this.loaded = false;
      this.profesionales = [];
      axios({url: window.hostname+'/api/admin/getprofesionales', method:'GET'})
      .then((r)=>{
        
        for(var p of r.data.profesionales){
          p.viendo = false;
          this.profesionales.push(p);
        }
        this.loaded = true;
      })
      .catch((error)=> {
        this.loaded = true;
        console.log(error);
      })
    },
    async eliminar_profesional(hid){
      
      await axios({url: window.hostname+'api/admin/eliminar_profesional', method:'POST', data:{profesional_id: hid}})
      .then((response) => {
        this.getProfesionales();
        alert('Se eliminó el profesional.')
      })
      .catch((e)=>{
        alert('Error al eliminar el profesional.')
        console.log(e)
      })
    }

  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'prestador']),
  },
  mounted() {
    this.getProfesionales()
    this.$root.$on('recargar_profesionales', () => {
      this.getProfesionales();
});
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
  }

}
</script>


<style scoped>
.action-icon{

  font-size: 25px;
}
    .table-pro th {
      padding:15px 10px;
      font-weight: 700 !important;
    }
    .table-pro thead{
    
      color: rgba(0,0,0,0.5);

      border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    .table-pro img{
      width: 70px;
      
      border-radius: 100%;
      margin: 0 auto;
    }

    .table-pro td {
      padding:10px;
    }
    .table-pro tr:hover {
      background-color: rgba(0,0,0,0.01);
    }
.padded-h{
  padding-left: 20px;
  padding-right: 20px;
}
td{
  text-align: left;
}
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;



  
  margin: 0 auto;




}
.boxy{
  background-color: white;

  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{

  background-color: rgb(242, 247, 251);
  padding-bottom:40px;
  padding-top:0px;
  padding-left:275px;
  width:100%;

  height: 100%;
  

}
.main-container{
  
  padding: 10px;
  height: 100%;
  margin-top:10px;
  

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

