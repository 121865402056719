<!-- eslint-disable -->
<template>
<div>
<transition name="zoom-m">
   
  <div v-if="showing" id="modal-receta-m" >
    
  <div v-on:click="hide();" class="contenedor-icono">       
    <b-icon class="icon" icon="x"></b-icon>
  </div>
  <h2> Prestadores</h2>
  <div class="main-m">

<div class="filtro">

   
   <table style="width:100%; margin: 0px 0px; text-align: left;">
                <tr style="font-weight: 700;">
                  <td>Id</td>
                  <td>Nombre</td>
                  <td style="width: 80px;">¿Atiende?</td>
                </tr>
                <tr v-for="prestador in prestadores">
                  <td style="padding:5px;">
                    {{ prestador.id }}
                  </td>
                  <td style="padding:5px;">
                    {{ prestador.nombre }}
                  </td>
                  <td style="text-align:center; padding:5px;">
                    <input
                      v-model="prestador.activo"
                      type="checkbox"
                    >
                  </td>
                </tr>
              </table>

</div>
<button 
                    class="menuCol btn-ir-gordo"
                    style="padding: 5px 10px; margin: 0 auto; margin-top:10px;"
                    v-on:click="post_atencion_profesional_prestador()"
                    :style="{'background-color':prestador.admin.assets.colors.primary}"> 
                    Guardar
                    <b-icon icon="check"></b-icon>
                  </button>



  </div>

  </div>


</transition>
<div v-if="showing" class="background-screen"></div>
</div>

</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'ModalGenerico',
  components: {

  },
  props: {
   profesional_id:Number,
  },
  data(){
    return {
      showing:false,
      estados : ['Anulado', 'Citado',  'Confirmado', 'Atendido', 'No Atendido'],
      prestadores_activos: [],
      prestadores: [],
    }
  },
  methods:{

   send_to(route){      
      document.body.style.position = '';
      document.body.style.top = '';
      this.$router.push({path:route})
      this.hide();
    },
    show(){
      this.showing = true;
      /*document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.top = `-${window.scrollY}px`;
      */
    },
    hide(){
      this.showing = false;
     /* document.body.style.position = '';
      document.body.style.top = '';
      */
    },
    get_atencion_profesional_prestador(){
      axios.get(window.hostname+`/api/admin/atencion_profesional_prestador/${this.profesional_id}`)
        .then( (resp) => {
          this.prestadores = resp.data.prestadores;
          this.prestadores_activos = resp.data.prestadores_activos;
          this.prestadores.forEach( (prestador) => {
            if (this.prestadores_activos.includes(prestador.id)) {
              prestador.activo = true;
            } else {
              prestador.activo = false;
            }
          });
          console.log(this.prestadores);
        })
        .catch( (e) => { 
          this.$notify({duration:5000, title: 'Error!', text: e.response.data.message, type: 'error'})
        })
    },
    post_atencion_profesional_prestador() {
      let prestadores_activos = [];
      this.prestadores.forEach( (prestador) => {
        if (prestador.activo) {
          prestadores_activos.push(prestador.id);
        }
      });
      this.prestadores_activos = prestadores_activos;
      axios.post(window.hostname+`/api/admin/atencion_profesional_prestador/${this.profesional_id}`, {prestadores_activos})
        .then( (resp) => {
          this.$notify({duration:5000, title: 'Prestador actualizadors', text: '¡Se han realizado los cambios con éxito!', type: 'success'});
          this.get_atencion_profesional_prestador();
        })
        .then( () => alert('Se han realizado los cambios con éxito!'))
        .catch( (e) => { 
          this.$notify({duration:5000, title: 'Error!', text: e.response.data.message, type: 'error'});
          alert('Error al actualizar los prestadores');
        })
    },

  },
  mounted(){
   this.get_atencion_profesional_prestador();
  },
  computed:{
   ...mapGetters([
    'prestador',
    'comunas',
    'comunas_usadas',
    'categorias',
    'costo_servicio_pendiente',
    'examenes',
    'prestador_id',
    'categorias_selected',
    'prestador',
    ]),




  },
  watch:{
  
  },
}
</script>


<style scoped>
.btn-sig{
  border: none;
  padding: 4px 10px;
  border-radius: 4px;
  color:#fff;
}
.btn-sig:hover{
  opacity: 0.8;
}
.button-modal-m{
   padding:10px 40px;
   border: 2px solid rgba(0, 0, 0, 1);
   color: rgba(0, 0, 0, 1);
   font-weight: 600;
   display: inline-block;
   width: 300px;
   margin:10px;
   border-radius: 3px;
   cursor: pointer;
}
.button-modal-m:hover{
   background-color: rgba(0, 0, 0, 1);
   transition: 0.3s;
   color:white;
   box-shadow: rgba(0, 0, 0, 0.45) 0px 2px 10px 3px;
}
.background-screen{
  height: 100vh;
  width: 100vw;
  position: fixed;
 
/*   backdrop-filter: blur(2px); */
  z-index: 9999;

}
.titulo1{
  margin-bottom:30px;
  color: rgba(0, 0, 0, 1) !important;
}
.texto{
  text-align: center;
  padding: 0px 10px;
}
.main-m{
   display: flex;
   flex-wrap: wrap;
  width: 100%;
  text-align: center;
  padding: 10px;
  gap:10px;
}
.filtro{
   width: 100%;
   background-color: rgb(250, 250, 250);
   border-radius: 15px;
}
.greeny{
  color:#FF8C00;
  cursor:pointer;
  text-decoration: underline;
}
.subtitulo2{
  color:#FF8C00;
}
#modal-receta-m{
   transition: 0.5s;
   position:fixed;



   overflow-y:auto; 
   border-radius:10px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:10px; 
   background:white; 
   top: 50%;
   left:0px;
    transform: translateY(-50%);
  
   width:400px;
   max-width:90%;
   margin: 0px auto; 
   right: 0;
   opacity:1;
   z-index:99999;
}
.flaco{
   width:600px !important;
   left: 10% !important;
   transform: translate(-10%, -50%) !important;
}
#modal-receta-m h2{
     text-align: center;
     font-weight: bold;
     color:rgba(0,0,0,0.6);
     margin-top:20px;
}
#modal-receta-m h4{

  color:rgb(0, 0, 0) !important;
  margin-bottom:15px;
  margin-top:15px;
  font-size: 20px;
  font-weight: 700;
}
#modal-receta-m h5{
  text-align: center;
  vertical-align: middle;
  color:rgba(0, 0, 0, 0.3) !important;
 
}
.cont{
  text-align: center;
  vertical-align: middle;
  position:absolute;
   width:Calc(100% - 40px);
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
#modal-receta-m .icon{
   height:40px;
   width:40px;
   color:white;
}
#modal-receta-m .contenedor-icono{
   cursor:pointer;
   position:absolute;
   right:20px;
   top:20px;
   height:40px;
   width:40px;
   background-color: rgba(0, 0, 0, 1);
   border-radius:50px;
   z-index: 100001;
}
#modal-receta-m .subtitulo{
   text-align: center;
   font-size:22px;
   margin-bottom:40px;
   margin-top:20px;
   color:rgb(181, 181, 181);
   font-weight:350;
}
#modal-receta-m .input-wrapper{
   padding:0px 5px;
}
#modal-receta-m .btn-examinar{
   background-color:#FF8C00;
   padding:8px 20px;
   font-size:14px;
   font-weight:600;
   width:200px;
   max-width:Calc(100vw - 80px);
   color:white !important;
   outline: none;
   margin-top:20px;
}
#modal-receta-m .draggable-container{
   padding: 15px 5px 20px 5px;
   max-width: 600px;
   margin: 0 auto;
   background-color: rgb(247, 247, 247);
   border-style:dashed;
   border: 2px dashed rgb(200, 200, 200);
   border-radius:5px;
}
#modal-receta-m .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white;
   z-index: 999;
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 10px); 
   border: 1px solid rgb(200, 200, 200);
}
#modal-receta-m .placeholder-c{
   position:absolute; top:33px; left:20px;
}
#modal-receta-m .receta-label-mobile{
   display: none;
}
#modal-receta-m .btn-enviar{
   margin:0 auto; 
   cursor:pointer; 
   background-color:rgba(255, 179, 25, 1);
   max-width:300px; 
   height:45px; 
   color:white; 
   font-size:18px; 
   border-radius:4px;
   vertical-align: middle;
   font-weight:500;
}
#modal-receta-m .btn-enviar-d{
   margin:0 auto; 
   cursor:pointer; 
   background-color:rgba(255, 179, 25, 0.4);
   max-width:300px; 
   height:45px; 
   color:white; 
   font-size:18px; 
   border-radius:4px;
   vertical-align: middle;
   font-weight:500;
}
#modal-receta-m .upload-img{
   height:70px;
   width:70px;
   margin:0 auto; 
   margin-bottom:40px;
   margin-top:30px;
}
#modal-receta-m .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:50%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding-right:7px;
}
#modal-receta-m .comuna{
   position:relative;
}
#modal-receta-m .input-container{
   max-width:610px;
   margin: 0 auto;
}
@media only screen and (max-width: 1000px) {
   #modal-receta-m .btn-enviar{
   margin:0 auto; 
   margin-top:30px; 
   cursor:pointer; 
   background-color:rgba(103,30,117,1);
   max-width:100%;
   margin: 20px 20px; 
   height:55px; 
   line-height:55px;
   color:white; 
   font-size:18px; 
   border-radius:4px;
   font-weight:500;
}
#modal-receta-m .btn-examinar{
   padding:10px 20px;
   font-size:18px;
   font-weight:600;
   width:300px;
   max-width:Calc(100vw - 80px);
   color:white !important;
}
#modal-receta-m .receta-label-mobile{
   display: inline-block;
}
#modal-receta-m .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:100%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding:5px 20px;
 color:grey;
 
}
#modal-receta-m .placeholder-c{
   position:absolute; top:33px; left:30px;
}
#modal-receta-m .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white; 
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 40px); 
}
#modal-receta-m .receta-label-desk{
   display: none;
}
#modal-receta-m .draggable-container{
   border:none;
   background: none;
}
#modal-receta-m .contenido{
   z-index: 999999;
   position:relative;
      overflow-y:scroll !important;
   min-height:Calc(100vh);
   -webkit-overflow-scrolling: touch;
}
#modal-receta-m{
   
   position:fixed !important;
   height:Calc(100vh) !important;
   border-radius:0px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:0px; 
   background:white; 
   top:0px !important;
   width: 100vw !important;
   transform: none !important;
   max-width:100%;
   max-height: 100%;
   margin: 0px auto !important; 
   left: 0px !important;
   right: 0px !important;
   opacity:1;
   z-index:90001;
}
.primary{
   display: block;
}
.texto{
  text-align: center;
}
}

.zoom-m-enter-active,
.zoom-m-leave-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: zoom-m;
}

.zoom-m-leave-active {
  animation-direction: reverse;
}

@keyframes zoom-m {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
</style>
