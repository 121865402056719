import axios from 'axios';

const state = {

    aseguradoras:[],
    precios_examen_prestador_categoria_cliente:[],
    costo_servicio_categoria_cliente:[],
    categorias:[],
    categorias_usadas:[],
    descuentos:[],
    comunas:[],
    regiones:[],
    examenes:[],
    comunas_usadas:[],
    prestador:{},
    costo_servicio:0,
    costo_servicio_pendiente:0,
    costo_servicio_asegurador:{},
    costo_servicio_comuna:{},
    descuento_costo_servicio_asegurador_comuna:{},
    prestador_id:0,
    config_id:null,


    sideMenu:false,

    hasCoords: false,
    myCoords: {
        lat:0,
        lng:0
    },

};

const getters = {
    sideMenu: state => state.sideMenu,
    
    aseguradoras: state => state.aseguradoras,
    descuentos: state => state.descuentos,
    comunas: state => state.comunas,
    comunas_usadas: state => state.comunas_usadas,
    examenes: state => state.examenes,
    instituciones: state => state.instituciones,
    regiones: state => state.regiones,
    tipos: state => state.tipos,
    hasCoords: state => state.hasCoords,
    myCoords: state=> state.myCoords,
    costo_servicio_asegurador: state=> state.costo_servicio_asegurador,
    costo_servicio_comuna: state=> state.costo_servicio_comuna,
    prestador_id: state=> state.prestador_id,
    prestador: state=> state.prestador,
    costo_servicio: state=> state.costo_servicio,
    costo_servicio_pendiente: state=> state.costo_servicio_pendiente,
    categorias: state=> state.categorias,
    categorias_usadas: state=> state.categorias_usadas,
    config_id: state=> state.config_id,
    precios_examen_prestador_categoria_cliente: state=> state.precios_examen_prestador_categoria_cliente,
    costo_servicio_categoria_cliente: state=> state.costo_servicio_categoria_cliente,
    descuento_costo_servicio_asegurador_comuna: state=> state.descuento_costo_servicio_asegurador_comuna,
};

const actions = {
    CambiarMenuAction({commit}, payload){
        commit('CambiarMenu', payload)        
    },


    setCoords({commit}, payload){
        commit('setCoordinates', payload)        
    },


    async initialize({commit}, data){
        let resp;
        let done = false;
        while (!done){
            try{
                resp = await  axios({url:window.hostname+'/api/initialize', method: 'POST', data:data})
                commit('setData', resp.data)
                done = true;
            }
            catch (e){
                console.log(e)
            }
        }
    },


    async reconfigure({commit}, data){
        let resp;
        let done = false;
        while (!done){
            try{
                resp = await  axios({url:window.hostname+'/api/initialize', method: 'POST', data:data})
                commit('setDataReconfig', resp.data)
                done = true;
            }
            catch (e){
                console.log(e)
            }
        }
    },

    async recargar({commit}, data){
    axios({url:window.hostname+'/api/initialize', method: 'POST', data:data}).then(r=>{
        commit('setData', r.data)
    }).catch((r)=>{
        console.log(r)
    })

  


    }
};



const mutations = {
    CambiarMenu(state){
        state.sideMenu = !state.sideMenu;
    },
    setData: (state, data) => {
        state.aseguradoras = data.aseguradoras;
        state.comunas = data.comunas;
        state.comunas_usadas = data.comunas_usadas;
        state.examenes = data.examenes;
        state.tipos = data.tipos;
        state.regiones = data.regiones;
        state.instituciones = data.instituciones;
        state.costo_servicio = data.costo_servicio;
        state.descuentos = data.descuentos;
        state.costo_servicio_asegurador = data.costo_servicio_asegurador;
        state.costo_servicio_comuna = data.costo_servicio_comuna;
        state.prestador = data.prestador
        state.categorias = data.categorias
        state.categorias_usadas = data.categorias_usadas
        state.costo_servicio_pendiente = data.costo_servicio_pendiente;
        state.costo_servicio_categoria_cliente = data.costo_servicio_categoria_cliente;
        state.precios_examen_prestador_categoria_cliente = data.precios_examen_prestador_categoria_cliente;
        state.descuento_costo_servicio_asegurador_comuna = data.descuento_costo_servicio_asegurador_comuna;
    },
    setDataReconfig: (state, data) => {
        state.aseguradoras = data.aseguradoras;
        state.comunas = data.comunas;
        state.comunas_usadas = data.comunas_usadas;
        state.examenes = data.examenes;
        state.tipos = data.tipos;
        state.regiones = data.regiones;
        state.instituciones = data.instituciones;
        state.costo_servicio = data.costo_servicio;
        state.descuentos = data.descuentos;
        state.costo_servicio_asegurador = data.costo_servicio_asegurador;
        state.costo_servicio_comuna = data.costo_servicio_comuna;
        state.categorias = data.categorias
        state.categorias_usadas = data.categorias_usadas
        state.costo_servicio_pendiente = data.costo_servicio_pendiente;
        state.costo_servicio_categoria_cliente = data.costo_servicio_categoria_cliente;
        state.precios_examen_prestador_categoria_cliente = data.precios_examen_prestador_categoria_cliente;
        state.descuento_costo_servicio_asegurador_comuna = data.descuento_costo_servicio_asegurador_comuna;
    },
    setCoordinates: (state, data) =>{
        state.hasCoords = true;
        state.myCoords = data;
    }

};

export default{
    state,
    getters,
    actions,
    mutations,
};