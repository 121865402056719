<template>
  <!-- eslint-disable -->
  <div>
    <button
      class="btn-ir-gordo"
      :style="{'background-color':prestador.admin.assets.colors.primary}"
      @click="openModal"
    >
      Carga Masiva 
      <b-icon icon="upload" />
    </button>

    <!-- Modal -->
    <div
      v-if="showModal"
      class="modal-upload"
    >
      <div v-if="!loading" class="modal-content">
        <span
          class="close"
          @click="closeModal"
        >&times;</span>
        <h2>Cargar Masivo</h2>
        <br/>
        <button
          :style="{'background-color':prestador.admin.assets.colors.secondary}"
          class="btn btn-dark"
          style="margin-bottom: 10px;"
          @click="DownloadExamsExcel2()"
        >
          Descargar planilla tipo
          <b-icon
          style="margin-left: 10px;"
          icon="download"
          />            

        </button>
        <button
          :style="{'background-color':prestador.admin.assets.colors.primary}"
          class="btn btn-dark"
          style="margin-bottom: 10px;"
          @click="$refs.fileMasivo.click()"
        >
          {{ selectedExcel ? selectedExcel.name : 'Subir archivo' }}
        </button>
        <input
          id="file-masivo"
          ref="fileMasivo"
          type="file"
          style="display: none;"
          accept=".xlsx, .xls"
          @change="handleFileUpload"
        >
        <button
          v-if="selectedExcel"
          class="btn btn-dark"
          @click="submitFile"
        >
          Iniciar Carga Masiva
          <b-icon icon="play"></b-icon>
        </button>
      </div>
      <div v-else class="modal-content">
        <span
          class="close"
          @click="closeModal"
        >&times;</span>
        <div style="text-align: center;">
          <Loader :height="100" :width="100" />
        Esto puede tomar varios minutos, por favor, <b>no refrescar</b> la página...
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import fetchMultipartData from '@/helpers/fetchMultipartData.js'
import Loader from '@/components/Loader.vue';
import {mapGetters, mapActions} from 'vuex';
import axios from 'axios';

export default {
  components: {
    Loader,
  },
  data() {
    
    return {
      showModal: false,
      selectedExcel: null, // store the selected Excel file
      loading:false,
    };
  },
  methods: {
    ...mapActions(['recargar']),
    DownloadExamsExcel2(){
      this.loading = true;
      axios({
        url: window.hostname+'api/admin/descargar_excel_estandar',
        method: 'GET',
        responseType: 'blob',
      })
      .then( (response) =>  {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'planilla_tipo_clinicgo.xlsx'); 
        document.body.appendChild(link);
        link.click();          
        this.loading = false;
        this.this.recargar({prestador_id:this.prestador.id})
      })
      .catch( (e) => {
        this.loading = false;
        console.log(e);
      })
    },
    submitFile() {
      // Create a FormData instance
      const formData = new FormData();
      // Append the selected file to the FormData instance
      formData.append('file', this.selectedExcel);

      // Replace 'your_endpoint' with your actual Flask endpoint URL
      // and ensure you include the correct 'prestador_id' in the URL
      this.loading = true;
      axios.post(window.hostname+'api/admin/subir_precios_excel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        console.log('Success:', response);
        this.loading = false;
        alert('¡Se ha cargado exitosamente!');
      })
      .catch(error => {
        console.error('Error:', error);
        this.loading = false;
        alert('Ocurrió un error en la carga.');
      });
    },

    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedExcel = null;
    },
    handleFileUpload(event) {
      this.selectedExcel = event.target.files[0];
    },
    async uploadExcel() {
      if (this.selectedExcel) {
        const formData = new FormData();
        formData.append("excel", this.selectedExcel);

        await fetchMultipartData('/api/admin/uploadExcel', formData)
          .then((data) => {
            console.log("Response:", data);
            // Handle the response data as needed
          })
          .catch(error => {
            console.error("Error:", error);
            // Handle any errors that occurred during the request
          });

        this.closeModal();
      }
    },
  },
  computed:{
    ...mapGetters(['prestador']),
  },
};
</script>

<style>
/* Styles for the modal */
.modal-upload {
  display: inline;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  max-width: 400px;

}
.modal-content h2{
  text-align: center;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
