<template>
  <div>
    <TopNav />
    <div class="container-fluid">
      <div v-if="loaded">
        <div style="background-color:rgb(252,252,252);">
          <div
            class="container"
            style=" text-align:left; padding-bottom:120px; "
          >
            <h2 style="margin-bottom:30px;">
              Datos de Pacientes
            </h2>

            <div>
              <div
                v-for="p, i in pacientes"
                style="margin-bottom:20px; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; background-color:white; "
              >
                <div
                  v-b-toggle="'accordion-'+(i+1)"
                  style="width:100%; border-bottom:1px solid rgb(214, 214, 214);  padding:15px 15px 15px 30px; background:white;" 
                  @click="examen_buscado = ''; agregando_examen = false;"
                > 
                  <b-icon icon="person" /> 
                  {{ p.nombre != '' ? p.nombre+' '+p.apellido1 + ' ' + p.apellido2: 'Paciente '+(i+1) }}
                  <span style="float:right; margin-right:10px;">
                    <b-icon
                      style="transition:0.2s;"
                      :style="{'transform': p.visible ? 'rotate(180deg)': 'none'}"
                      icon="chevron-down"
                    />          
                  </span>
                </div>

                <b-collapse
                  :id="'accordion-'+(i+1)"
                  v-model="p.visible"
                  accordion="my-accordion"
                  class="main-c"
                >
                  <div style="border-radius: 0px 0px 5px 5px;">
                    <div
                      class="nombre"
                      style="display:inline-block; font-size:13px; padding-right:7px; margin-top:0px; padding-top:0px;"
                    >
                      Nombre <input
                        id=""
                        v-model="p.nombre"
                        class="form-control"
                        type="text"
                        name=""
                        @focus="focused_nombre = true;"
                        @blur="focused_nombre = false"
                        @input="p.nombre = $event.target.value"
                      >
                    </div>
                    <div
                      class="apellido1"
                      style="display:inline-block; font-size:13px; padding:4px; margin-top:0px; padding-top:0px;"
                    >
                      Apellido Paterno <input
                        id=""
                        v-model="p.apellido1"
                        class="form-control"
                        type="text"
                        name=""
                        @focus="focused_apellido1 = true;"
                        @blur="focused_apellido1 = false"
                        @input="p.apellido1 = $event.target.value"
                      >
                    </div>
                    <div
                      class="apellido1"
                      style="display:inline-block; font-size:13px; padding:4px; margin-top:0px; padding-top:0px;"
                    >
                      Apellido Materno<input
                        id=""
                        v-model="p.apellido2"
                        class="form-control"
                        type="text"
                        name=""
                        @focus="focused_apellido2 = true;"
                        @blur="focused_apellido2 = false"
                        @input="p.apellido2 = $event.target.value"
                      >
                    </div>
                    <div
                      class="telefono"
                      style="position:relative; z-index:99; width:33%; position:relative; display:inline-block; font-size:13px; padding:4px; vertical-align:bottom;"
                    >
                      <div>
                        Rut o identificador
                        <span
                          v-if="!verificar_rut(p.rut) && p.rut != '' && !focused_rut && p.tipo_identificador == 'rut'"
                          style="color:red; float:right; height:0px;"
                        >Formato Incorrecto  </span>
                      </div>
                      <div
                        class="ident_prefico" 
                        :style="{ 'border': !verificar_rut(p.rut) && p.rut != '' && !focused_rut && p.tipo_identificador == 'rut' ? '1px solid rgb(217,83,79)' :'1px solid rgb(210,210,210)'}"
                        style="height:38px; position:relative; float:left; cursor:pointer; display:inline-block; border-radius:5px 0px 0px 5px; padding:4px 4px 8px 8px; vertical-align:top;"
                        @click="p.seleccionando_rut = !p.seleccionando_rut"
                      >
                        <div
                          class="ident_prefico"
                          style="display:inline-block; vertical-align:middle; padding-left:7px; padding-right:15px;"
                        >
                          {{ tipo_identificador.filter(x=>{return p.tipo_identificador == x.valor}).length > 0 ? tipo_identificador.filter(x=>{return p.tipo_identificador == x.valor})[0].nombre :'' }}
                        </div>
                        <b-icon
                          class="ident_prefico"
                          style="position:absolute; top:12px; right:4px; cursor:pointer; height:12px; width:12px;"
                          icon="caret-down"
                        />
                      </div>
                      <div style=" float: none; overflow: hidden;">
                        <input
                          id=""
                          v-model="p.rut"
                          :class="{'is-invalid':!verificar_rut(p.rut) && p.rut != '' && !focused_rut && p.tipo_identificador == 'rut'}"
                          style="border-left:0px; display:inline-block; border-radius: 0px 5px 5px 0px; width:100%;"
                          class="form-control" 
                          type="text"  
                    
                          name=""
                          @focus="focused_rut = true;"
                          @blur="focused_rut = false"
                          @input="p.rut = $event.target.value"
                        >
                      </div>
                      <div
                        v-if="p.seleccionando_rut"
                        class="ident_prefico"
                        style="position:absolute; overflow-y: scroll; max-height:200px; top:60px; background:white; border-radius:5px; border: 1px solid rgb(210,210,210); padding:5px 0px;"
                      >
                        <div
                          v-for="t in tipo_identificador"
                          class="ident_prefico"
                          style="padding:6px 10px; cursor:pointer; "
                          @click="p.tipo_identificador = t.valor; p.seleccionando_rut = false;"
                        >
                          <div style="display:inline-block; vertical-align:middle;">
                            <div style="display:inline-block; padding:0px 5px 0px 5px;">
                              {{ t.nombre }} {{ t.desc }}
                            </div>
                            {{ t.nom }} 
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="telefono"
                      style="width:33%; display:inline-block; font-size:13px; padding:4px; vertical-align:top;"
                    >
                      Sexo Biológico
                      <select
                        v-model="p.sexo"
                        class="selector form-select"
                        style="display:block; width:100%"
                      >
                        <option value="f">
                          Femenino
                        </option>
                        <option value="m">
                          Masculino
                        </option>
                      </select>
                    </div>
                    <div
                      class="email"
                      style=" padding:4px; width:33%; vertical-align:top; display:inline-block; font-size:13px; padding-right:7px;"
                    >
                      Nacionalidad <input
                        id=""
                        v-model="p.nacionalidad"
                        class="form-control"
                        type="text"
                        name=""
                        @input="p.nacionalidad = $event.target.value"
                        @focus="focused_nacionalidad = true;"
                        @blur="focused_nacionalidad = false"
                      >
                    </div>


                    <div
                      class="email"
                      style="width:33%; display:inline-block; font-size:13px; padding-right:7px;"
                    >
                      Email <input
                        id=""
                        v-model="p.email"
                        :class="{'is-invalid':!verificar_correo(p.email) && !focused_email}"
                        class="form-control"
                        type="text"
                        name=""
                        @input="p.email = $event.target.value"
                        @focus="focused_email = true;"
                        @blur="focused_email = false"
                      ><span
                        v-if="!verificar_correo(p.email) && !focused_email"
                        style="color:red; float:right; height:0px;"
                      >Formato Incorrecto </span>
                    </div>
                    <div
                      class="telefono"
                      style="width:33%; display:inline-block; font-size:13px; padding:4px;"
                    >
                      Teléfono <input
                        id=""
                        v-model="p.telefono"
                        class="form-control"
                        type="text"
                        name=""
                        @focus="focused_telefono = true;"
                        @blur="focused_telefono = false"
                        @input="p.telefono = $event.target.value"
                      >
                    </div>

                    <div
                      class="fechan"
                      style="width:33%; display:inline-block; font-size:13px; padding:4px; vertical-align:top;"
                    >
                      Fecha de nacimiento 
                      <div style="display:block;">
                        <select
                          v-model="p.fecha_nacimiento_d"
                          class="selector form-select"
                          style="width:55px"
                        >
                          <option
                            v-for="x in getDaysInMonth(p.fecha_nacimiento_m, p.fecha_nacimiento_a)"
                            :value="x"
                          >
                            {{ x }}
                          </option>
                        </select>
                        <select
                          v-model="p.fecha_nacimiento_m"
                          class="selector form-select"
                        >
                          <option
                            v-for="x, y in meses"
                            :value="y+1"
                          >
                            {{ x }}
                          </option>
                        </select>
                        <select
                          v-model="p.fecha_nacimiento_a"
                          class="selector form-select"
                          style="width:75px"
                        >
                          <option
                            v-for="x in 120"
                            :value="new Date().getFullYear()-x+1"
                          >
                            {{ new Date().getFullYear()-x+1 }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      class="isapre"
                      style="width:33%; display:inline-block; font-size:13px; padding:4px; height:90px; vertical-align:top;"
                    >
                      Aseguradora 
                      <select
                        v-model="p.asegurador"
                        class="selector form-select"
                        style="display:block; width:100%"
                      >
                        <option
                          v-for="a in aseguradoras"
                          :value="a.id"
                        >
                          {{ a.nombre }}
                        </option>
                      </select>
                    </div>

                    <div class="examen-paciente-container">
                      <div style="width:100%; padding:20px; margin:0 auto; ">
                        <h5>Exámenes de {{ p.nombre != '' ? p.nombre : 'Paciente '+(i+1) }}</h5>
                      </div> 
                      <div style="margin-top:-10px; padding:0px; font-size:14px; position:relative; padding-bottom:40px;">
                        <div
                          v-for="e in p.examenes"
                          class="exam-list"
                          style=""
                        >
                          <b-icon
                            style="color: rgb(108, 193, 252); cursor:pointer;"
                            icon="x-circle"
                            @click="removeExamen(i, e)"
                          /> {{ examenes.some(z=>{return z.id == e.id}) ? examenes.filter(x=>{return x.id == e.id})[0].nombre : "INDEFINIDO" }}
                        </div>
                        <div
                          v-if="agregando_examen"
                          class="dont-close-exam"
                          style="position:relative; margin-top:10px;"
                        >
                          <div style="width:Min(100%, 400px); position:relative;">
                            <input
                              v-model="examen_buscado"
                              type="text"
                              class="form-control input-buscar-examen"
                              placeholder="Escribe un examen"
                              @input="examen_buscado = $event.target.value"
                            >
                            <b-icon
                              class="buscar-examen-icon"
                              icon="search"
                            />
                          </div>
                          <div
                            v-if="resultado_fuse_examen.length>0"
                            class="listado-buscando-container dont-close-exam"
                          >
                            <div
                              v-for="ez, i2 in resultado_fuse_examen.slice(0, 10)"
                              v-if="'categorias_selected.some(x=>{return x == ez.item.categoria_id})'"
                              :style="{'border-bottom': i2+1 != Math.min(10, resultado_fuse_examen.length) ? '1px solid rgb(214,214,214)': 'none'}"
                              class="listado-buscando dont-close-exam"
                              @click="addExamen(i, ez.item)"
                            >
                              <b-icon
                                style="height:18px; width:18px; transform:translate(0px, 1px); color:rgb(108, 193, 252);"
                                :icon="p.examenes.some(x=>{return x.id == ez.item.id}) ? 'check': 'bag-plus'"
                              />


                              {{ ez.item.nombre }} 

                              <div
                                class="dont-close-exam"
                                style="position:absolute; border:2px solid rgb(20, 134, 231); color:rgb(20, 134, 231); padding:1px; border-radius:10px; right:5px; top:2px;"
                              >
                                ${{ Intl.NumberFormat('de-DE').format(ez.item.precio) }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          style="cursor:pointer; position:absolute; bottom:20px; left:40px;  margin: 0 auto; transform:translateY(50px); width:60px; height: 60px; border-radius: 40px; background-color:rgb(108, 193, 252); border:1px solid rgb(108, 193, 252);  "
                          @click="agregando_examen = !agregando_examen; editando_paciente = i"
                        >
                          <b-icon
                            icon="plus"
                            style="height:60px; width: 60px; transform:translate(-0px,-0px); color:white;"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="btn-agregar-examen-inverso"
                      style="width:170px; margin-bottom:0px;"
                      @click="removerPaciente(i);"
                    >
                      <b-icon icon="trash" /> Quitar paciente
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div
                style="width:280px; vertyi"
                class="btn-agregar-examen-inverso"
                @click="agregarPaciente()"
              >
                <!--style="border:1px solid rgb(20, 134, 231); border-radius:10px; padding:10px; color:rgb(20, 134, 231); cursor:pointer; "-->
                <b-icon
                  icon="person"
                  style=" height:20px; width:20px; margin-right:5px; margin-left:5px; transform:translate(0px, 2px);"
                /> <b-icon
                  icon="plus"
                  style="position:absolute;  transform:translate(-19px, -1px);"
                /> Agregar un nuevo paciente
              </div>
            </div>

            <div style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; background-color:white; padding:20px 25px 25px 25px; margin-top:20px;">
              <h5>Dirección</h5>
              <div class="dir1">
                <div style="width:200px; display:inline-block; font-size:13px; padding-right:7px;">
                  Calle y numero <input
                    id=""
                    v-model="direccion"
                    placeholder=""
                    class="form-control"
                    type="text"
                    name=""
                    @input="direccion = $event.target.value"
                    @focus="focused_direccion = true;"
                    @blur="focused_direccion = false"
                  >
                </div>
                <div style="display:inline-block; padding-right:7px;">
                  <div style="font-size:13px;">
                    Comuna
                  </div>
                  <div style="display:inline-block; width:100%; padding:6px; border-radius:5px; border: 1px solid rgb(220,220, 220); ">
                    <span style="opacity:0.6;">{{ comunas.some(z=>{ return z.id == comuna}) ? comunas.filter(x=>{return x.id == comuna})[0].Nombre : "Indefinida:"+comuna }}</span>
                  </div> 
                </div>
              </div>

              <div
                class="dir2"
                style="margin-top:20px;"
              >
                <div style="font-size:13px;">
                  Tipo
                </div>
                <button
                  style="margin-right:10px; transition:0s;"
                  :class="{'opcionClicked': depto==0, 'opcionNoClicked': depto==1}"
                  class="btn"
                  @click="depto = 0"
                >
                  Casa
                </button>
                <button
                  style="transition:0s;"
                  :class="{'opcionClicked': depto==1, 'opcionNoClicked': depto==0}"
                  class="btn"
                  @click="depto = 1"
                >
                  Departamento
                </button>
              </div>
              <div
                class="dir3"
                style="margin-top:20px;"
              >
                <div
                  v-if="depto == 1"
                  style="max-width:200px;"
                >
                  <div style="font-size:13px;">
                    Número de departamento
                  </div>
                  <input
                    id=""
                    v-model="n_depto"
                    placeholder="A32"
                    maxlength="8"
                    class="form-control"
                    type="text"
                    name=""
                    @focus="focused_n_depto = true;"
                    @blur="focused_n_depto = false"
                  >
                </div>
              </div>
              <div style="width:200px; display:inline-block; font-size:13px; padding-right:7px;">
                Descuento <input
                  id=""
                  v-model="descuento"
                  placeholder=""
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  class="form-control"
                  type="text"
                  name=""
                  @input="descuento = $event.target.value"
                >
              </div>
            </div>
              

            <div style="position:fixed; bottom:60px; left:0px; z-index:9999; width:100%;">
              <div
                class="container"
                style="position:relative;"
              >
                <div
                  style="position:absolute; right:0px;"
                  :style="{'opacity': alerta && !puedeContinuar ? '1':'0'}"
                  class="alerta alert alert-danger"
                >
                  <b-icon icon="exclamation-triangle" /> {{ puedeContinuarError }}
                </div>
              </div>
            </div>



            <div style=" left:0px; height:80px; bottom:0px; background:white; border-top:1px solid rgb(214,214,214); ">
              <div style="">
                <div style="position:relative">
                  <b> Valor Total: <span style="color:red;">${{ Intl.NumberFormat('de-DE').format(valorTotal) }}</span></b>
                  <button
                    style="font-size:14px;  position:absolute; right:10px; top:25px; padding:7px 4px;"
                    :class="{'btn-danger': !puedeContinuar, 'btn-success':puedeContinuar, 'glow':puedeContinuar}"
                    class="btn"
                    @mouseleave="alerta = false"
                    @mouseenter="alerta = true"
                    @click="continuar()"
                  >
                    Guardar <b-icon
                      style="transform:translateY(2px);"
                      icon="check-circle"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import TopNav from '@/components/TopNav.vue';


export default {
  name: 'HelloWorld',
  props:['id'],
  components:{
    TopNav
  },
  data() {
    return {

      loaded:false,
      pacientes:[],
      direccion:'',
      descuento:0,
      comuna:0,
      depto:0,
      n_depto:'',
      alerta:false,
      agregando_examen:false,
            focused_direccion:false,
      focused_apellido1:false,
      focused_apellido2:false,
      focused_nombre:false,
      focused_rut:false,
      focused_email:false,
      focused_telefono:false,
      focused_n_depto:false,
      focused_info_adicional:false,
      focused_nacionalidad:false,
      window: {
          width: 0,
          height: 0
      },
      meses:['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      fexamenes:null,
      resultado_fuse_examen:[],
      examen_buscado:'',
      categorias_selected:[],
      tipo_identificador:[
        {nombre:'Rut', valor:'rut', desc:'(RUT nacional)'},
        {nombre:'Pasaporte', valor:'pasaporte', desc:'(Pasaporte extranjero)'},
        {nombre:'DNI', valor:'dni', desc:'(DNI extranjero)'},
        ],

    }
  },
  created(){
  
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),

      getDaysInMonth(month,year) {
      return new Date(year, month, 0).getDate();
     },
    removeExamen(pos, ex){

      for(let i = 0; i < this.pacientes[pos].examenes.length; i++){
          if (this.pacientes[pos].examenes[i].id == ex.id){
              this.pacientes[pos].examenes.splice(i, 1)
              break;
          }
      }     
    },
      removerPaciente(i){
      if (this.pacientes.length > 1)
      {      
        this.pacientes.splice(i, 1);
      }
    },

    addExamen(pos, ex)
    {
      for (let i = 0; i < this.pacientes[pos].examenes.length; i++) {
        if(this.pacientes[pos].examenes[i].id == ex.id)
          return;
      }
      this.examen_buscado = ''      
      this.pacientes[pos].examenes.push(this.examenes.filter(x=>{return x.id == ex.id})[0])


    },

    continuar()
    {
      if (this.puedeContinuar)
      {
        axios.post(window.hostname+'/api/admin/modificar_orden', {
          orden_id:this.$route.params.id,
          orden:this.pacientes, 
          direccion:this.direccion, 
          descuento:this.descuento,
          depto:this.depto,
          n_depto:this.n_depto,
        })
          .then(r=>{
            this.$notify({
              group: 'foo',
              title: '¡Éxito!',
              text: '¡Se ha modificado la orden exitosamente!',
              type:'success'
            });
          })
          .catch(e=>{
            this.$notify({
              group: 'foo',
              title: '¡Error!',
              text: '¡Ha ocurrido un error!',
              type:'error'
            });
            console.log("Error al cargar...")
          })


      }
      else{
        this.$gtag.event('datospersonales-continuar', {'event_category': 'agendar', 'event_label': 'incorrecto', 'value': 1} );
      }
    },



    agregarPaciente(){
      this.pacientes.push({id:0, nombre:'', tipo_identificador:'rut', nacionalidad:'Chilena', sexo:'f',  fecha_nacimiento_a: 1991, fecha_nacimiento_m: 1, fecha_nacimiento_d: 1, asegurador:1, apellido1:'', apellido2:'', email:'', rut:'', telefono:'', tel_prefijo:'+56', seleccionando_telefono:false, seleccionando_rut:false, examenes:[]})

    },
    close(e){
      if (!(e.target.classList.contains('search-exam-popup') || e.target.classList.contains('dont-close-exam') ) && this.focused_exam)
      {
        this.focused_exam = false;
        this.examen_buscado = ''
      }
    },
      verificar_correo(c){
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(c != '')
        return re.test(c) ? true: false;
      return true;
    },
    dv(T){
        let M=0,S=1;
        for(;T;T=Math.floor(T/10))
          S=(S+T%10*(9-M++%6))%11;
        return S?S-1:'k';
    },

    verificar_rut(r)
    {
      function dv(T){
        let M=0,S=1;
        for(;T;T=Math.floor(T/10))
          S=(S+T%10*(9-M++%6))%11;
        return S?S-1:'k';
      }
    function validaRut (rutCompleto) {
        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
          return false;
        let tmp 	= rutCompleto.split('-');
        let digv	= tmp[1]; 
        let rut 	= tmp[0];
        if ( digv == 'K' ) digv = 'k' ;
          return (dv(rut) == digv ) ? true : false;
      }
      return validaRut(r)
    },
  
    
    initialize_(){
      if (typeof(this.$route.params.id === 'number'))
      {
        this.loaded = false;
        axios.post(window.hostname+'/api/admin/get_orden',
        {orden_id: this.$route.params.id})
        .then(response => 
        {
            this.loaded = true;
            this.orden = response.data.orden

            for(let i = 0; i < this.orden.clientes.length; i++){
              this.pacientes.push({
                id:this.orden.clientes[i].id,
                nombre:this.orden.clientes[i].nombre, visible: i == 0, 
                apellido1:this.orden.clientes[i].apellido1,
                apellido2:this.orden.clientes[i].apellido2,
                email:this.orden.clientes[i].email, 
                rut:this.orden.clientes[i].rut,
                sexo:this.orden.clientes[i].sexo_biologico,
                nacionalidad:this.orden.clientes[i].nacionalidad,
                asegurador:this.orden.clientes[i].asegurador,

                fecha_nacimiento_a:this.orden.clientes[i].fecha_nacimiento_a,
                fecha_nacimiento_m:this.orden.clientes[i].fecha_nacimiento_m,
                fecha_nacimiento_d:this.orden.clientes[i].fecha_nacimiento_d,   

                seleccionando_rut:false,
                tipo_identificador:this.orden.clientes[i].tipo_identificador,
                telefono:this.orden.clientes[i].telefono, 
                examenes:this.orden.examenes.filter(z=>{return z.cliente_id ==this.orden.clientes[i].id}),
              })
            }
            this.categorias_selected = this.orden.categorias
            this.direccion = this.orden.direccion
            this.comuna = this.orden.comuna_id
            this.descuento = this.orden.descuento
            this.n_depto = this.orden.n_depto
            this.depto = this.orden.departamento
        })

        .catch(e=>{
            this.loaded = true;
            console.log("Error al cargar...!")
        })
      }
    },


  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'aseguradoras', 'prestaciones', 'tipos', 'admin_datos', 'examenes', 'costo_servicio', 'costo_servicio_pendiente']),
      valorTotal(){
        let total = this.costo_servicio
        for (let z = 0; z < this.pacientes.length; z++){
            total += this.costo_servicio_pendiente
            for(let j = 0; j < this.pacientes[z].examenes.length; j++){
              total += this.pacientes[z].examenes[j].precio
            }
        }
        return total
      },
  puedeContinuarError(){
    for (let i = 0; i<this.pacientes.length; i++){
          
      if(!(
        this.pacientes[i].nombre.length > 1 &&
        ['f', 'm'].includes(this.pacientes[i].sexo) &&
        this.pacientes[i].email.length > 2 && 
        this.pacientes[i].apellido1.length > 1 &&
        this.pacientes[i].telefono.length > 6 &&
        this.pacientes[i].nacionalidad.length > 1 &&
        this.verificar_correo(this.pacientes[i].email) 
      )) {
        return 'Hay pacientes con información incompleta. (' + (this.pacientes[i].nombre.length > 1 ? this.pacientes[i].nombre : 'Paciente '+(i+1) )+').'
      }
      if (this.pacientes[i].examenes.length == 0)
        return ''
        // return [true, 'Hay pacientes sin exámenes. (' + (this.pacientes[i].nombre.length > 1 ? this.pacientes[i].nombre : 'Paciente '+(i+1) )+' no tiene).']
      if (!this.aseguradoras.some(z=> {return z.id == this.pacientes[i].asegurador}))
        return '' + (this.pacientes[i].nombre.length > 1 ? this.pacientes[i].nombre : 'Paciente '+(i+1) )+' no tiene aseguradora seleccionada.'
      if(!(this.pacientes[i].rut.length>4))
        return '' + (this.pacientes[i].nombre.length > 1 ? this.pacientes[i].nombre : 'Paciente '+(i+1) )+' debe ingresar rut o pasaporte (5 o mas caracteresa).'  
      if(!(this.verificar_rut(this.pacientes[i].rut) || this.pacientes[i].tipo_identificador != 'rut'))
        return '' + (this.pacientes[i].nombre.length > 1 ? this.pacientes[i].nombre : 'Paciente '+(i+1) )+' tiene que tener un rut o pasaporte valido.'
    }

    console.log(this.direccion)
    console.log(this.direccion.length)
    if (this.direccion.length < 3)
      return 'Falta agregar dirección.'

    return ''
    },
    puedeContinuar(){
      return this.puedeContinuarError == ''
    },
  },
  watch:{
    examen_buscado(val){
      this.resultado_fuse_examen = this.fexamenes.search(val)
      if(!this.focused_exam)
        this.focused_exam = true;
    },
    examenes(){
      this.initialize_()
    }
  },
  mounted() {
    this.fexamenes = new Fuse(this.examenes, {  keys: ['nombre', 'keywords']})
    document.addEventListener('click', this.close) 
  },
  beforeUnmount () {
    document.removeEventListener('click',this.close)
  },
  unmounted(){
    window.removeEventListener('resize', this.handleResize);
  }

}
</script>


<style scoped>

.selector {
  background-color:white;
  width: 130px;
  padding: 6px 6px;
  display: inline-block !important;
  margin: 0 10px 0 0px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size:16px;
  border:1px solid rgba(0,0,0,0.2);
  outline: none;
  color:#111111;
}
.dir1, .dir2, .dir3{
  display:inline-block;
  margin-right:40px;
  margin-top:0px !important;
}
.glow {
  color: #fff;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
  animation-iteration-count: 5;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #198754, 0 0 40px #198754, 0 0 50px #198754, 0 0 60px #198754, 0 0 70px #198754;
  }
  to {
    box-shadow: 0 0 20px #fff, 0 0 30px #198754, 0 0 40px #198754 0 0 50px #198754, 0 0 60px #198754, 0 0 70px #198754, 0 0 80px #198754;
  }
}
.spa{
  margin-top:100px;
}
.listado-buscando-container{
  z-index:9997; left:20px; top:35px; width:Min(400px, Calc(100vw - 100px)); position:absolute; background:white; border: 1px solid rgb(108, 193, 252); margin-top:12px; border-radius:20px;padding:15px 0px 10px 0px;
}
.listado-buscando{
   position:relative; cursor:pointer; margin:0 auto; width:100%; text-align:left; padding:5px 10px 5px 10px; 
}
.examen-paciente-container{
border: 1px solid rgb(214, 214, 214); padding-right:40px; border-radius:10px; margin-top:40px; margin-bottom:40px; background:rgba(20, 134, 231, 0.04);
}
.buscar-examen-icon{
  position:absolute; top:10px; right:-5px;
}
.exam-list{
  padding:8px 20px;
}
.input-buscar-examen{
  margin-left:20px; margin-right:20px; border-radius:20x;
}
.btn-agregar-examen{
  border:2px solid rgb(255, 200, 9); 
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:200px;
  background:rgb(255, 200, 9);
  color:white; 
  cursor:pointer;
  font-weight: 700;
  margin-bottom:10px;
}
.btn-agregar-examen:hover{
  border:2px solid rgb(255, 200, 9); 
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:200px;
  background:white;
  color:rgb(255, 200, 9); 
  cursor:pointer;
  font-weight: 700;
}

.btn-agregar-examen-inverso{

  border:2px solid rgb(108, 193, 252); 
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:190px;
  background:white;
  color:rgb(108, 193, 252);
  cursor:pointer;
  font-weight: 700;


}
.btn-agregar-examen-inverso:hover{
  border:2px solid rgb(108, 193, 252);
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:200px;
  background:rgb(108, 193, 252);
  color:white; 
  cursor:pointer;
  font-weight: 700;
  margin-bottom:10px;
}
.opcionClicked{
  border:2px solid rgb(108, 193, 252); 
  color:white;
  background: rgb(108, 193, 252);
}
.opcionClicked:hover{
  color:white;

}
.opcionNoClicked{
  border:2px solid rgb(108, 193, 252); 
  color:rgb(108, 193, 252);
}
.opcionNoClicked:hover{
  border:2px solid rgb(108, 193, 252); 
  color:white;
  background: rgb(108, 193, 252);
}
.parent {
  overflow: hidden; /* required */
  width: 50%; /* for demo only */
  height: 250px /* some non-zero number */;
  margin: 25px auto; /* for demo only */
  border:1px solid grey; /* for demo only */
  position: relative; /* required  for demo*/
}

.ribbon {
  margin: 0;
  padding: 0;
  background: rgb(108, 193, 252);
  color:white;
  padding:1em 0;
  position: absolute;
  top:0;
  right:0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  z-index: 99;
  transform-origin: top left;
}
.nombre, .apellido1, .rut{
  width:33%
}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top:0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background: rgb(108, 193, 252);
}
.ribbon:before {
  right:100%;
}
.alerta{
  padding:10px;
  background-color: #dc3545;
  color:white;
  position:absolute;
  
  right:3px;
  font-weight: 700;
  top: -41px;
  font-size: 12px;
  transition-duration: 0.2s;
}
.alerta:after{

  content:'';
  right:30px;
  top:33px;
  width: 10px;
  height: 10px;
  background: #dc3545;
  border-right: 1px solid #dc3545;
  border-bottom: 1px solid #dc3545;
  transform: rotate(45deg);
  position: absolute;
}

.ribbon:after {
  left:100%;
}
.bottom-spacer{
  height:0px;
}
.top-spacer{
  height:130px;
}
.carta{
  overflow: hidden; 
  position:relative; 
  background:white; 
  border:1px solid rgb(220, 220, 220); 
  padding: 40px; 
  border-radius:5px; 
  box-shadow:rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
@media only screen and (max-width: 540px) {
.nombre, .apellido1, .apellido2, .rut, .email, .telefono{
  width: 100% !important;
  margin:0px;
  padding:0px !important;
  display: block !important;;
}
.carta{
  padding: 10px; 
}
.email{
  margin-bottom: 20px;
}
.telefono, .apellido1, .rut{
  padding:0px !important;
}
.nombre, .apellido2, .apellido1{
  margin-bottom: 10px;
}

.ribbon {
  padding:4px 0;

  transform: translateX(40%) translateY(0%) rotate(45deg);

}




}
.main-c{
  padding:20px 30px;
}
@media only screen and (max-width: 1200px) {
  .top-spacer{
  height:85px;
}
.spa{
  margin-top:55px;

}
.bottom-spacer{
  height:60px;
}




}

@media (max-width: 576px) { 
  
  .main-c{
  padding:20px 10px;
}

.buscar-examen-icon{
  position:absolute; top:10px; right:-5px;
}
.exam-list{
  padding:8px 10px;
}
.input-buscar-examen{
  margin-left:10px; 
  margin-right:10px;
  width:100%;
  border-radius:10x;
}
.examen-paciente-container{
  padding-right:20px;
}
.listado-buscando{
   position:relative; cursor:pointer; margin:0 auto; width:100%; text-align:left; padding:5px 10px 5px 10px; 
}


.listado-buscando-container{
  z-index:9997; left:10px; top:35px; width: Min(400px, Calc(100% + 0px)); position:absolute; background:white; border: 1px solid rgb(108, 193, 252); margin-top:12px; border-radius:20px;padding:15px 0px 10px 0px;
}

}

@media (max-width: 768px) { 
  
 }

@media (max-width: 992px) { 
  .dir1, .dir2, .dir3{
  display:block;
  margin-right:40px;
  margin-top:20px !important;
}
 }

@media (min-width: 1200px) { 
  
 }

@media (min-width: 1400px) { 
  
 }




</style>

