<template>
  <!-- eslint-disable -->
<div  style="text-align:left; background-color: rgb(242, 247, 251);">
 
  
  <div class="column-left">
    <SideNav />
  </div>

  
  <div class="column-right">
    <TopNav />
    <div class="main-container">   
      
      <div v-if="loaded" class="articulo-principal">
        <h2>Descuento por aseguradora</h2>    
        <div class="boxy">
          <p style="font-style: italic;">
            *Desde esta pestaña puedes editar el descuento que se le mostrará al paciente segun la aseguradora que escoja en su orden. 
            Puedes editar los valores <b>individualmente con el lapiz</b> que está al lado de cada nombre de las prestaciones, o bien, <b>modificar una columna entera con el porcentaje</b> que está
            debajo del nombre de la aseguradora.
          </p>
          <br/>
          <table style="width:100%;">
          <thead>
            <td />
            <td v-for="a in aseguradoras">
              {{ a.nombre }}

              <div>
                <select
                  id=""
                  name=""
                  @change="(e)=>{editar_isapre_porcentaje(a.id, e.target.options.selectedIndex)}"
                >
                  <option
                    v-for="x in 11"
                    :value="(x-1)*10"
                  >
                    {{ (x-1)*10 }}%
                  </option>
                </select>
              </div>
            </td>
          </thead>
          <tr v-for="ase, i in editando_descuentos">
            <td style="width:20%;">
              {{ examenes[i].nombre }} (${{ Intl.NumberFormat('de-DE').format(examenes[i].precio) }})

              <i 
                v-if="!editando[i]"
                :style="{'color': prestador.admin.assets.colors.primary}" 
                @click="cambiarEditando(i)"
                class="menu-icon tf-icons ti icon-edit ti-pencil"
                ></i>
              <b-icon
                v-if="editando[i]"
                style="cursor:pointer; color:red;"
                icon="x-circle"
                @click="cambiarEditando(i)"
              />
              <b-icon
                v-if="editando[i]"
                style="cursor:pointer; color:green "
                icon="check-circle"
                @click="editar_descuento(); cambiarEditando(i);"
              />
            </td>
            <td v-for="ex, j in ase">
              <input
                v-if="editando[i]"
                v-model="editando_linea[j].valor"
                style="width:70px;"
                type="text"
              > <span v-else>{{ editando_descuentos[i][j].valor }}</span>
            </td>
          </tr>
        </table>
          
        </div>
      </div>

      <div v-else class="articulo-principal">
        <div style="height: calc(100vh - 140px); display: flex; justify-content: center; align-items: center;">
          <Loader :height="100" :width="100" />
        </div>
      </div>

    </div>
  </div>



</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  components:{
    TopNav,
    Loader,
    SideNav,
  },
  data() {
    return {

      loaded:false,
      editando_descuentos:[],
      modificados:[],
      editando:[],
      editando_linea:[],

    }
  },
  created(){
  
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),

      editar_descuento(){
    axios.post(window.hostname+'/api/admin/editar_descuentos',{descuentos:this.editando_linea})
        .then(response => 
        {
          this.initialize().then(()=>{
          this.initialize_();
          })
          
        })

        .catch(e=>{
            console.log("Error al cargar...")
        })
        
  },

  editar_isapre_porcentaje(ase_id, porcentaje)
  {
    let edit = []
    for(var i = 0; i < this.examenes.length; i++){
      edit.push({examen_id:this.examenes[i].id, aseguradora_id:ase_id, valor:Math.floor((porcentaje/10) * this.examenes[i].precio)})
    }


    axios.post(window.hostname+'/api/admin/editar_descuentos',{descuentos:edit})
    .then(response => 
    {
      this.initialize().then(()=>{
      this.initialize_();
      
      })
      
    })

    .catch(e=>{
        console.log("Error al cargar...")
    })
        
  },
  cambiarEditando(i){
    this.editando_linea = Array.from(this.editando_descuentos[i])
    this.editando[i] = !this.editando[i]

    this.$forceUpdate()
  },




    
  initialize_(){
      this.loaded = false;
      let row = []
      let col = []
      this.editando = []
      this.editando_descuentos = []
      /*
      for (var a of this.aseguradoras)
      {
        col = []
        for(var e of this.examenes){
          col.push({examen_id: e.id, aseguradora_id: a.id, valor:this.descuentos.find(z=>{return z.examen_id == e.id && z.aseguradora_id == a.id}) || 0})
        }
        row.push(col)
      }
      */
        for(var e of this.examenes){
          col = []
          for (var a of this.aseguradoras)
            {
              col.push(this.descuentos.find(z=>{return z.examen_id == e.id && z.aseguradora_id == a.id}) || {examen_id: e.id, aseguradora_id: a.id, valor:0})
            }
           row.push(col)
       }
      for(let z = 0; z < this.examenes.length; z++)
        this.editando.push(false)

      this.editando_descuentos = row
      this.loaded = true;
      this.$forceUpdate()

  },


  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'prestador', 'admin_datos', 'examenes', 'aseguradoras', 'descuentos']),
  },
  watch:{

  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
  }

}
</script>


<style scoped>
thead td{
  font-weight: 700;
}
td{
  text-align: left;
}
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;



  
  margin: 0 auto;




}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);

  padding-top:0px;
  padding-left:275px;
  width:100%;
  

}
.main-container{
  background-color: rgb(242, 247, 251);
  padding: 10px;

  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

