<!-- eslint-disable -->
<template>
<div>
<transition name="zoom-m">
   
  <div v-if="showing" id="modal-receta-m" >
    
  <div v-on:click="hide();" class="contenedor-icono">       
    <b-icon class="icon" icon="x"></b-icon>
  </div>
  <h2> Modificar Profesional</h2>
  <div class="main-m">

   <div id="userInfoForm" class="form-container">
        <div class="form-group">
            <label for="nombre">Nombre</label>
            <input v-model="nombre" type="text" id="nombre" name="nombre" required>
        </div>
        <div class="form-group">
            <label for="apellido">Apellido</label>
            <input v-model="apellido" type="text" id="apellido" name="apellido" required>
        </div>
        <div class="form-group">
            <label for="email">Email</label>
            <input v-model="email" type="email" id="email" name="email" required>
        </div>
        <div class="form-group">
            <label for="rut">RUT</label>
            <input v-model="rut" type="text" id="rut" name="rut" required>
        </div>
        <div class="form-group">
            <label for="rut">Empresa <i>(opcional)</i></label>
            <input v-model="empresa" type="text" id="rut" name="rut" >
        </div>
        <div class="form-group">

        </div>
        <div class="form-group">
            <div style="display: inline-block; ">
               <input multiple v-on:change="change_file()" :id="'filex'" :ref="'filex'"  type="file" accept="image/*" style="visibility:hidden; width:0px;" />
            <label  :style="{'background-color':prestador.admin.assets.colors.primary}" style="display: inline-block; padding: 5px 10px; border-radius: 4px; color:#fff; margin-right: 5px;" class="btn-subir"  :for="'filex'">Subir foto <b-icon icon="camera-fill"></b-icon></label>

            </div>
              <div style="margin-top: 10px; font-style: italic;" v-if="foto != null">*Archivo subido: <div style="display: inline-block;">{{ foto.name }}</div></div>
        </div>
        <button v-on:click="modificar_pro()" :style="{'background-color':prestador.admin.assets.colors.primary}">
         Guardar 
         <i class="action-icon tf-icons ti ti-device-floppy"></i>               
         <div v-if="loading" class="spinner-border spinner-border-sm" role="status"/> 
      </button>
        <button v-on:click="reestablecer_contraseña()" :style="{'background-color':prestador.admin.assets.colors.primary}">
         Reestablecer contraseña a
         <i class="action-icon tf-icons ti ti-lock"></i>               
         <div v-if="loading" class="spinner-border spinner-border-sm" role="status"/> 
      </button>
      </div>

  </div>

  </div>


</transition>
<div v-if="showing" class="background-screen"></div>
</div>

</template>

<script>
/* eslint-disable */

import {mapGetters, mapActions} from 'vuex';
import axios from 'axios';
import { error } from 'jquery';
export default {
  name: 'ModalGenerico',
  components: {

  },
  props: {
 
  },
  data(){
    return {
      showing:false,
      loading: false,
      profesional:{},
      foto:null,
      nombre:'',
      apellido:'',
      email:'',
      rut:'',
      empresa:'',
      error_archivo:false,
    }
  },
  methods:{
   change_file(){

 
      if (['jpg', 'png', 'jpeg'].includes(this.$refs['filex'].files[0].name.split('.').pop()))
      {
         this.foto = this.$refs['filex'].files[0]
      }
      else
      {
      this.error_archivo = true;
      }

      },

   handleCheckboxChange(d, nombre){
      this.$root.$emit('Filtros', {valor: d, nombre:nombre});
   },
   checked_(value, nombre){
      let filtro = this.filtros.find(z=>z.nombre == nombre)
      return !filtro.excluidos.includes(value)   

      
   },

   send_to(route){      
      document.body.style.position = '';
      document.body.style.top = '';
      this.$router.push({path:route})
      this.hide();
    },
    show(pro){
      if(!pro)
         return
      this.profesional = pro;
      this.showing = true;
      this.nombre = pro.nombre;
      this.apellido = pro.apellido;
      this.rut = pro.rut;
      this.email = pro.email;
      this.empresa = pro.atributos.empresa

    },
    hide(){
      this.showing = false;
      this.pro = {};
    },
    modificar_pro(){
      this.loading = true;
      const json_ob = JSON.stringify({
         profesional_id:this.profesional.id,
         nombre:this.nombre,
         apellido:this.apellido,
         email:this.email,
         rut:this.rut,
         empresa:this.empresa,
        })
        const blob = new Blob([json_ob], {type:'application/json'});
        let data = new FormData();
        data.append("document", blob)
        if (this.foto != null){
            data.append("imagen", this.foto)
        }
/*       axios.post(window.hostname+'/api/admin/modificar_pro', 
      {
         profesional_id:this.profesional.id,
         nombre:this.nombre,
         apellido:this.apellido,
         email:this.email,
         rut:this.rut,
      }) */
      axios({method:'post', url:window.hostname+'/api/admin/modificar_pro', data:data})
      .then(response => 
      {
         this.loading = false;
         this.$notify({
          group: 'foo',
          title: '¡Éxito!',
          text: response.data.message,
          type:'success'
          });
          this.showing = false;
          this.$root.$emit('recargar_profesionales');
      })
      .catch(e=>{
         this.loading = false;
         this.$notify({
          group: 'foo',
          title: '¡Error!',
          text: e.response.data.message,
          type:'error'
          });
        console.log(e)        
      })
  },
    reestablecer_contraseña(){
      this.loading = true;
      axios.post(window.hostname+'/api/admin/nuevo_password_profesional', 
      {
         profesional_id:this.profesional.id,
      })
      .then(response => 
      {
         this.loading = false;
         this.$notify({
          group: 'foo',
          title: '¡Éxito!',
          text: response.data.message,
          type:'success'
          });
          this.showing = false;
      })
      .catch(e=>{
         this.loading = false;
         this.$notify({
          group: 'foo',
          title: '¡Error!',
          text: e.response.data.message,
          type:'error'
          });
        console.log(e)        
      })
  }


  },
  mounted(){
  
  },
  computed:{
   ...mapGetters([
    'prestador',
    'comunas',
    'comunas_usadas',
    'categorias',
    'costo_servicio_pendiente',
    'examenes',
    'prestador_id',
    'categorias_selected',
    'prestador',
    ]),

    profesionales(){

      let uniqueValues = this.ordenes.map((obj) =>  { return {id: obj.profesional.id, nombre:obj.profesional.nombre, apellido: obj.profesional.apellido}})
                                .filter((value, index, self) => self.findIndex(obj => obj.id == value.id) === index);
      return uniqueValues;
    },
    prestadores_tomador(){
      let uniqueValues = this.ordenes.map(obj => obj.prestador_tomador)
      .filter((value, index, self) => self.indexOf(value) === index);
      return uniqueValues;
    },
    prestadores(){
      let uniqueValues = this.ordenes.map(obj => obj.prestador)
      .filter((value, index, self) => self.indexOf(value) === index);
      return uniqueValues;
    },
    comunas_(){
      let uniqueValues = this.ordenes.map(obj => obj.comuna_id)
      .filter((value, index, self) => self.indexOf(value) === index);
      return uniqueValues;
    },
    estados_(){
      let uniqueValues = this.ordenes.map(obj => obj.estado)
      .filter((value, index, self) => self.indexOf(value) === index);
      return uniqueValues;
    },


  },
  watch:{
  
  },
}
</script>


<style scoped>

.form-container {
    display: flex;
    flex-wrap: wrap;    
    justify-content: space-between;
    gap: 20px; /* This is an alternative way to create space between flex items */
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background: white;
    border-radius: 8px;

}

.form-group {
    flex: 1 1 260px; /* Adjusts the basis to ensure 2x2 layout when enough space */
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
    
}

.form-group label {
    margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="email"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%; /* Ensures button stretches to full width on narrow screens */
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

@media (max-width: 600px) {
    .form-group {
        flex-basis: 100%; /* Forces 1x4 layout on narrow screens */
    }
}

.button-modal-m{
   padding:10px 40px;
   border: 2px solid rgba(0, 0, 0, 1);
   color: rgba(0, 0, 0, 1);
   font-weight: 600;
   display: inline-block;
   width: 300px;
   margin:10px;
   border-radius: 3px;
   cursor: pointer;
}
.button-modal-m:hover{
   background-color: rgba(0, 0, 0, 1);
   transition: 0.3s;
   color:white;
   box-shadow: rgba(0, 0, 0, 0.45) 0px 2px 10px 3px;
}
.background-screen{
  height: 100vh;
  width: 100vw;
  position: fixed;
 
/*   backdrop-filter: blur(2px); */
  z-index: 9999;

}
.titulo1{
  margin-bottom:30px;
  color: rgba(0, 0, 0, 1) !important;
}
.texto{
  text-align: center;
  padding: 0px 10px;
}
.main-m{
   display: flex;
   flex-wrap: wrap;
  width: 100%;
  text-align: center;
  padding: 10px;
  gap:10px;
}
.filtro{
   width: Calc(50% - 10px);
   background-color: rgb(250, 250, 250);
   border-radius: 15px;
}
.greeny{
  color:#FF8C00;
  cursor:pointer;
  text-decoration: underline;
}
.subtitulo2{
  color:#FF8C00;
}
#modal-receta-m{
   transition: 0.5s;
   position:fixed;



   overflow-y:auto; 
   border-radius:10px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:10px; 
   background:white; 
   top: 50%;
   left:0px;
    transform: translateY(-50%);
  
   width:700px;
   max-width:90%;
   margin: 0px auto; 
   right: 0;
   opacity:1;
   z-index:99999;
}
.flaco{
   width:600px !important;
   left: 10% !important;
   transform: translate(-10%, -50%) !important;
}
#modal-receta-m h2{
     text-align: center;
     font-weight: bold;
     color:rgba(0,0,0,0.6);
     margin-top:20px;
}
#modal-receta-m h4{

  color:rgb(0, 0, 0) !important;
  margin-bottom:15px;
  margin-top:15px;
  font-size: 20px;
  font-weight: 700;
}
#modal-receta-m h5{
  text-align: center;
  vertical-align: middle;
  color:rgba(0, 0, 0, 0.3) !important;
 
}
.cont{
  text-align: center;
  vertical-align: middle;
  position:absolute;
   width:Calc(100% - 40px);
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
#modal-receta-m .icon{
   height:40px;
   width:40px;
   color:white;
}
#modal-receta-m .contenedor-icono{
   cursor:pointer;
   position:absolute;
   right:20px;
   top:20px;
   height:40px;
   width:40px;
   background-color: rgba(0, 0, 0, 1);
   border-radius:50px;
   z-index: 100001;
}
#modal-receta-m .subtitulo{
   text-align: center;
   font-size:22px;
   margin-bottom:40px;
   margin-top:20px;
   color:rgb(181, 181, 181);
   font-weight:350;
}
#modal-receta-m .input-wrapper{
   padding:0px 5px;
}
#modal-receta-m .btn-examinar{
   background-color:#FF8C00;
   padding:8px 20px;
   font-size:14px;
   font-weight:600;
   width:200px;
   max-width:Calc(100vw - 80px);
   color:white !important;
   outline: none;
   margin-top:20px;
}
#modal-receta-m .draggable-container{
   padding: 15px 5px 20px 5px;
   max-width: 600px;
   margin: 0 auto;
   background-color: rgb(247, 247, 247);
   border-style:dashed;
   border: 2px dashed rgb(200, 200, 200);
   border-radius:5px;
}
#modal-receta-m .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white;
   z-index: 999;
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 10px); 
   border: 1px solid rgb(200, 200, 200);
}
#modal-receta-m .placeholder-c{
   position:absolute; top:33px; left:20px;
}
#modal-receta-m .receta-label-mobile{
   display: none;
}
#modal-receta-m .btn-enviar{
   margin:0 auto; 
   cursor:pointer; 
   background-color:rgba(255, 179, 25, 1);
   max-width:300px; 
   height:45px; 
   color:white; 
   font-size:18px; 
   border-radius:4px;
   vertical-align: middle;
   font-weight:500;
}
#modal-receta-m .btn-enviar-d{
   margin:0 auto; 
   cursor:pointer; 
   background-color:rgba(255, 179, 25, 0.4);
   max-width:300px; 
   height:45px; 
   color:white; 
   font-size:18px; 
   border-radius:4px;
   vertical-align: middle;
   font-weight:500;
}
#modal-receta-m .upload-img{
   height:70px;
   width:70px;
   margin:0 auto; 
   margin-bottom:40px;
   margin-top:30px;
}
#modal-receta-m .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:50%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding-right:7px;
}
#modal-receta-m .comuna{
   position:relative;
}
#modal-receta-m .input-container{
   max-width:610px;
   margin: 0 auto;
}
@media only screen and (max-width: 1000px) {
   #modal-receta-m .btn-enviar{
   margin:0 auto; 
   margin-top:30px; 
   cursor:pointer; 
   background-color:rgba(103,30,117,1);
   max-width:100%;
   margin: 20px 20px; 
   height:55px; 
   line-height:55px;
   color:white; 
   font-size:18px; 
   border-radius:4px;
   font-weight:500;
}
#modal-receta-m .btn-examinar{
   padding:10px 20px;
   font-size:18px;
   font-weight:600;
   width:300px;
   max-width:Calc(100vw - 80px);
   color:white !important;
}
#modal-receta-m .receta-label-mobile{
   display: inline-block;
}
#modal-receta-m .email, .telefono, .isapre, .comuna{
 padding:4px;
 width:100%;
 text-align:left;
 vertical-align:top;
 display:inline-block;
 font-size:13px;
 padding:5px 20px;
 color:grey;
 
}
#modal-receta-m .placeholder-c{
   position:absolute; top:33px; left:30px;
}
#modal-receta-m .comuna-container{
   position:absolute; 
   top:65px; 
   background-color:white; 
   border-radius: 0px 0px 4px 4px; 
   width:Calc(100% - 40px); 
}
#modal-receta-m .receta-label-desk{
   display: none;
}
#modal-receta-m .draggable-container{
   border:none;
   background: none;
}
#modal-receta-m .contenido{
   z-index: 999999;
   position:relative;
      overflow-y:scroll !important;
   min-height:Calc(100vh);
   -webkit-overflow-scrolling: touch;
}
#modal-receta-m{
   
   position:fixed !important;
   height:Calc(100vh) !important;
   border-radius:0px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
   padding:0px; 
   background:white; 
   top:0px !important;
   width: 100vw !important;
   transform: none !important;
   max-width:100%;
   max-height: 100%;
   margin: 0px auto !important; 
   left: 0px !important;
   right: 0px !important;
   opacity:1;
   z-index:90001;
}
.primary{
   display: block;
}
.texto{
  text-align: center;
}
}

.zoom-m-enter-active,
.zoom-m-leave-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: zoom-m;
}

.zoom-m-leave-active {
  animation-direction: reverse;
}

@keyframes zoom-m {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
</style>
