<template>
  <!-- eslint-disable -->
<div  style="text-align:left; background-color: rgb(242, 247, 251);">
 
  
  <div class="column-left">
    <SideNav />
  </div>

  
  <div class="column-right">
    <TopNav />
    <div class="main-container">   
      
    <div v-show="!cargando" class="articulo-principal">
        <h2>Gráficos y reportes</h2>    
        <div class="boxy">
          <div
      class=""
      style="position: relative;"
    >
   <div
          style="position:absolute; right:10px; top: 10px; border:2px solid; border-radius:10px; padding:5px 10px; cursor:pointer; color:#1D6F42;"
          @click="descargarCSV()"
        >
          Descargar EXCEL <b-icon icon="cloud-download" />
        </div>
        <div style="margin-bottom: 20px;">
        Desde <input
          id="x2"
          v-model="dt_dia"
          type="date"
        > Hasta: <input
          id="x2"
          v-model="dt_dia2"
          type="date"
        >
      </div>


      <div :v-show="puede_seleccionar_prestadores">
        <multiselect
          v-model="prestadores_seleccionados"
          :options="prestadores_habilitados"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Seleccionar prestadores"
          label="nombre"
          track-by="prestador_id"
          :preselect-first="true"
          selectedLabel="Seleccionado"
          selectLabel="Pulsar para seleccionar"
          deselectLabel="Pulsar para remover"
        >
        </multiselect>
        <hr>
      </div>


      <div>
        <multiselect
          v-model="comunas_en_ordenes_seleccionadas"
          :options="comunas_en_ordenes"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Seleccionar comunas"
          label="Nombre"
          track-by="id"
          :preselect-first="true"
          selectedLabel="Seleccionado"
          selectLabel="Pulsar para seleccionar"
          deselectLabel="Pulsar para remover"
        >
        </multiselect>
        <hr>
      </div>


      <hr>
  
      <div style=" position: relative; cursor: pointer;">
        <div v-on:click="viendo_menu = !viendo_menu" style="border:1px solid rgba(99, 99, 99, 1); padding: 2px 10px; position: absolute; top: 60px; border-radius: 5px; right:0px;">Tipo de gráfico</div>
        <div v-if="viendo_menu" style="cursor: pointer; z-index: 10000; position:  absolute; background-color: white; width:200px; top:95px; right:0px;  border-radius: 5px; border: 1px solid rgba(99, 99, 99, 0.2)">
          <div v-on:click="grafico_densidad = 0; viendo_menu = false;">Densidad de atención</div>
          <div v-on:click="grafico_densidad = 1; viendo_menu = false;">Densidad de compra</div>
        </div>
      </div>

      <WeekDensityChart
        v-show="grafico_densidad == 0"
        :ordenes="ordenes_filtradas"
        :bgColor="'rgb(20, 134, 231)'"
      />
      <WeekDensityOrderChart
        v-show="grafico_densidad == 1"
        :ordenes="ordenes_filtradas"
        :bgColor="'red'"
      />

      <hr>
      
      <div style="padding:80px 0px;">
<!--         <div class="cardContainer">
          <div
            style="background: rgba(0, 116, 217, 0.85);"
            class="cardS"
          >
            <div class="cardSIconDiv">
              <b-icon
                icon="cash-stack"
                class="cardSIcon"
              />
            </div>
            <div class="cardSText">
              Total Ingresos: <h2 style="color:white">${{ Intl.NumberFormat('de-DE').format(ordenes_filtradas.filter(z=>{return z.estado != 0}).reduce((a, b)=>{ return (a+b.monto || 0)}, 0)) }}</h2>
            </div>
          </div>
        </div> -->


        <div class="cardContainer">
          <div
            style="background: rgba(255, 133, 27, 0.85);"
            class="cardS"
          >
            <div class="cardSIconDiv">
              <b-icon
                icon="clipboard"
                class="cardSIcon"
              />
            </div>
            <div class="cardSText">
              Ordenes Totales: <h2 style="color:white">{{ ordenes_filtradas.length }}</h2>
            </div>
          </div>
        </div>

        <div class="cardContainer">
          <div
            style="background: rgba(57, 204, 204, 0.85);"
            class="cardS"
          >
            <div class="cardSIconDiv">
              <b-icon
                icon="person"
                class="cardSIcon"
              />
            </div>
            <div class="cardSText">
              Pacientes: <h2 style="color:white">{{ ordenes_filtradas.reduce((a, b)=>{return (a +b.clientes.length || 0)}, 0) }}</h2>
            </div>
          </div>
        </div>

        <div class="cardContainer">
          <div
            style="background: rgba(255, 65, 54, 0.9);"
            class="cardS"
          >
            <div class="cardSIconDiv">
              <b-icon
                icon="file-excel"
                class="cardSIcon"
              />
            </div>
            <div class="cardSText">
              Ordenes Anuladas: <h2 style="color:white">{{ ordenes_filtradas.filter(x=>{return x.estado == 0}).length }}</h2>
            </div>
          </div>
        </div>
        <div class="cardContainer">
          <div
            style="background: #111111;"
            class="cardS"
          >
            <div class="cardSIconDiv">
              <b-icon
                icon="arrow-clockwise"
                class="cardSIcon"
              />
            </div>
            <div class="cardSText">
              Anulaciones sin v/s con retorno: 
              <h2 style="color:white">
                {{count_with_no_valid_after}} / {{ count_with_valid_after }}
              </h2>
            </div>
          </div>
        </div>

      </div>

      <hr>

      <h3 style="margin-bottom:60px; margin-top:40px;">
        Gráficos por Cantidad #
      </h3>


      <div style="padding-top:0px;">
        <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              # Ordenes Pagadas / No Pagadas
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              style="transform:translateY(-2px);"
              :data="graficoPagadoNoPagado"
              :options="chartOptions"
            />
            <div
              v-if="ordenes.length == 0"
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>


                
        <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              # Ordenes por Estados
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              style="transform:translateY(-2px);"
              :data="graficoEstados"
              :options="chartOptions"
            />
            <div
              v-if="ordenes.length == 0"
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>

        <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              # Examenes por Categoría
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              style="transform:translateY(-2px);"
              :data="graficoCategorias"
              :options="chartOptions"
            />
            <div
              v-if="ordenes.length == 0"
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>
      </div>

      <hr>


      <h3 style="margin-bottom:60px; margin-top:40px;">
        Gráficos por Venta $
      </h3>
      <div>
        <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              Venta Ordenes Pagadas / No Pagadas
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              style="transform:translateY(-2px);"
              :data="graficoPagadoNoPagadoVenta"
              :options="chartOptions"
            />
            <div
              v-if="ordenes.length == 0"
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>
        <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              Ventas por Comuna
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              style="transform:translateY(-2px);"
              :data="graficoComunas"
              :options="chartOptions"
            />
            <div
              v-if="ordenes.length == 0"
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>
                
        <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              Ventas por Categoría
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              style="transform:translateY(-2px);"
              :data="graficoCategoriasVenta"
              :options="chartOptions"
            />
            <div
              v-if="ordenes.length == 0"
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>
      </div>

    </div>
          
        </div>
      </div>
      <div v-show="cargando" class="articulo-principal">
        <h2>Gráficos y reportes</h2> 
        <div style="height: calc(100vh - 140px); display: flex; justify-content: center; align-items: center;">


<Loader :height="100" :width="100" />


          </div>
        

      </div>
    </div>
  </div>



</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';
import WeekDensityChart from "./WeekDensityChart.vue";
import WeekDensityOrderChart from "./WeekDensityOrderChart.vue";
import PieChart from "./PieChart.vue";
import Multiselect from 'vue-multiselect'

export default {
  name: 'Reportes',
  components:{
    WeekDensityOrderChart,
    WeekDensityChart,
    TopNav,
    SideNav,
    Loader,
    PieChart,
    Multiselect,
  },
  data() {
    return {
      cargando:false,

      viendo_menu:false,
      grafico_densidad:0,
      email:'',
      nombre:'',
      apellido:'',
      rut:'',

      dt_dia:'',
      dt_dia2:'',

      desde:{d:0, m:0, a:0},
      hasta:{d:0, m:0, a:0},

      ordenes:[],
      ordenes_calculated:[],

      puede_seleccionar_prestadores:false,
      prestadores_habilitados:[],
      prestadores_seleccionados:[],

      comunas_en_ordenes:[],
      comunas_en_ordenes_seleccionadas:[],
      count_with_valid_after:0,
      count_with_no_valid_after:0,


      id_orden:0,

      popup: false,
      mouseX:0,
      mouseY:0,

      resultado:'',
      estados : ['Anulado', 'Citado',  'Confirmado', 'Atendido', 'No Atendido'],

      key:0,
      keyMain:0,
      
      chartOptions: {
        responsive:true,
        hoverBorderWidth: 20,
        title: {
          display: false,
          text: 'Actividad en la próxima'
        },
                pieceLabel: {
            mode: 'value'
        },
        legend:{display:true, position:'right'},

      },


      optionsLinea: {
            maintainAspectRatio: false,
            height: 70,
            responsive: true,
            title:{
                display: false,
                text: "Chart.js Time Scale"
            },
            scales:{
                xAxes: [{
                    type: "time",
                    time: {unit: 'day', displayFormats: {day: 'MMM DD' }
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Día'
                }
                }],
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                  },
                    scaleLabel: {
                        display:     true,
                        labelString: 'Monto'
                    }
                }]
            }
      },

      

    }
  },
  methods:{
    ...mapActions(['loginAdmin']),

    descargarCSV(){
      axios({
        url: window.hostname+'/api/admin/exportar_ordenes',
        method: 'POST',
        responseType: 'blob',
        data: {
          dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
          dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a
        }
      })
      .then( (response) =>  {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'exportar.xlsx'); 
        document.body.appendChild(link);
        link.click();          
      })
      .catch(e=>{
        console.log(e);
      })

    },

    groupBy (xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },

    cambiarEstado(e){
        axios.post(window.hostname+'/api/admin/cambiarEstado', 
        {orden_id:this.id_orden, estado_id:e})
        .then(response => 
        {
          this.get_ordenes()
          this.popup = false;
        })

        .catch(e=>{
          if (e.response.data.message)
            this.resultado = e.response.data.message           
        })

    },

    descargarCSVPago(){
        axios({
          url: window.hostname+'/api/admin/get_reporte',
          method: 'POST',
          responseType: 'blob',
          data: {
            dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
            dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a
          }
        })
        .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exportar_pago.csv'); 
            document.body.appendChild(link);
            link.click();          
        })
        .catch( (e) =>{
          console.log(e)
        })

    },

    marcar_pagado(e){
        axios.post(window.hostname+'/api/admin/marcar_pagado', 
        {orden_id:this.id_orden})
        .then(response => 
        {
          this.get_ordenes()
          this.popup = false;
        })

        .catch(e=>{
          if (e.response.data.message)
            this.resultado = e.response.data.message           
        })

    },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
      mouseEnter(event, order_id) {

          this.popup = true;
          this.id_orden = order_id
          //this.$el.addEventListener('mousemove', this.mouseMove, false);
          this.mouseX = event.clientX;
          this.mouseY = event.clientY;
      },
      mouseLeave(event) {
        if (!(event.clientX >= this.mouseX && event.clientY >= this.mouseY))
        {

          this.popup = false;
        }

      },
    close (e){
      /*Si clickeo (e.target) fuera de este componente (this.$el), se cierra*/
      if (!(e.target.classList.contains('dontClose')))
        this.showinFuncionesList = false
    },
    get_ordenes(){      
      if (this.desde.a != 0 && this.hasta.a != 0)
      {
        this.cargando = true;
        axios.post(window.hostname+'/api/admin/get_reporte', {
          dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
          dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a
        })
          .then((response) => {
            this.cargando = false;
            this.prestadores_habilitados = response.data.prestadores_habilitados;
            if (this.prestadores_seleccionados.length == 0) {
              this.prestadores_seleccionados = this.prestadores_habilitados;
            }

            this.ordenes = response.data.ordenes;
            if (this.prestador.id in [0, 1]) {
              this.puede_seleccionar_prestadores = true;
              this.ordenes = this.ordenes.filter((orden) => {
                return this.prestadores_seleccionados.some((prestador) => {
                  return prestador.prestador_id == orden.prestador_id;
                });
              });
            } else {
              this.puede_seleccionar_prestadores = false;
              this.ordenes = this.ordenes.filter(orden => orden.orden_propia == true)
            }
            
            function compare(a, b) {
              let da = new Date(a.fecha.a, a.fecha.m, a.fecha.d)
              let db = new Date(b.fecha.a, b.fecha.m, b.fecha.d)
              if ((a.urgente && a.estado == 1)  && !(b.urgente && b.estado == 1)){
                return -1                
              } else if (!(a.urgente && a.estado == 1) && (b.urgente && b.estado == 1)){
                return 1
              } else if(da > db){
                return 1
              } else if (da < db){
                return -1
              } else {
                return 0
              }
            }

            this.ordenes_calculated = []
            var ins = {}
            var hoy = new Date();
            var dia = new Date();
            var comunas_en_ordenes = []
            var comunas_en_ordenes_ids = []

            for (var i = 0; i < this.ordenes.length; i++){
              ins = this.ordenes[i]
              dia = new Date(ins.fecha.a, ins.fecha.m-1, ins.fecha.d)
              if ((((dia.getTime()+(1000 * 60 * ins.desde))-hoy.getTime())/(1000 * 60 * 60)) < 2*24)
                ins.urgente = true;
              else
                ins.urgente = false;
              if(!comunas_en_ordenes_ids.includes(ins.comuna_id)){
                comunas_en_ordenes_ids.push(ins.comuna_id);
                comunas_en_ordenes.push(this.comunas.find(x=>{return x.id == ins.comuna_id}));
              }
              this.ordenes_calculated.push(ins)
            }

            this.comunas_en_ordenes = comunas_en_ordenes;
            this.comunas_en_ordenes_seleccionadas = comunas_en_ordenes;
            this.ordenes_calculated.sort(compare)
            this.count_with_no_valid_after = response.data.count_with_no_valid_after;
            this.count_with_valid_after = response.data.count_with_valid_after;
          })
          .catch((e) => {
            this.cargando = false;

            console.log("error:", e)        
          }
        )
      }
    }

  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'categorias', 'prestaciones', 'tipos', 'admin_datos', 'examenes', 'prestador']),
      ordenes_filtradas(){
        return this.ordenes.filter(
          orden=>
          {
            return this.comunas_en_ordenes_seleccionadas.some((comuna) => {
                  return comuna.id == orden.comuna_id;
                }) && 
            this.prestadores_seleccionados.some((prestador) => {
                  return prestador.prestador_id == orden.prestador_id;
                })})
      },
      graficoComunas(){
      var ret = this.groupBy(this.ordenes, 'comuna_id')

      let keys = Object.keys(ret)
      var labs = []
      var data = []
      for(var k = 0; k< keys.length; k++){
        labs.push(this.comunas.filter(x=>{return x.id == keys[k]})[0].Nombre)
        data.push(ret[keys[k]].reduce((a, b)=>{return (a+b.monto || 0) }, 0))
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labs,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }

      return graph;
    },
      graficoCategoriasVenta(){
      var labels = []
      var data = []
      for(var i = 0; i < this.categorias.length; i++){
        data.push(
          this.ordenes.reduce((a, b)=>{
                return (a+b.examenes.filter(z=>{
                  return this.examenes.filter(m=>{return m.id == z.examen_id}).length > 0 ? (this.examenes.filter(m=>{return m.id == z.examen_id})[0].categoria_id == this.categorias[i].id) : false}).reduce((c, d)=>{return c+d.precio || 0}, 0) || 0) }, 0))
        labels.push(this.categorias[i].nombre)
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }

      return graph;
    },
      graficoCategorias(){
      var labels = []
      var data = []
      for(var i = 0; i < this.categorias.length; i++){
        data.push(this.ordenes.reduce((a, b)=>{return (a+b.examenes.filter(z=>{return this.examenes.filter(m=>{return m.id == z.examen_id}).length > 0 ? this.examenes.filter(m=>{return m.id == z.examen_id})[0].categoria_id == this.categorias[i].id : false}).length || 0) }, 0))
        labels.push(this.categorias[i].nombre)
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }

      return graph;
    },
      graficoEstados(){
      let nCanceladas = this.ordenes.filter(x=>{return x.pagado == 0}).length
      let nAgendadas = this.ordenes.filter(x=>{return x.pagado == 1}).length

      let labels = this.estados
      var data = []
      for(var i = 0; i < this.estados.length; i++){
        data.push(this.ordenes.filter(x=>{return x.estado == i}).length)

      }          
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ],
            data: data
          }
        ]
      }

      return graph;
    },
    
      graficoPagadoNoPagadoVenta(){
      let nCanceladas = this.ordenes.filter(x=>{return x.pagado == 0}).reduce((a,b) =>{return a+b.monto || 0 }, 0)
      let nAgendadas = this.ordenes.filter(x=>{return x.pagado == 1}).reduce((a,b) =>{return a+b.monto || 0 }, 0)

      let labels = ['Pagadas', 'No Pagadas']


          
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#3D9970", "#FF4136"],
            data: [nAgendadas, nCanceladas]
          }
        ]
      }

      return graph;
    },
      graficoPagadoNoPagado(){
      let nCanceladas = this.ordenes.filter(x=>{return x.pagado == 0}).length
      let nAgendadas = this.ordenes.filter(x=>{return x.pagado == 1}).length

      let labels = ['Pagadas', 'No Pagadas']


          
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#3D9970", "#FF4136"],
            data: [nAgendadas, nCanceladas]
          }
        ]
      }

      return graph;
    },
    
    graficoLineaSumaPagos(){

      var ret = this.groupBy(this.ordenes, 'fecha_dt')
      var ret_fecha_creado = this.groupBy(this.ordenes, 'fecha_creado_dt')
      let labels1 = []
      let labels2 = []
      let ventasProgramadasTotal = []
      let fecha;
      let desde_ = new Date(this.desde.a, this.desde.m-1, this.desde.d)
      let hasta_ = new Date(this.hasta.a, this.hasta.m-1, this.hasta.d)
      for(var itDia = new Date(desde_.getFullYear(), desde_.getMonth(), desde_.getDate()); itDia <= hasta_; itDia.setDate(itDia.getDate() + 1)){
        fecha = itDia.toLocaleDateString("en-US", {year:"numeric", month:'2-digit', day:'2-digit'})
        if (ret.hasOwnProperty(fecha)) {
          labels1.push({x:fecha, y:ret[fecha].filter(x=>{return x.estado != 0}).reduce((a, b)=>{return  (a+b.monto || 0) }, 0)})
          labels2.push({x:fecha, y:ret[fecha].filter(x=>{return x.pagado == 1 && x.estado != 0}).reduce((a, b)=>{return  (a+b.monto || 0) }, 0)})
        } else {
          labels1.push({x:fecha, y:0})
          labels2.push({x:fecha, y:0})
        }
        if (ret_fecha_creado.hasOwnProperty(fecha)) {
          ventasProgramadasTotal.push({x:fecha, y:ret_fecha_creado[fecha].reduce((a, b)=>{return  (a+b.monto || 0) }, 0)})
        } else {
          ventasProgramadasTotal.push({x:fecha, y:0})
        }
      }
      let graph = {          
        datasets: [
          {
            label: "Ingreso diario total", 
            data: labels1,
            fill: false,
            borderColor: 'rgba(255, 10, 13, 0.5)',
            backgroundColor: "rgba(255, 10, 13, 0.2)"
          },
          {
            label: "Ingreso diario cobrado", 
            data: labels2,
            fill: false,
            backgroundColor: 'rgba(46, 204, 64, 0.2)',
            borderColor: 'rgba(46, 204, 64, 0.5)'
          },
          {
            label: "Ventas programadas", 
            data: ventasProgramadasTotal,
            fill: false,
            backgroundColor: 'rgba(246, 237, 116, 0.2)',
            borderColor: 'rgba(246, 237, 116, 0.5)'
          }
        ]
      }

      return graph;
    },

  },
  watch:{
    dt_dia(val){

        var datos = val.split('-')
        this.desde = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
        this.get_ordenes()
      
    },
    dt_dia2(val){
        var datos = val.split('-')
        this.hasta = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
        this.get_ordenes()
      
    },
    ordenes(){
      this.key +=1; 
    },
    prestadores_seleccionados: function (val) {
      this.get_ordenes();
    },

  },
  mounted() {
    document.addEventListener('click', this.close);
    var hoy = new Date();
    this.dt_dia = hoy.toISOString().split('T')[0];
    hoy.setDate(hoy.getDate() + 7);
    this.dt_dia2 = hoy.toISOString().split('T')[0];
  },
  beforeUnmount () {
    document.removeEventListener('click',this.close)
  }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.cardSIconDiv{
  position:absolute;
  top:43px;
  right:20px;
}
.cardSIcon{
  height:35px;
  width:35px;
}
.cardContainer{
  width:25%;
  display:inline-block;
  padding:10px;
}
.cardSText{
  padding-top:10px;
  line-height: 35px;
}
.cardS{
  border-radius: 5px;
  color:#fff;
  padding:20px;
  height:120px;
  position:relative
}
.graphContainerTop{
padding-left:10px; padding-right:10px; padding-bottom:20px; width:33%;  display:inline-block; 
}
.graphContainerMain{
padding-left:10px; padding-right:10px; padding-bottom:20px; width:100%;  display:inline-block; 
}

td{
  padding: 5px 5px 0px;
  text-align: left;
}
/*
 tr:nth-child(odd){
  background-color: rgba(1, 98, 153, 0.8);
  color: #fff;
}
*/
table
{ 
    margin-left: auto;
    margin-right: auto;
}

.shadow-object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  height:auto;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 3px 8px;
  padding:2px;
}


td{
  text-align: left;
}
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;



  
  margin: 0 auto;




}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);

  padding-top:0px;
  padding-left:275px;
  width:100%;
  

}
.main-container{
  background-color: rgb(242, 247, 251);
  padding: 10px;

  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

