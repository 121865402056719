<template>
  <!-- eslint-disable -->
<div  style="text-align:left; background-color: rgb(242, 247, 251);">
 <div v-if="loading" style="background-color: rgba(0,0,0,0.2); display: flex; justify-items: center; align-items: center; text-align: center; position: fixed; top:0px;left:0px; height: 100vh; width: 100vw; z-index: 100000;">
  <div style="margin: 0 auto;">
    <img :src="'https://assets-prestador-clinicgo.s3.sa-east-1.amazonaws.com/'+prestador.id+'/images/logo.png'" style="height: 40px; display: inline-block; " alt="Imagen"> 
    <Loader style="transform: translateY(-20px);" :height="100" :width="100" />
  </div>

 </div>
  
  <div class="column-left">
    <SideNav />
  </div>

  
  <div class="column-right">
    <TopNav />
    <div class="main-container">   
      
      <div v-if="loaded" class="articulo-principal">
        <h2>Modificar Código con ID {{ $route.params.id }} </h2>    
        <div class="boxy">
            <h4>Descuento de valor domiciliario</h4>
           Porcentaje de descuento al servicio domiciliario  <input style="max-width: 50px;" v-model="descuento_servicio_porcentaje" max="100" min="0" type="number"> % 
           <button  class="btn-ir-gordo"
            @click="editar_valores_codigos(null)"
            :style="{'background-color':prestador.admin.assets.colors.primary}">Actualizar</button>
          <div>
 

            <hr/>
            <h4>Descuento por servicio</h4>
        <table style="width:100%;">
          <tr>
            <td><b>Nombre</b></td>
            <td><b>Descuento</b></td>
          </tr>
          <tr>
            <td />
            <td>
              <div>
                <select
                  id=""
                  name=""
                  @change="(e)=>{editar_por_porcentaje(e.target.options.selectedIndex)}"
                >
                  <option
                    v-for="x in 11"
                    :value="(x-1)*10"
                  >
                    {{ (x-1)*10 }}%
                  </option>
                </select>
              </div>
            </td>
          </tr>
          <tr v-for="edit, i in editando_codigo">
            <td style="width:500px;">
              {{ examenes[i].nombre }} (${{ Intl.NumberFormat('de-DE').format(examenes[i].precio) }})
          
              <b-icon
                v-if="!edit.editando"
                style="cursor:pointer; color:blue;"
                icon="pen"
                @click="edit.editando = true; $forceUpdate();"
              />
              <b-icon
                v-if="edit.editando"
                style="cursor:pointer; color:green;"
                icon="check-circle"
                @click="editar_valores_codigos({examen_id:edit.id, valor:edit.valor}); edit.editando = false; $forceUpdate();"
              />
              <b-icon
                v-if="edit.editando"
                style="cursor:pointer; color:red;"
                icon="x-circle"
                @click="edit.editando = false; $forceUpdate();"
              />
              <!--
          <b-icon v-if="editando[i]" style="cursor:pointer; color:red;" v-on:click="cambiarEditando(i)" icon="x-circle"></b-icon>
          <b-icon v-if="editando[i]" style="cursor:pointer; color:green " v-on:click="editar_descuento(); cambiarEditando(i);"  icon="check-circle"></b-icon>
        -->
            </td>
            <td>
              <div v-if="!edit.editando">
                {{ edit.valor }}
              </div>
              <div v-else>
                <input
                  id=""
                  v-model="edit.valor"
                  type="text"
                  name=""
                >
              </div>
            </td>
            <!--
        <td v-for="ex, j in ase">
          <input style="width:70px;"  v-if="editando[i]" v-model="editando_linea[j].valor" type="text"> <span v-else>{{editando_descuentos[i][j].valor}}</span>

        </td>
        -->
          </tr>
        </table>
        <div style="text-align:left; margin-top:10px;" />
      </div>
          
        </div>
      </div>

      <div v-else class="articulo-principal">
        <div style="height: calc(100vh - 140px); display: flex; justify-content: center; align-items: center;">
          <Loader :height="100" :width="100" />
        </div>
      </div>

    </div>
  </div>



</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  props:['id'],
  components:{
    TopNav,
    Loader,
    SideNav,
  },
  data() {
    return {
      loading:false,
      loaded:false,
      descuento_servicio_porcentaje:0,
      codigo:[],
      editando_codigo:[],

    }
  },
  created(){
  
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize', 'recargar']),

    editar_valores_codigos(d){
      this.loading = true;
    axios.post(window.hostname+'/api/admin/editar_valores_codigos',{valores: d != null ? [d] : [], descuento_servicio_porcentaje:this.descuento_servicio_porcentaje, codigo_id:this.$route.params.id})
        .then(response => 
        {
          this.recargar({prestador_id:this.prestador.id}).then( ()=>{
          this.initialize_();
          })
          this.loading = false;
          
        })

        .catch(e=>{
          this.loading = false;
            console.log("Error al cargar...")
        })
        
  },

  editar_por_porcentaje(porcentaje)
  {
    let edit = []
    for(var i = 0; i < this.examenes.length; i++){
      edit.push({examen_id:this.examenes[i].id, valor:Math.floor((porcentaje/10) * this.examenes[i].precio)})
    }

    this.loading = true;
    axios.post(window.hostname+'/api/admin/editar_valores_codigos',{valores:edit, codigo_id:this.$route.params.id})
    .then(response => 
    {
      this.recargar({prestador_id:this.prestador.id}).then( ()=>{
      this.initialize_();
      this.loading = false
      })
      
    })

    .catch(e=>{
        console.log("Error al cargar...")
        this.loading = false
    })
        
  },





    
  initialize_(){
    
    //this.loaded = false;
    
      axios.post(window.hostname+'/api/admin/init_editar_codigo', {codigo_id: this.$route.params.id})
      .then(response => 
      {

        
        this.codigo = response.data.codigo;
        let edit = this.examenes
        console.log(edit)
        for(var i = 0; i < edit.length; i++){
          edit[i].editando = false;
          edit[i].valor = (this.codigo.valores.find(x=>{return x.examen_id == edit[i].id}) || {valor:0}).valor
        }
        console.log(response.data)
        this.descuento_servicio_porcentaje = response.data.codigo.costo_servicio_porcentaje
        this.editando_codigo = edit;
        this.loaded = true;
      })         
      .catch(e=>
      {
          this.loaded = true;
      })

  },


  },
  computed:{
    ...mapGetters(['comunas', 'prestador', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes', 'aseguradoras', 'descuentos']),
  },
  watch:{

  },
  mounted() {

  }

}
</script>


<style scoped>
td{
  text-align: left;
}
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;



  
  margin: 0 auto;




}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);

  padding-top:0px;
  padding-left:275px;
  width:100%;
  

}
.main-container{
  background-color: rgb(242, 247, 251);
  padding: 10px;

  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

