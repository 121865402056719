
<template>
  <div class="container-fluid">
    <!--eslint-disable-->

    <div>
      <h4 style="margin: 20px 0px;">Distribución de atención</h4>
      <div style=" line-height: 30px; font-size: 14px;">
        <div style=" line-height: 20px;">Densidad</div>
        <div style="display: inline-block; vertical-align: top; line-height: 20px; margin-right: 5px;">0</div>
        <div style=" border: 1px solid rgb(38,53,66); display: inline-block; width: 20px; height: 20px; margin: 0px 1px" v-for="i in 5">
          <div style="height:100%; width:100%;" :style="{'opacity': (i-1)/4, 'background-color':bgColor,}" ></div>
        </div>
        <div style="display: inline-block; vertical-align: top; line-height: 20px; margin-left: 5px;">{{maxLen_}}</div>
      </div>
      <div
        style="padding:0px; margin-bottom:40px; position:relative; margin-top:0px;"
        class="shadow-object"
      >
      

        <table
          class="Table-Normal"
          style="width:100%; text-align:center;"
        >
          <td style="width:12.5%; text-align: center; overflow: hidden; text-overflow: ellipsis; white-space:nowrap; background-color:rgb(38,53,66); border:1px solid white; color:white;">
            Horario
          </td>
          <td
            v-for="d, i in dias2"
            style="width:12.5%; overflow: hidden; text-align: center; text-overflow: ellipsis; white-space:nowrap; background-color:rgb(38,53,66); border:1px solid white; color:white;"
          >
            {{ d }} 
          </td>
        </table>
                
        <table style="width:12.5%; text-align:center; ">
          <!--display: -webkit-box-->
          <tr
            v-for="i in Math.ceil((horaMax-horaMin)/60)"
            style="text-align:center; font-size:10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-height:50px; height:50px; line-height:48px; background-color:rgb(38,53,66); border:1px solid white; color:white;"
          >
            {{ timeConvert(horaMin+(i-1)*60) }}-{{ timeConvert(horaMin+(i)*60) }}
          </tr>
        </table>
        <template v-for="dia, index_dia in densidades">
        <div
          v-for="valor, index_hora in dia"
          :style="{
            'left':(12.5*(mapping[index_dia]).toString())+'%', 'line-height':(50*(separacion)/60)+'px', 
            'height':(50*(separacion)/60)+'px', 'top':((((index_hora)*separacion)*50/60)+29).toString()+'px',
            'background-color': bgColor,
            'opacity': ''+ maxLen_ > 0 ? (valor/maxLen_) : 0
          }" 
          style="font-size:10px; color:white; position:absolute; vertical-align:middle; text-align:center;   width:12.5%;"
        >

        </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
 /* eslint-disable */

export default {
  name: 'WeekDensityChart',
  props:['ordenes', 'bgColor'],
  data() {
    return {

      dias:['Lu','Ma','Mi','Ju','Vi','Sa','Dom'],
      dias2:['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
      codigo:[],
      editando_codigo:[],
      ordenes_calculated:[],
      separacion:15,
      maxLen_:1,
      horaMax_:0,
      min:360,
      max:1080,
      mapping:[7,1,2,3,4,5,6]

    }
  },
  created(){
  
  },

  methods:{
    ...mapActions(['initialize']),
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },


    



  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes', 'aseguradoras', 'descuentos']),
    horaMin: function(){
      var min = Infinity;
      for(var o of this.ordenes)
      {
        if (o.desde < min)
        {
          min = o.desde
        }
      }
      if(this.min < min)
        return this.min
      return min;
    },
    horaMax: function(){
      var max = 0;
      for(var o of this.ordenes)
      {
        if (o.hasta > max)
        {
          max = o.hasta
        }
      }
      if(this.max > max)
        return this.max
      return max;
    },
    densidades: function(){
      let max_len = 0;
      let ret = [];
      let separacion = 15;
      let len = 0;
      let ordenes_filtradas;
      for(var weekday = 0; weekday < 7; weekday++)
      {
        ret.push([])
        ordenes_filtradas = this.ordenes_calculated.filter(z=>{return z.weekday == weekday})
        for(var intervalo = this.horaMin; intervalo <= this.horaMax; intervalo+=this.separacion)
        {
          len = ordenes_filtradas.filter(orden=>{ return (orden.desde >= intervalo && orden.desde < intervalo+this.separacion) || (orden.hasta > intervalo && orden.hasta <= intervalo+this.separacion) || (orden.desde <= intervalo  && orden.hasta >= intervalo+separacion) }).length 
          ret[weekday].push(len)
          if(len > max_len){
            max_len = len
          }
        }
      }
      this.maxLen_ = max_len;
      return ret;
    }
  },
  watch:{
    ordenes(val){
      for(var o of val){
        o.weekday = new Date(o.fecha.a, o.fecha.m-1, o.fecha.d).getDay()
        console.log(o.weekday, o.fecha);
      }
      this.ordenes_calculated = val;
    }

  },
  mounted() {

  }

}
</script>


<style scoped>
td{
  text-align: left;
}
.shadow-object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  height:auto;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 3px 8px;
  padding:2px;
}
table
{ 

}

</style>

